@import "wrap";
@import "form";
@import "select";
@font-face {
  font-family: 'tfBlackItalic';
    src: url(../css/fontFamily/BwSeidoRound-BlackItalic.woff);
}
@font-face {
  font-family: 'tfBlack';
  src: url(../css/fontFamily/BwSeidoRound-Black.woff);
}
@font-face {
  font-family: 'tfBold';
  src: url(../css/fontFamily/BwSeidoRound-Bold.woff);
}
@font-face {
  font-family: 'tfBoldItalic';
  src: url(../css/fontFamily/BwSeidoRound-BoldItalic.woff);
}
@font-face {
  font-family: 'tfLight';
  src: url(../css/fontFamily/BwSeidoRound-Light.woff);
}
@font-face {
  font-family: 'tfLightItalic';
  src: url(../css/fontFamily/BwSeidoRound-LightItalic.woff);
}
@font-face {
  font-family: 'tfMedium';
  src: url(../css/fontFamily/BwSeidoRound-Medium.woff);
}
@font-face {
  font-family: 'tfMediumItalic';
  src: url(../css/fontFamily/BwSeidoRound-MediumItalic.woff);
}
@font-face {
  font-family: 'tfRegular';
  src: url(../css/fontFamily/BwSeidoRound-Regular.woff);
}
@font-face {
  font-family: 'tfRegularItalic';
  src: url(../css/fontFamily/BwSeidoRound-RegularItalic.woff);
}
@font-face {
  font-family: 'tfThin';
  src: url(../css/fontFamily/BwSeidoRound-Thin.woff);
}
@font-face {
  font-family: 'tfThinItalic';
  src: url(../css/fontFamily/BwSeidoRound-ThinItalic.woff);
}
* {
  box-sizing: border-box;
  font-family: 'tfRegular';
  margin: 0;
  padding: 0;
}
.bootstrap {
  // height:100%;
  @import "facility-grid";
  @import "style";
  @import "bootstrap-grid";
  @import "bootstrap.min";
  @import "setupbooking";
  @import "fullcalender";
  @import "add-doctor";
  @import "style-doctor";

}

body {
  margin: 0;
  padding: 0;
  height: 100% !important;
  min-height: 100vh;
  zoom: 85%;
  background-color: #ffffff;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 14px;
  line-height: 1.4;
}
.h3{
  font-size: 1.25em;
  padding: 2px;
}
.mt45 {
  margin-top: 45px;
}

.tr3 {
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}
.not-found:not(main) {
  display: none;
}
//city styles


main {
  padding-bottom: 100px;
}

main.home {
  .defLogo {
    display: none;
  }
}
div.defHeader {
  padding-bottom: 30px;
  box-shadow: 0 4px 20px 0 rgba(128, 128, 128, 0.24);
  margin-bottom: 33px;
  height: 86px;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 200;
  background-color: #f4f5f9;

  .defLogo {
    display: block;
    width: 166px;
    padding-top: 5px;
    padding-right: 7px;
    padding-left: 0px;
  }
  .fully {
    width: 100%;
    padding-right: 0;
    padding-left: 0;
    padding-top: 0;
    padding-bottom: 0;
    float: right;
    box-shadow: none;
    height: 50px;
    .bar_filter {
      width: 100%;
      height: auto !important;
      display: block;
      overflow: hidden;
      //position: static;
      //top: 92px;
    }
    .inputs {
      justify-content: space-between;
      width: auto;
      flex-grow: 1;
      > div {
        //input#text_search {
        //  width: 575px;
        //}
       > p {
          display: none;
        }
      }
      > div:nth-child(2) {
        flex-basis: calc(44.5% - 166px);
        flex-grow: 1;
        margin: 0 3px;
      }
      > div:last-child {
        flex-basis: calc(68.5% - 166px);
        flex-grow: 1;
        margin: 0 3px;
      }
    }
    .buttons {
      width: auto;
      flex-basis: initial;
      .filter {
        display: none;
      }
    }

    .specialties {
      top: 49px;
      z-index: 999;
    }
  }
}
.list-page{

  div.defHeader {
    height: auto;
    padding-bottom: 3px;
  }
}



//sign in button styles
.sign {
  position: relative;
  height: 55px;
  //background-color: rgba(255, 255, 255, 0.3);
  @extend .buttonRadius;
  // width: 520px;
  display: flex;
  padding: 6px;
  justify-content: flex-end;
  & > div {
    display: block;
    &.loaderTop{
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      border-radius: 127px;
      text-align: center;
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      //background-image: url(../img/spin.svg);
      background-size: 50px;
      background-repeat: no-repeat;
      background-position: center;
      background-color: rgb(255, 255, 255);
    }
    &.not {
      display: flex;
      justify-content: flex-end;
      width: 30%;
      background-color: rgba(255, 255, 255, 0.3);
      border-radius: 50px;
      padding: 0 5px;
      >div{
        position: relative;
        &:hover .list{
          display: block;
          transition-duration: 0.5s;
        }
        ul.list{
          display: none;
          width: 200px;
          position: absolute;
          right: 0;
          top: 100%;
          text-align: left;
          background-color: #ffffff;
          -webkit-box-shadow: 1px 1px 1px #718899;
          -moz-box-shadow: 1px 1px 1px #718899;
          box-shadow: 0px 6px 24px 0px rgba(113, 136, 153, 0.21);
          padding: 15px 0 20px 28px;
          border-radius: 10px;
          li{
            padding: 8px 0;
            cursor: pointer;
            &:nth-of-type(1):hover,
            &:nth-of-type(1):hover a
            {
              color: #0096FD
            } 
            &:nth-of-type(2):hover,
            &:nth-of-type(2):hover a
            {
              color: #0096FD
            }
            &:last-child{
              color:rgb(221, 107, 85);
            }
          }
          &:hover{
            display: block;
          }
        }
      }
      .prof{
        width: 36px;
        height: 36px;
        top: 2px;
        position: relative;
        cursor: pointer;
        border-radius: 100%;
        background-size: 40px;
        background-position: center;
        overflow: hidden;
        margin: 1px 0 0 9px;
        //background-image: url(../img/prof_pic.png);
        background-color: #ffffff;
        border: 2px solid #0096fd;
        &:hover+.list{
          display: block;
        }
        a{
          display: block;
          width: 100%;
          height: 100%;
          margin: 0;
        }
      }
    }
    > p,
    > a {
      text-align: center;
      font-size: 14px;
      display: block;
      width: 100%;
      padding: 13px 0;
      text-decoration: none;
      cursor: pointer;
      &.name{
        text-transform: capitalize;
        width: 80%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        a{
          color: #0096fd;
        }
      }
    }
    a {
      color: #000000;
    }
    &.fcl{
      a{
        color: #ffffff;
      }
    }
    &.about{
      a{
        color: #0096fd;
      }
    }
  }
}

.login {
  color: #0096fd;
  background-color: #ffffff;
  //box-shadow: 2px 1px 4px rgba(158, 164, 183, 0.2);
  box-shadow: 1px 1px 14px rgba(158, 164, 183, 0.55);
  @extend .buttonRadius;
  &:hover {
    box-shadow: 1px 1px 14px rgba(158, 164, 183, 0.95);
  }
}



.buttonRadius {
  border-top-right-radius: 2em;
  border-bottom-right-radius: 2em;
  border-top-left-radius: 2em;
  border-bottom-left-radius: 2em;
}

//header styles
.pt50 {
  padding-top: 50px;
}

.fullWidth {
  width: 100%;
}

.flexBox {
  box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: 0;
  margin-left: 0;
  width: 100%;
}

.align_justify {
  justify-content: space-between

}

.align_justify_right {
  justify-content: flex-end;

}

.align_justify_left {
  justify-content: flex-start;

}

.logo {
  img {
    width: 200px;
    margin-top: 5px;
    //height: 68px;
  }
}

.headerTop {
  @extend .flexBox;
  @extend .align_justify
}
.list-page + footer,
.list-page{
  background-color: #F3F5F8;
}

.row.topHolder{
  z-index: 30;
  position: fixed;
  top: 0;
  width: 100%;
  //background-color: #f9f9f9;
  background-color: #ffffff;
  -webkit-box-shadow: 1px 1px 1px #718899;
  -moz-box-shadow: 1px 1px 1px #718899;
  box-shadow: 3px 2px 24px 5px rgba(113, 136, 153, 0.21);
  .wrap{
    width: 1400px;
  }
  .headerTop{
    padding: 10px 0;
    .sign{
      display: flex;
      width: 520px;
      justify-content: flex-end;
      > div{
        float: none;
        width: 30%;
        &.fcl{
          a{
            color: #ffffff;
          }
        }
        &.about{
          a{
            color: #0096fd;
          }
        }
        p{
          margin: 0;
          padding: 13px 0;
        }
      }
    }
  }
}
.fcl{
  margin-right: 25px;
  width:30%;
  a{
    margin: 0;
    padding: 13px 0;
    background-color: #0096FD;
    color: #ffffff;
    border-top-right-radius: 2em;
    border-bottom-right-radius: 2em;
    border-top-left-radius: 2em;
    border-bottom-left-radius: 2em;
    &:hover{
      background-color: #007de0;
    }
  }
}






//modal styles

.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.72);
  z-index: 1031;
}

.user-select {

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.modall {
  .socials{
    display: flex;
    justify-content: center;
    padding: 0 0 25px 0;
    > div{
      float: left;
      padding: 13px 10px;
      color: #ffffff;
      cursor: pointer;
      width: 50%;
      text-align: center;
      text-transform: capitalize;
      &.gp {
        background-color: #E24825;
        &:hover{
          background-color: #bf4626;
        }
      }
      &.fb {
        background-color: #385499;
        margin-right: 10px;
        &:hover{
          background-color: #363f7f;
        }
      }
      span{
        font-size: 20px;
      }
    }
  }
  @extend .user-select;
  background-color: #fff;
  border-radius: 7px;
  max-width: 100%;
  width: 663px;
  min-height: 300px;
  margin: auto;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 51px 81px 15px 81px;
  max-height: 680px;
  overflow: auto;
  z-index: 1033;
  &.reg {
    max-height: 740px;
    .regS {
      text-align: center;
      position: relative;
      text-transform: uppercase;
      padding-bottom: 10px;
      &:after {
        content: '';
        height: 4px;
        width: 41px;
        background-color: #FF4B1F;
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        bottom: 0;
      }
    }
  }
  .footer {
    text-align: center;
    a {
      text-decoration: none;
      color: #000000;
      font-size: 14px;
      letter-spacing: 0.2pt;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .close {
    position: absolute;
    z-index: 10;
    right: 34px;
    top: 29px;
    width: 14px;
    height: 14px;
    cursor: pointer;
  }
  &.forgot{
    // z-index: 1001;
    height: 300px;
    padding: 17px 81px;
    &+.modall{
      display: none;
    }
  }
}

.SearchBar.row {
  z-index: 25;
}

//search bar


 .fully {
  @extend .flexBox;
  @extend .align_justify;
  //height: 140px;
  min-height: 140px;
  flex-wrap: wrap;
  width: 100%;
  padding: 42px 25px 15px 15px;
  -webkit-box-shadow: 1px 1px 1px #718899;
  -moz-box-shadow: 1px 1px 1px #718899;
  box-shadow: 3px 2px 24px 5px rgba(113, 136, 153, 0.21);
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  border-radius: 7px;
   background-color: #ffffff;
  &.expand {
    .bar_filter {
      height: 49px;
    }
  }
  .vis {
    @extend .flexBox;
    flex-basis: 100%;
    flex-grow: 1;
    flex-wrap: nowrap;
  }
  .inputs {
    @extend .flexBox;
    @extend .align_justify;
    //width: 934px;
    flex-basis: 86.6%;
    max-width: 87%;
    padding-right: 5px;
    margin-right: 18px;
    > div:nth-child(2) {
      flex-basis: 31.2%;
    }
    > div:last-child {
      flex-basis: 67.9%;
    }
    > div {
      position: relative;
      input {
        border-radius: 4px;
        border: 1px solid #cccccb;
        padding: 22px;
        //padding-top: 4px;
        height: 49px;
        overflow: hidden;
        text-overflow: ellipsis;        
        background-repeat: no-repeat;
        background-size: 17px;
        background-position: right 10px center;
        @include placeholder {
          color: #808080;
          font-size: 16px;
          letter-spacing: -0.7pt;
        }
        &#location {
          width: 100%;
          margin-right: 10px;
          //background-image: url('../img/location.svg');
          background-color: #F3F5F8;
        }
        &#text_search {
          width: 100%;
          //background-image: url('../img/loop.svg');
          background-size: 19px;
          background-color: #F3F5F8;
        }
        &:focus {
          outline: 0;
          border-color: rgba(0, 0, 255, 0.21);
          box-shadow: 0 0 15px rgba(0, 0, 255, 0.21);
        }
      }
      > p {
        padding-left: 8px;
        font-size: 11px;
        padding-top: 10px;
        letter-spacing: 0.2pt;
        color: #D2D5D8;
      }
    }

  }
 }

@media screen and (max-width: 750px){
  .headerStatus{
    .row{
      width: 100%;
      .wrap{
        width: 100%;
        max-width: 100%;
        .defLogo{
          flex-basis: 30%;
          width: 30%;
          >a>img{
            width: 100%;
          }
        }
        .partBar{
          width: 69%;
           padding-top: 0px; 
           padding-bottom: 0px; 
          .account.tab{
            //background-image:none;
            width: 31%;
            
            >span:last-child{
              font-size: 8px;
              padding-top: 0px; 
              padding-left: 0px; 
            }
          }
        }
      }
    }
  }
  .account-page{
    padding-top: 15px!important;
    .row{
      .wrap{
        .profile{
          .leftBar{
            flex-basis: 35%;
            max-width: 35%;
            width: 35%;
            .pic{
              >p{
                top: 0px;
              }
            }
          }
          .rightBar{
            flex-basis: 90%;
            max-width: 90%;
            margin: 10px;            
            .addProf{
              .inps{
                >div:nth-child(2){
                  margin: 0;
                }
              }
            }
            .bsicInfo{
              .birth{
                flex-basis: 100%;
                max-width: 100%;
              }
              .gender{
                padding-top: 12px;
                flex-basis: 100%;
                max-width: 100%;
              }
            }
            .contactInfo{
              .inps{
                >div{
                  width: 80%;
                }
                input{
                  width: 80%;
                }
                padding-top: 12px;
                >div:nth-child(2){
                  margin:0px;
                  width:75%;
                  padding-top: 12px;
                }
              }
            }
            .specItems .cardSpec{
              width: 50%;
              flex-basis: 50%;
              max-width: 50%;
            }
          }
        }
      }
    }
  }
}

footer {
  position: relative;
  @extend .user-select;
  >.row{
    //background-image: url(../img/fBG.png);
    background-repeat: no-repeat;
    background-position: center 0;
    background-size: cover;
    height: 160px;
  }
  .items {
    padding-top: 66px;
    @extend .flexBox;
    @extend .align_justify;
    .footer-logo {
      width: 26%;
      text-align: center;
      position: relative;
      right: 39px;
      img {
        width: 145px;
      }
      p {
        font-size: 12px;
        color: #ffffff;
        font-weight: 300;
        letter-spacing: 0.3pt;
        padding-top: 10px;
      }
    }
    .social {
      ul {
        li {
          a {
            padding-right: 0;
            padding-left: 13px;
          }
        }
      }
    }
  }

  .circle {
    display: none;
    width: 300px;
    height: 100px;
    background-color: rgb(249, 249, 249);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    background: rgb(249, 249, 249);
    background: -webkit-linear-gradient(top, rgb(249, 249, 249) 0%, rgb(249, 249, 249) 35%, #0096fd 32%);
    background: -o-linear-gradient(top, rgb(249, 249, 249) 0%, rgb(249, 249, 249) 47%, #0096fd 47%);
    background: -ms-linear-gradient(top, rgb(249, 249, 249) 0%, rgb(249, 249, 249) 47%, #0096fd 47%);
    background: linear-gradient(to bottom, rgb(249, 249, 249) 0%, rgb(249, 249, 249) 35%, #0096fd 32%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff ', endColorstr='#ededed ', GradientType=0);
  }
  .halfCirc.halfCircleleft {
    height: 155px;
    width: 135px;
    border-radius: 90px 0 0 104px;
    background: #0096fd;
    transform: scale(-1, 1);
    position: absolute;
    top: 0px;
    left: 0;
  }

  .centerCircl {
    position: absolute;
    top: -70px;
    left: 0;
    right: 0;
    margin: auto;
    background-color: rgb(249, 249, 249);
    border-radius: 50%;
    height: 122px;
    width: 76px;
    z-index: 20;
  }

  .halfCirc.halfCircleright {
    height: 155px;
    width: 135px;
    border-radius: 111px 0 0 115px;
    background: #0096fd;
    transform: rotate(0deg);
    position: absolute;
    top: 0;
    right: 0px;
  }
}
main.list-page.single + footer,
main.list-page.single{
  background-color: #ffffff;
}
main.list-page {
  .results {
    //padding-top: 63px;
    @extend .flexBox;
    @extend .align_justify;
    .Sidebar {
      -ms-flex-preferred-size: 20%;
      flex-basis: 20%;
      max-width: 20%;
    }
    .clinicsList {
      -ms-flex-preferred-size: 80%;
      flex-basis: 80%;
      max-width: 80%;
      padding-left: 22px;
      position: relative;
      padding-bottom: 70px;
      .not-found-clinics {
        text-align: center;
        padding: 20px;
        font-size: 30px;
      }
      .each {
        @extend .flexBox;
        @extend .align_justify;
        margin-bottom: 15px;
        height: 190px;
        padding: 5px 0;
        .logo {
          -ms-flex-preferred-size: 174px;
          flex-basis: 174px;
          max-width: 174px;
          width: 174px;
          height: 174px;
          border-radius: 100%;
          position: absolute;
          overflow: hidden;
          border: 7px solid #dadfe4;
          background-repeat: no-repeat;
          background-size: 80%;
          background-position: center;
          z-index: 999;
          background-color: #ffffff;
          a{
            position: relative;
            width: 100%;
            height: 100%;
            display: block;
          }
        }
        .info {
          max-width: 82%;
          padding-left: 69px;
          padding-top: 14px;
          position: absolute;
          left: 174px;
          min-height: 171px;
          @extend .flexBox;
          @extend .align_justify;
          background-size: 96.2%;
          background-position: 20px -1px;;
          background-repeat: no-repeat;
          .obs {
            position: absolute;
            height: 100%;
            width: 170px;
            background: transparent;
            border-radius: 100%;
            left: -126px;
            box-shadow: inset -5px 1px 26px 1px rgba(216, 216, 216, 0.79);
            -webkit-clip-path: circle(50.0% at 100% 50%);
            clip-path: circle(49% at 100% 49%);
            top: 0;
            display: none;
          }
          .leftSect {
            -ms-flex-preferred-size: 57%;
            flex-basis: 57%;
            max-width: 57%;
            .name {
              color: black;
              letter-spacing: 0.2pt;
              padding-bottom: 13px;
              font-size: 16px;
            }
            .stars {
              height: 23px;
              width: 163px;
              background-repeat: no-repeat;
            }
            .each_one {
              padding-top: 20px;
              > div {
                padding-left: 32px;
                background-repeat: no-repeat;
                margin-bottom: 9px;
                background-position: 0 center;
                padding-bottom: 3px;
                background-size: 20px;
                max-height: 25px;
                overflow: hidden;
                padding-top: 3px;
                &:empty{
                  display: none;
                }
                span {
                  display: inline-block;
                  font-size: 13px;
                  padding-right: 6px;
                  &:not(:last-child):after {
                    content: ',';
                  }
                }
              }
              .specialties {
                white-space: nowrap;
                background-size: contain;  
                color: black;              
                width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                display: inline-block;
                font-size: 13px;
                
              }
              .insurances {
                background-size: contain;
                white-space: nowrap;
                width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                display: inline-block;
                font-size: 13px;
                color: black;
              }
            }
          }
          .rightSect {
            -ms-flex-preferred-size: 40%;
            flex-basis: 40%;
            max-width: 40%;
            > p,
            > div {
              background-repeat: no-repeat;
              background-size: 15px;
              background-position: 0 center;
              padding: 4px 0 4px 32px;
              margin-bottom: 17px;
              font-size: 14px;
            }
            .address {
              color: black;
            }
            .times {
              position: relative;
              p {
                //position: absolute;
                //top: -19px;
              
                span:not(:last-child):after {
                  content: ',';
                }
                span.until{
                  color: #0096fd;
                }
              }
            }
            .specialistFee {
              > span {
                color: #a6b4bc;
                margin-left: 10px;
              }
            }
          }
          &:before {
            content: '';
            display: none;
            position: absolute;
            width: 20px;
            height: 15px;
            bottom: 0;
            border-radius: 100%;
            left: 1px;
            z-index: 31;
            box-shadow: -13px 1px 26px 1px rgba(216, 216, 216, 0.79);
            background: white;
          }
          &:after {
            content: '';
            display: none;
            position: absolute;
            width: 20px;
            height: 15px;
            top: 1px;
            border-radius: 100%;
            left: 1px;
            z-index: 31;
            box-shadow: -17px -2px 26px 1px rgba(216, 216, 216, 0.79);
            background: white;
          }
          .more {
            position: absolute;
            right: 14px;
            bottom: 14px;
            a {
              text-decoration: none;
              text-transform: uppercase;
              padding: 5px 23px 5px 15px;
              font-size: 13px;
            
              color: #0096FD;
              width: 87px;
              height: 27px;
              display: block;
              border-radius: 13px;
              background-repeat: no-repeat;
              background-position: right 6px center;
              background-size: 20px;
              //background-image: url(../img/more_blue.svg);
              @extend .tr3;
            }
          }
          &:hover {
            .more {
              a {
                color: #ffffff;
                background-color: #0096FD;
                //background-image: url(../img/more_grey.svg);
              }
            }
          }
        }
        &:hover {
          .info{

            filter: drop-shadow(-2px 2px 5px #c4c4c4 );
            -webkit-filter: drop-shadow(-2px 2px 5px #c4c4c4 );
          }
        }

        &[data-featured=true]{
          &:after{
            content: '';
            display: block;
            width: 27px;
            height: 40px;
            background-repeat: no-repeat;
            background-size: contain;
            position: absolute;
            right: 20px;
          }
          .info{
            background-position: right -12px center;
            background-size: cover;
            min-height: 177px;
          }
        }
        &.verified{
          .info {
            .leftSect {
              .name{

                display: table;
                padding-right: 35px;
                background-repeat: no-repeat;
                background-size: 19px;
                background-position: right top;
              }
            }
          }
        }
      }
      .np {
        color: #000000;
        font-weight: 800;
        font-size: 14px;
        border: 1px solid #0096FD;
        border-radius: 11px;
        padding: 8px 14px;
        &:hover {
          background-color: #0096FD;
          color: #ffffff;
        }
      }
      .bottom{
        padding-top: 60px;
        .left{
          position: relative;
          top: -18px;
        }
        .newClinic{
          font-size: 16px;
          padding: 9px 16px;
          margin: 4px 0;
          display: table;
          border-radius: 11px;
          color: #ffffff;
          background-color: #0096FD;
          border: 1px solid #0096FD;
          box-shadow: 1px 2px 8px 0px rgba(0, 0, 0, 0.48);
          @extend .tr3;
          &:hover{
            color: #0096FD;
            background-color: #fff;
          }
        }
      }
      .pagination {
        @extend .flexBox;
        justify-content: flex-end;
        width: 80%;
        li {
          list-style-type: none;
          a {
            display: block;
            border-radius: 100%;
            color: #A6B4BC;
            padding: 6pt 7.9pt;
            min-width: 34px;
            min-height: 34px;
            text-align: center;
            margin-right: 8px;
            cursor: pointer;
            outline: 0;
            text-transform: uppercase;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            text-decoration: none;
            @extend .tr3;
            &:hover {
              background-color: #0096FD;
              color: #ffffff;
            }
          }
          &.active {
            a {
              background-color: #0096fd;
              color: #ffffff;
            }
          }
          &.next {
            a {
              @extend .np;
            }
          }
          &.previous {
            a {
              @extend .np;
            }
          }
          &.disabled {
            opacity: .7;
            a {
              cursor: default;
              border-color: transparent;
            }
          }
        }
      }
    }
    img {
      max-width: 100%;
      height: auto;
    }
  }
}

.Sidebar {
  color: #666666;
  >div{
    position: sticky;
    position: -webkit-sticky;
    top: 140px;
  }
  .sHead {
    //background-color: #0096FD;
    padding: 5px 0;
    text-align: center;
    border-radius: 12px 12px 0 0;
    //background-image: url(../img/wizard/top_sidebar.svg);
    background-repeat: no-repeat;
    background-size: 103%;
    background-position: -2px 0;
    h2 {
      color: #ffffff;
      padding-top: 7px;
      font-size: 19px;
      letter-spacing: 2.9pt;
    }
  }
  .sCont {
    min-height: 300px;
    border: 1px solid #dadfe4;
    border-top: 0;
    background-color: #ffffff;
    border-radius: 0 0 12px 12px;
    ////background-image: url(../img/wizard/bottom_sidebar.svg);
    background-repeat: no-repeat;
    background-size: 101%;
    background-position: bottom;
    //border-bottom: 0;
    .near {
      padding: 20px 10px 18px 10px;
      border-bottom: 1px solid #dadfe4;
      display: none;
      .near-head {
        p {
          padding: 5px 0 5px 41px;
          background-repeat: no-repeat;
          letter-spacing: -0.6pt;
          text-transform: capitalize;
        }
      }
      .near-cont {
        padding-top: 32px;
        .u-slider {
          user-select: none;
          height: 7px;
          position: relative;
          display: block;
          background-color: transparent;
          border-radius: 8px;
          width: 194px;
          margin: auto;
          border: 1px solid #dadfe4;
          .value {
            position: absolute;
            border-radius: 3px;
            top: 0;
            height: 100%;
            background-color: rgb(0, 150, 253);
          }
          .handle {
            position: absolute;
            width: 0;
            height: 0;
            &:after {
              width: 22px;
              height: 22px;
              background-color: rgb(255, 255, 255);
              border: 1px solid #abb8bf;
              border-radius: 50%;
              position: relative;
              display: block;
              content: "";
              top: -9px;
            }
          }
        }
        > p {
          text-align: right;
          padding: 25px 17px 0 0;
          font-size: 12px;
        
        }
      }
    }
    .timing, .fees, .rate {
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      padding: 5px;
      border-bottom: 1px solid #dadfe4;
      &[data-open=false] {
        .fees-head,
        .timing-head {
          p {
            &:after {
              transform: rotate(0);
              top: 6px;
            }
          }
        }
        .fees-cont,
        .timing-cont {
          height: 0;
          padding: 0 12px;
        }
      }
      .fees-cont {
        height: 225px;
      }
      .timing-cont {
        height: 108px;
      }
      .fees-head,
      .rate-head,
      .timing-head {
        padding: 12px 0 12px 62px;
        letter-spacing: -0.6pt;
        background-repeat: no-repeat;
        background-size: 29px;
        background-position: 5px center;
        cursor: pointer;
        p:not(.rateP) {
          position: relative;
          &:after {
            content: '';
            display: block;
            position: absolute;
            width: 10px;
            height: 5px;
            right: 8px;
            top: 4px;
            transform: rotate(180deg);
          }
        }
      }
      .rate-head {
        cursor: default;
        background-size: 45px;
      }
      .fees-cont,
      .timing-cont {
        overflow: hidden;
        padding: 15px 12px;
        @extend .tr3;
        > div {
          input {
            display: none;
          }
          label {
            width: 100%;
            cursor: pointer;
            padding-bottom: 17px;
            @extend .flexBox;
            .box {
              width: 15px;
              height: 15px;
              position: relative;
              border-radius: 100%;
              border: 1px solid #dadfe4;
              margin-right: 10px;
              top: 0;
              @extend .tr3;
              &:after {
                content: '';
                position: absolute;
                width: 7px;
                height: 7px;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                margin: auto;
                border-radius: 100%;
                background-color: #1FA9FA;
                opacity: 0;
                @extend .tr3;
              }
            }
            P {
              margin-left: 8px;
            }
            &:hover {
              .box {
                border-color: rgba(31, 169, 250, 0.57);
                &:after {
                  opacity: .2;
                }
              }
            }
          }
          input:checked + label {
            .box {
              border-color: rgb(31, 169, 250);
              &:after {
                opacity: 1;
              }
            }
          }
        }
      }
    }
    .rate {
      .rate-cont {
        padding: 30px 0 30px 16px;
        .stars {
          @extend .flexBox;
          div {
            padding-right: 20px;
            span {
              display: block;
              width: 22px;
              height: 22px;
              background-repeat: no-repeat;
              background-size: cover;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}

.ClinicFull {
  width: 100%;
  .header {
    @extend .flexBox;
    .left {
      -ms-flex-preferred-size: 16%;
      flex-basis: 16%;
      max-width: 16%;
      .logo {
        width: 174px;
        height: 174px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        border-radius: 100%;
        overflow: hidden;
        border: 7px solid #dadfe4;
        box-shadow: 0px 0px 26px 2px rgb(183, 183, 183);
      }
    }
    .right {
      padding-left: 14px;
      -ms-flex-preferred-size: 84%;
      flex-basis: 84%;
      max-width: 84%;
      .top {
        padding-top: 7px;
        padding-bottom: 45px;
        h1 {
          font-size: 40px;
          letter-spacing: 0.4pt;
          max-width: 92%;
          display: table;
          padding-right: 100px;
          position: relative;
          &+p{
            width: 100%;
          }
        }
      }
      .bottom {
        @extend .flexBox;
        > p {
          background-repeat: no-repeat;
          background-size: 22px;
          background-position: 0 center;
          padding: 5px 0 5px 35px;
          margin-right: 41px;
          -ms-flex-preferred-size: 28.3%;
          flex-basis: 28.3%;
          max-width: 28.3%;
          font-size: 14px;
          letter-spacing: 0.1pt;
          max-height: 31px;
        }
        > div:first-of-type {
          background-repeat: no-repeat;
          background-size: 20px;
          background-position: top 35px left;
          padding: 27px 35px;
          width: 100%;
          margin-top: 40px;
          p {
            > span:not(:last-child) {
              &:after {
                content: ',';
              }
            }
          }
        }
      }
    }
  }
  .location {
    padding: 25px 0;
    .GMap-canvas {
      height: 370px;
    }
    .address {
      //background-image: url(../img/location_grey.svg);
      background-repeat: no-repeat;
      background-size: 20px;
      background-position: left top 6px;
      padding: 9px 35px 39px 35px;
      line-height: 30px;
      & > span:not(:last-of-type):after{
        content: ', ';
      }
    }
  }
  .gallery {
    padding: 25px 0;
    .slider {
      width: 100%;
      img {
        width: 100%;
      }
    }
  }
  .description {
    padding: 25px 0;
  }
  h2 {
    color: #a6b4bc;
    padding-bottom: 20px;
  }
  .alls {
    @extend .flexBox;
    justify-content: space-between;
    flex-wrap: wrap;
    > div {
      flex-basis: 100%;
    }

    .Fees {
      flex-grow: 1;
      width: 100%;
      flex-basis: 100%;

    }

    h3 {
      color: #A6B4BC;
      border-left: 2px solid #1FA9FA;
      text-transform: capitalize;
      background-repeat: no-repeat;
      padding: 24px 80px;
      margin-bottom: 20px;
      background-position: 13px center;
      letter-spacing: 0.9pt;
      background-size: 38px;
      background-color: #f4f5f9;
      font-size: 1.25em;
    }
    > div {
      > div {
        //@extend .flexBox;
        padding: 40px 12px;
        -webkit-column-count: 4;
        -moz-column-count: 4;
        column-count: 4;
        display: block;
        > p {
          padding-left: 30px;
          //background-image: url(../img/check.svg);
          background-repeat: no-repeat;
          background-position: left center;
          background-size: 16px;
          position: relative;
          bottom: 2px;
          line-height: 30pt;

          width: 221px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        > p.fee {
          flex-basis: 30%;
          max-width: 30%;
          //background-image: url(../img/fee.svg);
          background-size: 25px 20px;
          background-position: left center;
          padding-left: 35px;
          > span {
            color: #a6b4bc;
            margin-left: 10px;
          }
        }
      }

    }
    >.Fees{
      >div{
        @extend .flexBox;
      }
    }
    &.nM{
      > div {
        > div {
          > p {
            background-size: 16px;
          }
        }
      }
    }
  }
}

.claim:not(.defX){
  //background-image: url(../img/claim.svg);
  padding: 10px 16px 10px 32px;
  background-size: 16px;
  background-repeat: no-repeat;
  background-position: left 5px center;
  max-height: 42px;
  box-shadow: 0 0 9px 1px rgba(172, 173, 173, 0.47);
  border-radius: 4px;
  border: 1px solid rgb(172, 173, 173);
  position: absolute;
  right: 0;
  top: -4px;
  a{
    color: #000000;
    text-decoration: none;
  }
}
.claimedD{
  //background-image: url(../img/green_check.png);
  background-repeat: no-repeat;
  width: 22px;
  background-size: contain;
  background-position: center;
  height: 27px;
  position: absolute;
  right: -9px;
  top: 0px;
  &:after{
    content: 'This facility has already been claimed by its owner';
    position: absolute;
    width: max-content;
    font-size: 12px;
    padding: 5px 10px;
    background-color: #f1f0ed;
    color: #242525;
    top: -27px;
    display: none;
    border-radius: 5px;
  }
  &:hover:after{
    display: block;
  }
}
$green: #008744;
$blue: #0057e7;
$red: #d62d20;
$yellow: #ffa700;
$white: #eee;

// scaling... any units
$width: 100px;

// demo-specific
.showbox {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 5%;
}

// end demo-specific

.loader {
  position: relative;
  margin: 0 auto;
  width: $width;
  &:before {
    content: '';
    display: block;
    padding-top: 100%;
  }
}

.circular {
  animation: rotate 2s linear infinite;
  height: 100%;
  transform-origin: center center;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.path {
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
  stroke-linecap: round;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}

@keyframes color {
  100%,
  0% {
    stroke: $red;
  }
  40% {
    stroke: $blue;
  }
  66% {
    stroke: $green;
  }
  80%,
  90% {
    stroke: $yellow;
  }
}
.timeError{
  font-size: 13px;
  padding-bottom: 5px;
  font-style: italic;
}
.daysList {
  padding-top: 20px;
}
div.defHeader{
  padding-top: 20px;
  background-color: #ffffff;
  .defLogo {
    display: block;
    width: 166px;
    padding-top: 5px;
    padding-right: 7px;
    padding-left: 0px;
  }
  .fully .inputs > div input#location,
  .fully .inputs > div input#text_search{
    background-color: #ffffff;
    margin-right: 10px;
  }
  .fully .bar_filter ul li > div .Select-placeholder{

    background-color: #F3F5F8;
  }
}
.report-page {
  .form {
    padding: 31px 0;
    text-align: center;
    .header {
      .logo {
        padding-bottom: 7px;
        img {
          height: 62px;
        }
      }
      h1 {
        text-transform: uppercase;
        font-size: 21px;
        letter-spacing: 2.6pt;
        color: #767F93;
      }
    }
    form {
      width: 100%;
      max-width: 1027px;
      margin: auto;
      padding-top: 36px;
      position: relative;
      @extend .flexBox;
      .showbox {
        @extend .flexBox;
        background-color: rgba(255, 255, 255, 0.52);
        z-index: 5;
        flex-direction: column;
        -ms-flex-direction: column;
        -webkit-flex-direction: column;
        justify-content: center;
      }
      input[type=text],
      input[type=email] {
        height: 60px;
        border-radius: 4px;
        border: 1px solid #DADFE4;
        padding-left: 15px;
        width: 100%;
      }
      div.e {
        -ms-flex-preferred-size: 49%;
        flex-basis: 49%;
        &:first-child {
          margin-right: 2%;
        }
        label {
          p {
            text-align: left;
            padding: 8px 17px;
            opacity: .8;
            font-size: 14px;
          }
        }
      }
      textarea {
        margin: 10px 0;
        width: 100%;
        border-radius: 4px;
        border: 1px solid #DADFE4;
        height: 153px;
        padding-left: 15px;
        padding-top: 20px;
        overflow: hidden;
      }
      input[type=text],
      input[type=email],
      textarea {
        @include placeholder {
          opacity: .8;
          font-size: 16px;
        }
      }
      .go {
        @extend .flexBox;
        padding-top: 25px;
        flex-direction: row-reverse;
        button {
          margin-right: 12px;
        }
        button, a {
          width: 212px;
          height: 50px;
          text-transform: uppercase;
          color: #ffffff;
          background-color: #0096FD;
          border: 2px solid #0096FD;
          border-radius: 4px;
          text-decoration: none;
          font-size: 17px;
          display: block;
          float: right;
          cursor: pointer;
          padding: 12px 0;
          @extend .tr3;
          &:hover {
            color: #0096FD;
            background-color: #ffffff;
          }
        }
      }
      label.file-att {
        border-radius: 4px;
        border: 1px solid #DADFE4;
        width: 194px;
        height: 50px;
        cursor: pointer;
        .frnt {
          //background-image: url(../img/picture_attach.svg);
          background-repeat: no-repeat;
          text-align: left;
          background-position: right 9px center;
          background-size: 32px;
          padding: 14px 19px;
          @extend .tr3;
          span {
            color: #0096FD;
            letter-spacing: -0.5pt;
          
            font-size: 15px;
            width: 128px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: block;
            white-space: nowrap;
            @extend .tr3;
          }
          &:hover {
            //background-image: url(../img/picture_attach_white.svg);
            background-color: #0096FD;
            span {
              color: #ffffff;
            }
          }
        }
        input {
          display: none;
        }
      }
    }
  }
}


.not-found {
  div.defHeader .fully {
    height: 80px !important;
    min-height: 80px;
  }
  .top {
    text-align: right;
    padding: 37px;
    h1 {
      font-size: 45px;
      letter-spacing: -1.6pt;
      padding-right: 65px;
    }
    p {
      font-size: 23px;
      padding-top: 12px;
      color: #808080;
      padding-bottom: 29px;
      padding-right: 65px;
    }
    button {
      border: 0;
      a {
        @extend .tr3;
        color: #ffffff;
        text-transform: uppercase;
        text-decoration: none;
        background-color: #0096FD;
        padding: 19px 47px;
        display: block;
        border-radius: 4px;
        font-size: 29px;
      
        border: 2px solid #0096FD;
        &:hover {
          background-color: #ffffff;
          color: #0096FD;
        }
      }
    }
  }
  .bottom {
    width: 100%;
    height: 180px;
    //background-image: url(../img/404Full.svg);
    background-repeat: no-repeat;
    background-size: auto 100%;
  }
}

/**
Account
 */
.headerStatus {
  box-shadow: 0px 4px 20px 0px rgba(128, 128, 128, 0.24);
  height: 86px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 400;
  background-color: #ffffff;
  
  .defLogo {
    display: block;
    flex-basis: 166px;
    width: 166px;
    padding-right: 10px;
    padding-top: 25px;
    padding-left: 1px;
  }
  .partBar.account{
    width: 85%;
    .account.tab {
      width: 160px;
      padding: 6px 5px;
      border-radius: 4px;
      //border: 1px solid #DADFE4;
      margin-right: 6px;
      cursor: pointer;
      pointer-events: none;
      &:last-child:hover{
        span:first-child{
          //background-image: url(../img/check_white.svg);
          background-size: 35px;
        }
      }
      span {
        &:first-child {
          width: 32px;
          height: 32px;
          color: #4D4D4D;
        
          border-radius: 100%;
          border: 1px solid #DADFE4;
          text-align: center;
          padding-top: 6px;
        }
        &:last-child {
          padding-left: 8px;
          text-transform: uppercase;
          font-size: 10px;
          color: #4D4D4D;
          padding-top: 10px;
          width: 113px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      &:first-child {
        span:first-child {
          //background-image: url(../img/small_user.svg);
          background-repeat: no-repeat;
          background-size: 11px;
          background-position: center;
          background-color: #0096FD;
        }
      }
      &:last-child {
        span:first-child {
          //background-image: url(../img/check.svg);
          background-repeat: no-repeat;
          background-size: 14px;
          background-position: center;
        }
      }
    }
  }
}

body{
  >div:last-child{
    iframe[sandbox]
    {
      width: 474px !important;
      height: 688px !important;
    }
  }
  &.loggedin{
    .overlay,
    .modall{
      display: none !important;
    }
  }
}
.account-page {
  padding-top: 75px;
  .profile {
    .leftBar {
      width: 18%;
      -ms-flex-preferred-size: 18%;
      flex-basis: 18%;
      max-width: 18%;
      .pic {
        display: flex;
        width: 174px;
        height: 174px;
        border: 7px solid #DADFE4;
        border-radius: 100%;
        cursor: pointer;
        position: relative;
        //background-image: url(../img/big_def_pic.svg);
        background-repeat: no-repeat;
        background-size: 58px;
        background-position: center;
        justify-content: center;
        align-items: center;
        img{
          max-width: 100%;
          height: auto;
          border-radius: 100%;
        }
        >p{
          position: absolute;
          top: 173px;
          text-align: center;
          width: 100%;
        }
        & + input {
          display: none;
        }
        &:after {
          content: '';
          position: absolute;
          width: 46px;
          height: 46px;
          background-color: #0096FD;
          border-radius: 100%;
          right: 0;
          bottom: 0;
          //background-image: url(../img/pencil.svg);
          background-repeat: no-repeat;
          background-position: center;
          background-size: 27px;
        }
        .pined {
          background-repeat: no-repeat;
          background-position: center;
          background-size: 80%;
          width: 100%;
          height: 100%;
          border-radius: 100%;
        }
      }
      .error + .pic{
        border-color: darkred;
      }
    }
    .rightBar {
      color: #4D4D4D;
      -ms-flex-preferred-size: 82%;
      flex-basis: 82%;
      max-width: 82%;
      position: relative;
      padding-bottom: 170px;

      .error + input,
      .error + span + input,
      .error_count + span + input,
      .error_count + span,
      .error_count + input{
        border: 1px solid darkred;
        outline: 0;
        margin-bottom: 30px;
      }
      .error_count + span + input{
        border-left:0;
      }
      .error + .Select{
        margin-bottom: 30px;
      }
      .error + .Select .Select-control {
        border: 1px solid darkred;
        outline: 0;
      }
      .error:after {
        content: 'This field is require';
        display: block;
        padding: 5px 0;
        color: darkred;
        position: absolute;
        bottom: 0;
        font-size: 13px;
      }
      .error.email:after {
        content: 'Please fill the correct value';
      }
      .error.myInsurances:after {
        bottom: 25px;
      }
      .error_count:after {
        content: 'Minimum 10 numbers';
        display: block;
        padding: 5px 0;
        color: darkred;
        position: absolute;
        bottom: 0;
        right: 0;
        font-size: 13px;
      }
      h2 {
        font-size: 16px;
        padding-top: 5px;
        padding-bottom: 25px;
      }
      .addProf {
        h2 {
          font-size: 17px;
          padding-top: 5px;
          padding-bottom: 25px;
        }
        h3 {
          font-size: 14px;
          padding-top: 12px;
          letter-spacing: -0.2pt;
        
          &+span{
            font-size: 13px;
            padding-top: 6px;
            display: block;
          }
        }
      }
      .selects.Clc .Select{
        width: 32%;
        margin-right: 1%;
      }
      .bsicInfo {
        padding-top: 25px;
        h2 {
          font-size: 16px;
          padding-top: 5px;
          padding-bottom: 25px;
        }
        .birth {
          -ms-flex-preferred-size: 50%;
          flex-basis: 50%;
          max-width: 50%;
          .selects{
            > .Select{
              flex-basis: 29%;
              max-width: 29%;
              height: 50px;
              background-color: #f4f5f9;
              &:nth-child(2){
                margin: 0 11px;
              }
            }
          }
          .selects.flexBox > div:nth-child(2) {
            margin: 0 11px;
          }
          .selects.flexBox > div {
            -ms-flex-preferred-size: 29%;
            flex-basis: 29%;
            max-width: 29%;
            height: 50px;
            background-color: #f4f5f9;
          }
        }
        .gender {
          -ms-flex-preferred-size: 50%;
          flex-basis: 50%;
          max-width: 50%;
          .radios {
            padding-top: 10px;
          }
        }
        &.op{
          position: relative;
          .error:after{
            bottom:auto;
            top: 50px;
          }
        }
      }
      .contactInfo {
        padding-top: 60px;
      }
    }
    .rightBar.RR{
      width: 100%;
      min-width: 100%;
      ////background-image: url(../img/why.svg);
      background-repeat: no-repeat;
      background-size: 619px auto;
      background-position: right 116px bottom;
    }
    .secs {
      padding-top: 50px;
      justify-content: space-between;
      > div {
        -ms-flex-preferred-size: 48%;
        flex-basis: 48%;
        max-width: 48%;
      }
    }
    .Select-placeholder {
      font-size: 14px;
    
      color: rgba(0, 0, 0, 0.68);
      padding-top: 8px;
    }
    .Select-control {
      height: 50px;
      border: 1px solid #dadfe4;
    }
    .Select-value, .Select-input {
      padding-top: 8px;
    }
    h4 {
      font-size: 16px;
      font-weight: 500;
      padding-bottom: 12px;
      color: #A6B4BC;
    }
    h2 {
      font-size: 17px;
    }
    h3 {
      font-size: 14px;
      padding-top: 12px;
      letter-spacing: -0.2pt;
    
    }
    input[type=radio],
    input[type=checkbox] {
      display: none;
      & + label {
        margin-right: 100px;
        span {
          &:first-child {
            width: 23px;
            height: 23px;
            display: inline-block;
            border: 1px solid #dadfe4;
            background-color: #ffffff;
            position: relative;
            cursor: pointer;
            top: 5px;
            margin-right: 10px;
            &:after {
              content: '';
              display: none;
              width: 12px;
              height: 12px;
              left: 0;
              right: 0;
              bottom: 0;
              top: 0;
              position: absolute;
              margin: auto;
              background-color: #0096FD;
            }
          }
          &:last-child {
            font-size: 14px;
          
          }
        }
      }
      &:checked + label {
        span {
          &:first-child {
            &:after {
              display: block;
            }
          }
        }
      }
    }
    .inps {
      .plusNumber{
        display: inline-block;
        padding: 15px 4px 15px 4px;
        border: 1px solid #dadfe4;
        border-radius: 4px;
        border-right: 0;
        position: relative;
        // top: 1pt;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        left: 0;
        background-color: rgba(128, 128, 128, 0.14);
        cursor: default;
      }
      >div{
        position: relative;
      }
      padding-top: 10px;
      > div:nth-child(2) {
        margin: 0 12px;
      }
      label {
        display: block;
        color: #A6B4BC;
        padding-bottom: 10px;
        & + p {
          padding-bottom: 10px;
          font-size: 13px;
        }
      }
      input {
        width: 312px;
        height: 50px;
        border-radius: 4px;
        border: 1px solid #dadfe4;
        padding: 10px;
        font-size: 14px;
        background-color: #ffffff;
        position: relative;
      }
      .plusNumber + input{
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
      textarea{
        width: 572px;
        height: 156px;
        border-radius: 4px;
        border: 1px solid rgb(218, 223, 228);
        padding: 10px;
        font-size: 14px;
        background-color: #ffffff;
        margin: 0;
        max-width: 600px;
        max-height: 156px;
        overflow: hidden;
      }
    }
    .ClinicFull {
      .header {
        padding-bottom: 30px;
      }
    }
    .specialties {
      padding-top: 30px;
      position: relative;
      button {
        display: none;
        width: 125px;
        -ms-flex-preferred-size: 125px;
        flex-basis: 125px;
        max-width: 125px;
        height: 50px;
        background-color: #0096FD;
        border: 1px solid #0096FD;
        border-radius: 4px;
        cursor: pointer;
        color: #ffffff;
        text-transform: uppercase;
        &:hover {
          background-color: #002cfd;
        }
      }
      > div {
        width: 85%;
        -ms-flex-preferred-size: 85%;
        flex-basis: 85%;
        max-width: 85%;
      }
    }
    .specItems {
      padding-top: 40px;
      .cardSpec {
        width: 19%;
        -ms-flex-preferred-size: 19%;
        flex-basis: 19%;
        max-width: 19%;
        margin-bottom: 20px;
        height: 48px;
        box-shadow: 0 1px 20px 4px rgba(128, 128, 128, 0.24);
        padding: 14px 19px;
        position: relative;
        display: flex;
        align-items: center;
        background-color: #f9f9f9;
        &:not(:nth-child(5n)) {
          margin-right: 1%;
        }
        &:hover {
          box-shadow: 0 1px 20px 4px rgba(128, 128, 128, 0.40);
        }
        .close {
          width: 27px;
          height: 27px;
          -webkit-border-radius: 100%;
          -moz-border-radius: 100%;
          border-radius: 100%;
          border: 1px solid #b9b9b9;
          //background-image: url(../img/close_pink.svg);
          background-size: 11px;
          background-position: center;
          cursor: pointer;
          display: block;
          background-repeat: no-repeat;
          position: absolute;
          right: 9px;
          top: 12px;
        }
        span {
          font-size: 13px;
        
          white-space: nowrap;
          width: 10em;
          overflow: hidden;
          text-overflow: ellipsis;
          display: block;
        }
      }
    }
    .infA {
      padding-top: 20px;
      position: relative;
      padding-bottom: 10px;
      label {
        width: 180px;
        height: 39px;
        display: block;
        border-radius: 4px;
        border: 2px solid #0096fd;
        padding: 7px;
        font-size: 14px;
      
        letter-spacing: 0;
        cursor: pointer;
        //background-image: url(../img/file.svg);
        background-repeat: no-repeat;
        background-size: 17px;
        background-position: right 10px center;
      }
      .error + label{
        border: 2px solid darkred;
      }
      input {
        display: none;
      }
      .attached {
        display: block;
      
        font-size: 14px;
        padding: 10px 10px 10px 0;
        span{
          display: inline-block;
          font-size: 18px;
          border-radius: 100%;
          background-color: #c3c3c3;
          padding: 0 8px 2px 8px;
          cursor: pointer;
        }
        > div > div{
          width: 300px;
          height: 200px;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          position: relative;
          div{
            width: 20px;
            height: 20px;
            border-radius: 100%;
            background-color: #e8e8e8;
            color: #000000;
            text-align: center;
            right: 5px;
            top: 5px;
            position: absolute;
            cursor: pointer;
          }

        }
      }
    }
    .attach.attC{
      padding: 20px 0;
    }
  }
}
span.req{
  padding-left: 3px;
  color: darkred;
}
p.fix {
  padding: 10px;
  border: 1px solid darkred;
  color: red;
}
body.sentAfterLogin .nTop + div{
  display: none;
}
.loginLabel .fbName{
  padding-top: 28px;
  font-size: 18px;
}
.map {

  .GMap{
    position: relative;
    .current{
      //background-image: url(../img/current_position.svg);
      height: 60px;
      width: 60px;
      position: absolute;
      z-index: 100;
      right: 55px;
      bottom: 61px;
      padding: 20px;
      background-size: 28px;
      background-position: center;
      background-repeat: no-repeat;
      background-color: #0096FD;
      border-radius: 100%;
      overflow: hidden;
      cursor: pointer;
    }
  }
  .GMap-canvas{
    min-height: 400px;
    zoom: 117%;
  }

  .highlight{
    padding-top: 5px;
    margin-bottom: 30px;
    font-size: 14px;
    color: #bfbfbf;
  }
}
.profile.clinicRD{
  .xFt{
    padding-bottom: 10px;
    .lOp{
      input{
        position: relative;
      }
      input:after{
        content: 'OMR';
        display: block;
        position: absolute;
        right: 0;
      }
    }
  }
  .days{
    width: 40%;
    flex-basis: 40%;
    margin-right: 200px;
    &#oYo_p{
      margin-right: 0;
      max-height: 130px;
    }
    .cardSpec{
      flex-basis: 30%;
      width: 30%;
      padding: 6px;
      text-align: center;
      border: 2px solid #dadfe4;
      margin-bottom: 13px;
      cursor: pointer;
      max-height: 33px;
      background-color: #ffffff;
      &:hover,
      &.opened{
        border-color: #0096FD;
        background-color: #0096fd;
        color: #ffffff;
      }
      &.fixed {
        background-color: #383b3e;
      }
    }
  }
  .times{
    width: 20%;
    flex-basis: 20%;
    > div {
      label {
        padding: 6px;
        text-align: center;
        border: 2px solid #dadfe4;
        margin-bottom: 13px;
        cursor: pointer;
        color: #4D4D4D;
        margin-right: 0;
        width: 100%;
        background-color: #ffffff;
        &:hover{
          border-color: #0096FD;
          background-color: #0096fd;
          color: #ffffff;
        }
      }
      input:checked + label{
        border-color: #0096FD;
        background-color: #0096fd;
        color: #ffffff;
      }
    }
  }
  .timesPicker{
    width: 27%;
    flex-basis: 27%;
    >div{
      width: 100%;
      margin-bottom: 15px;
      >div{
        width: 48%;
        flex-basis: 48%;
        &.splShift.one{
          width: 100%;
          flex-basis: 100%;
          margin-top: 15px;
        }
        >span{
          display: block;
          width: 100%;
          input{
            max-width: 100%;
          }
        }
      }
    }
  }
  .setter{
    flex-basis: 8%;
    width: 8%;
    margin-left: 25px;
    align-items: center;
    justify-content: center;
    .set{
      width: 100%;
      padding: 6px;
      text-align: center;
      border: 2px solid #dadfe4;
      margin-bottom: 13px;
      cursor: pointer;
      max-height: 33px;
      background-color: #ffffff;
      &:hover,
      &.opened{
        border-color: #0096FD;
        background-color: #0096fd;
        color: #ffffff;
      }
    }
  }
  .splShift{
    width: 40px;
    padding: 6px;
    text-align: center;
    border: 2px solid #dadfe4;
    margin-bottom: 13px;
    cursor: pointer;
    background-color: #ffffff;
    &:hover,
    &.opened{
      border-color: #0096FD;
      background-color: #0096fd;
      color: #ffffff;
    }
  }
  .daysList{
    .item{
      font-size: 19px;
      padding-bottom: 10px;
      .name{
        flex-basis: 25%;
        width: 25%;
      }
      .time{
        flex-basis: 24%;
        width: 24%;
      }
      .remove{
        flex-basis: 20%;
        width: 20%;
        .delButt{
          span{
            color: #000000;
            cursor: pointer;
          
            padding: 0 15px;
            display: table;
          }
        }
      }
    }
    .table{
      td {
        padding: 13px 7px;
      }
      tr {
        font-size: 15px;
        text-align: center;
      }
      th {
        font-size: 20px;
        padding: 0 10px;
      }
    }
  }
  .inps.mkO{
    >div:first-child{
      width: 60%;
      position: relative;
      input{
        width: 100%;
      }
    }
    > div:nth-child(2) {
      margin: 9px 0;
      width: 80%;
      textarea{
        width: 100%;
        min-width: 100%;
        overflow: hidden;
      }
    }
  }
  .inps.mkO.RT{
    > div:nth-child(1) {
      margin: 9px 0;
      width: 27%;
      margin-right: 4%;
      input{
        width: 100%;
        min-width: 100%;
      }
    }
    > div:nth-child(2) {
      margin: 9px 0;
      width: 27%;
      margin-right: 4%;
      input{
        width: 100%;
        min-width: 100%;
      }
    }
    > div:nth-child(3) {
      margin: 9px 0;
      width: 27%;
      input{
        width: 92%;
        min-width: 92%;
      }
    }
  }
}
.daysList{
  .table{
    td {
      padding: 13px 7px;
    }
    tr {
      font-size: 15px;
      text-align: center;
    }
    th {
      font-size: 20px;
      padding: 0 10px;
    }
  }
}
.pagin {
  padding-top: 30px;
  width: 100%;
  //justify-content: flex-end;
  @extend .flexBox;
  .butts{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  > a{
    &.plLog {
      padding: 15px 20px;
      display: block;
      color: rgb(255, 255, 255);
      background-color: rgb(0, 150, 253);
      width: 100%;
      border-radius: 50px;
    }
  }
  p.acts {
  
    cursor: pointer;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    border-top-right-radius: 2em;
    border-bottom-right-radius: 2em;
    border-top-left-radius: 2em;
    border-bottom-left-radius: 2em;
    padding: 15px 15px;
    box-shadow: 0 2px 23px 2px rgba(128, 128, 128, 0.24);
    text-align: center;
    background-color: #ffffff;
    margin:0;
    &.save{
      background-color: #ff552c;
      color: #ffffff;
    }
    &:hover{
      box-shadow: 0 2px 23px 2px rgba(128, 128, 128, 0.35);
    }
    &#prev {
      &:not(:last-child) {
        margin-right: 40px;
      }
      .arrow {
        transform: rotate(180deg)
      }
    }
    &#next {
      padding-left: 44px;
    }
    &#update{
      text-align: center;
      width: 110px;
      span.arrow{
        text-align: center;
        transform: rotate(90deg);
      }
    }
    &[data-active=false]{
      position: relative;
      cursor: not-allowed;
      &:after{
        content: 'Please fill required fields in all branches';
        display: none;
        position: absolute;
        padding: 5px;
        color: #ffffff;
        background-color: #000000;
        font-size: 10px;
        border-radius: 5px;
        opacity: 0.8;
        top: -26px;
        width: 100%;
        left: 0;
      }
      &:hover{
        box-shadow: 0 2px 23px 2px rgba(128, 128, 128, 0.24);
        &:after{
          display: block;
        }
      }
    }
    span {
      pointer-events: none;
      text-transform: uppercase;
    }
    span.arrow {
      display: block;
      width: 19px;
      height: 19px;
      //background-image: url('../img/arrow_orange.svg');
      background-repeat: no-repeat;
      background-size: 17px;
      background-position: center;
      margin: 0 10px;
      position: relative;
      //top: 2px;
    }
  }
}


/*Why thrive*/

.firstStep{
  margin: auto;
  .content {
    max-width: 1000px;
    margin: auto;
    >div{
      box-sizing: border-box;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      margin-right: 0;
      margin-left: 0;
      width: 100%;
      margin-bottom: 70px;
      &:nth-child(2n-1) {
        justify-content: flex-start;
        .text {
          padding-left: 15px;
          padding-top: 48px;
          width: 78%;
        }
        .icon{
          padding-top: 45px;
        }
      }
      &:nth-child(2n) {
        justify-content: flex-end;
        .text {
          width: 79%;
          padding-right: 25px;
          padding-top: 0;
          text-align: right;
        }
      }
      &:nth-child(2n) .icon {
        -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
        order: 2;
      }
    }
    .arrow1,
    .arrow2,
    .arrow3{
      position: absolute;
    }
    img.arrow1 {
      left: -30px;
      top: 339px;
    }
    img.arrow2 {
      left: 400px;
      top: 319px;
    }
    img.arrow3 {
      right: 180px;
      bottom: 230px;
    }
  }
  h1 {
    text-align: center;
    color: #0096FD;
    padding-bottom: 5px;
    font-size: 30px;
  }
  h2{
    padding-bottom: 40px;
    text-align: center;
    font-size: 14.8pt;
  }
  p{
    padding-bottom: 7px;
    font-size: 19px;
    line-height: 30pt;
    white-space: pre-wrap;
    color: #686666;
  }
  div{
    h3 {
      font-size: 24px;
      color: #0096FD;
      font-weight: 400;
    }
  }

}

/**/

/*Clinic Wizard*/
.account-page-wizard{
  padding-top: 45px;
  >.row{
    z-index: 1000;
  }
  .mobile{
    display: none;
  }
}
.topBar{
  padding-bottom: 35px;
  .head{
    h2{
      font-weight: bold;
      color: #4d4d4d;
      font-size: 22px;
      padding-bottom: 10px;
    }
  }
  .thrive{
    a{
      display: inline-block;
      padding: 11px 28px;
      font-size: 20px;
      border-radius: 30px;
      border: 2px solid #0096fd;
      box-shadow: 0 2px 23px 2px rgba(128, 128, 128, 0.24);
      background-color: #ffffff;
    
      text-transform: uppercase;
      color: #0096fd;
    }
  }
}
.selectFacility{
  .facilityBox{
    width: 77%;
    position: relative;
    >div{
      width: 100%;
      height: 100%;
      position: absolute;
      -webkit-transition: -webkit-transform 10.5s;
      -moz-transition: -moz-transform 0.5s;
      -o-transition: -o-transform 0.5s;
      transition: transform 0.5s;
      -webkit-transform-style: preserve-3d;
      -moz-transform-style: preserve-3d;
      -o-transform-style: preserve-3d;
      transform-style: preserve-3d;
    }
    .firstBox,
    .secondBox{
      border-radius: 12px;
      border: 1px solid #dadfe4;
      padding: 40px;
      min-height: 417px;
      position: absolute;
      top: 0;
      width: 100%;
      -webkit-backface-visibility: hidden;
      -moz-backface-visibility: hidden;
      -o-backface-visibility: hidden;
      backface-visibility: hidden;
      background-color: #ffffff;
    }
    .secondBox{
      -webkit-transform: rotateY( 180deg );
      -moz-transform: rotateY( 180deg );
      -o-transform: rotateY( 180deg );
      transform: rotateY( 180deg );
      .back{
        width: 17px;
        height: 20px;
        //background-image: url(../img/wizard/back.png);
        background-size: contain;
        position: absolute;
        right: 15px;
        top: 15px;
        cursor: pointer;
        background-repeat: no-repeat;
      }
      .branches {
        width: 200px;
        height: 200px;
        border-radius: 12px;
        border: 2px solid #0095fc;
        padding: 20px;
        margin: 56px auto;
        background-color: #f3f5f6;
        .up, .down{
          width: 100%;
          height: 20px;
          cursor: pointer;
          background-repeat: no-repeat;
          background-size: contain;
          display: block;
          margin: auto;
          background-position: center;
        }
        .count{
          margin: 0 auto 15px;
          color: #0095fc;
          display: block;
          input{
            width: 100%;
            text-align: center;
            border: 0;
            font-size: 70px;
            outline: 0;
            margin-top: 5px;
            background-color: #f3f5f6;
            font-family: tahoma;
            line-height: 0;            
            /* For Firefox */
            &{
              -moz-appearance:textfield;
            }
            /* Webkit browsers like Safari and Chrome */
            &::-webkit-inner-spin-button,
            &::-webkit-outer-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }
          }
          span{
            color: rgba(0, 149, 252, 0.55);
            display: block;
            text-align: center;
            text-transform: uppercase;
          }
        }
      }
    }
    &[data-flipped=true] > div{
      transform: rotateY( 180deg );
      .firstBox{
        pointer-events: none;
        opacity: 0;
      }
    }
    h3{
      text-align: center;
    
    }
    .list{
      padding-top: 35px;
      width: 350px;
      margin: auto;
      >div{
        width: 155px;
        border-radius: 12px;
        background-color: #ffffff;
        border: 1px solid #e1e5e9;
        height: 155px;
        cursor: pointer;
        align-items: flex-end;
        justify-content: center;
        @extend .tr3;
        >div{
          width: 100%;
          height: 100%;
          background-repeat: no-repeat;
          background-position: center top 16px;
          align-items: flex-end;
          justify-content: center;
          padding: 15px;
        }
        &:hover, &[data-selected=true]{
          background-color: #2e97ff;
          >div{
            filter: invert(100%);
            -webkit-filter: invert(100%);
          }
        }
      }
    }
  }
}
.typeahead{
  position: relative;
  .typeahead-selector{
    display: none;
    position: absolute;
    top: 51px;
    width: 100%;
    border: 1px solid #aaa;
    background-color: #fff;
    // font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: 16px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    max-height: 192px;
    overflow-y: auto;
    z-index: 2;
    li{
      cursor: pointer;
      padding: 10px 20px;
      a{
        color: unset;
      }
      &:hover{
        background-color: #ddd;
      }
    }
    &.with-icons{
      li{
        align-items: center;
        padding: 10px;
        flex-wrap: nowrap;
        span{
          &:first-child{
            width: 35px;
            height: 35px;
            display: inline-block;
            background-size: cover;
            border-radius: 100%;
            border: 1px solid #0096fd;
            margin-right: 10px;
            background-position: center;
            background-repeat: no-repeat;
          }
        }
      }
    }
  }
  input:focus + .typeahead-selector{
    display: block;
  }
  .typeahead-selector:hover{
    display: block;
  }
  input:invalid + .typeahead-selector,
  .typeahead-selector:empty{
    display: none !important;
  }
}
.Sidebar.S{
  -ms-flex-preferred-size: 20%;
  flex-basis: 20%;
  max-width: 20%;
  background-color: transparent;
  > div{
    top: 100px;
  }
  .sCont{
    padding: 40px 15px 40px 15px;
    line-height: 21pt;
    letter-spacing: 0.5pt;
    font-size: 15px;
    background-color: #ffffff;
    min-height: 379px;
  }
  .Logo{
    height: 230px;
  }
  .text {
    h4 {
      color: #4d4d4d;
      text-transform: uppercase;
    
      font-size: 12pt;
    }
    p{
      font-size: 14px;
      letter-spacing: 0;
      color: #b5b5b5;
      line-height: 13pt;
    }
  }
  .list{
    li{
      counter-increment: myIndex;
      margin: 6px 0;
      position: relative;
      left: 15px;
      border-top: 1px solid transparent;
      border-bottom: 1px solid transparent;
      cursor: pointer;
      &:after{
        content:counter(myIndex);
        width: 40px;
        height: 40px;
        text-align: center;
        -webkit-border-radius:100%;
        -moz-border-radius:100%;
        border-radius:100%;
        background-color: #ffffff;
        color: #616161;
        @extend .flexBox;
        position: absolute;
        align-items: center;
        justify-content: center;
        left: -24px;
        top: 0;
      }
      .edit{
        width: 15px;
        height: 15px;
        //background-image: url(../img/wizard/pencil.png);
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        position: absolute;
        right: 16px;
        top: 0;
        bottom: 0;
        margin: auto;
        cursor: pointer;
        display: none;
      }
      .warn{
        //background-image: url(../img/wizard/mark.png);
        pointer-events: none;
        width: 5px;
        height: 20px;
        position: absolute;
        right: 26px;
        background-size: contain;
        top: 0;
        bottom: 0;
        margin: auto;
        background-repeat: no-repeat;
        &.true{
          //background-image: url(../img/wizard/warn_check.png);
          width: 20px;
          right: 15px;
        }
      }
      &[data-active=true]{
        border-top: 1px solid #dde2e6;
        border-bottom: 1px solid #dde2e6;
        background-color: #ffffff;
        width: 118.7%;
        input{
          width: 65%;
          background-color: #ffffff;
        }
        &:after{
          background-color: #2e97ff;
          color: #ffffff;
        }
        .edit {
          right: 12px;
          display: block;
        }
        .warn{
          //display: none;
          right: 65px;
          &.true{
            right: 54px;
          }
        }
      }
    }
    input{
      padding: 6px 5px 6px 25px;
      width: 77%;
      border: 0;
      font-size: 18px;
    
      outline: 0;
      cursor: pointer;
      color: #616161;
      pointer-events: none;
      background-color: #f9f9f9;
      &:focus{
        pointer-events: unset;
        + .edit{
          display: none;
        }
      }
    }
  }
  &[data-error-click=true]{
    .list{
      li{
        .warn:not(.true){
          animation: pulsate .6s ease-out;
          -webkit-animation: pulsate .6s ease-out;
          animation-iteration-count: infinite;
          -webkit-animation-iteration-count: infinite;
        }
      }
    }
  }
}

.partBar {
  padding-top: 20px;
  padding-bottom: 20px;
  .account.tab {
    width: auto;
    padding: 6px 0 6px 16px;
    border-radius: 4px;
    margin-right: 6px;
    background-color: #ffffff;
    //background-image: url(../img/wizard/step.svg);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    &[data-active=false][data-prev=false]{
      //background-image: url(../img/wizard/step_mini.svg);
      padding: 6px 24px 6px 24px;
    }

    &[data-prev=true],
    &[data-active=true] + div {
      cursor: pointer;
      &:hover{
        .number{
          color: #ffffff;
          border: 1px solid #0096fd;
          background-color: #0096fd;
        }
      }

    }
    &[data-active=true] + div{
      &[data-can=false]{
        position: relative;
        &:after{
          content: 'Please fill required fields in all branches';
          display: none;
          position: absolute;
          padding: 5px;
          color: #ffffff;
          background-color: #000000;
          font-size: 10px;
          border-radius: 5px;
          opacity: 0.8;
          top: -22px;
          width: 207px;
          left: 0;
        }
        &:hover:after{
          display: block;
        }
      }
    }
    span {
      &.number {
        width: 32px;
        height: 32px;
        color: #4D4D4D;
      
        border-radius: 100%;
        border: 1px solid #dde1e6;
        text-align: center;
        padding-top: 6px;
      }
      &.title {
        padding-left: 8px;
        text-transform: uppercase;
        font-size: 10px;
        color: #4D4D4D;
        padding-top: 10px;
        width: 113px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: none;
      }
    }
    &[data-active=true],
    &[data-prev=true]{
      .title{
        display: block;
      }
    }
  }
}
.name-input .action{
  @extend .flexBox;
  width: 20px;
  left: -7px;
  background-color: #f3f5f8;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  border: 1px solid #dadfe4;
  font-size: 12px;
  color: #2e97ff;
  cursor: pointer;
  position: relative;
}
.wizardMain{
  .steps{
    border-radius: 12px;
    border: 1px solid #dadfe4;
    padding: 40px;
    min-height: 417px;
    width: 77%;
    background-color: #ffffff;
    &[data-preview=true]{
      width: 100%;
      border-radius: 0;
      border: 0;
      padding: 0;
      //background-color: #f4f5f9;
    }
  }
  .inputWrap{
    padding-bottom: 10px;
    &.s3{
      width: 23%;
    }
    &.s2{
      width: 47%;
    }
    &.s4{
      width: 30%;
    }
    .title{
      padding-bottom: 0.4em;
      font-family: 'tfBold';
      h2, label{
        font-size: 14px;
        color: #4d4d4d;
        padding-bottom: 4px;
        display: block;
      }
      .apply{
        @extend .tr3;
        font-size: 10px;
        border-radius: 5px;
        border: 1px solid #0096fd;
        background-color: #ffffff;
        color: #0096fd;
        padding: 3px;
        position: relative;
        top: 1px;
        cursor: pointer;
        margin-left: 30px;
        margin-right: 30px;
        &:hover{
          color: #ffffff;
          background-color: #0096fd;
        }
      }
    }
    .subTitle{
      p{
        font-size: 12px;
      }
    }
    input, textarea{
      width: 100%;
      height: 50px;
      border-radius: 4px;
      border: 1px solid #dadfe4;
      padding: 10px;
      font-size: 14px;
      background-color: #f9f9f9;
      position: relative;
      margin: 5px 0;
      outline: 0;
      overflow: hidden;
      &:focus{
        border: 1px solid #9da0a4;
      }
    }
    textarea{
      height: 140px;
      max-height: 140px;
      resize: none;
      overflow: hidden;
    }
    .inputParent{
      > div.fRt{
        &[data-error=true]{
          border-color: darkred;
          input{
            border-color: darkred;
          }
        }
      }
    }
    .extra{
      justify-content: space-between;
      padding: 5px 0;
      .arabic{
        color: #4d4d4d;
        font-size: 13px;
        position: relative;
        padding: 5px 0 5px 0;
        cursor: pointer;
        width: auto;
        align-items: center;
        .actName{
          padding-right: 5px;
        }
      }
    }
    &.number{
      .inputParent{
        > div.fRt{

          &[data-error=true]{
            border-color: darkred;
            input{
              border-color: darkred;
            }
          }

          margin-bottom: 7px;
          height: 33px;
          @extend .flexBox;
          border-radius: 5px;
          border: 1px solid #dadfe4;
          position: relative;
          margin-top: 1px;
          background-color: #f9f9f9;
          select{
            background-color: #eaedf0;
            border: 0;
            box-shadow: none;
            outline: 0;
            color: #4d4d4d;
            -webkit-appearance:none;
            cursor: pointer;
            padding-left: 5px;
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
          }
          .country{
            width: 88px;
            border-right: 1px solid #f9f9f9;
          }
          .code{
            width: 62px;
            &.l{
              width: 32px;
              input[type=number]{
                width: 115px;
              }
            }
          }
          input{
            width: 100%;
            border: 0;
            margin: 0;
            padding: 5px 5px 5px 25px;
            outline: 0;
            height: 100%;
            color: #4d4d4d;
            &[type=number]{
              width: 95px;
              /* For Firefox */
              &{
                -moz-appearance:textfield;
              }
              /* Webkit browsers like Safari and Chrome */
              &::-webkit-inner-spin-button,
              &::-webkit-outer-spin-button {
                -webkit-appearance: none;
                margin: 0;
              }
            }
          }
        }
        > div.info{
          padding-bottom: 2px;
          p{
            padding-right:35px;
            font-size: 12px;
            color: #b5b5b5;
            &:last-child{
              padding-left: 11px;
            }
          }
        }
        .action{
          position: absolute;
          right: -10px;
          left:unset;
          top: 0;
          bottom: 0;
          margin: auto;
          cursor: pointer;
        }
      }
    }
    p.setDef {
      width: 35%;
      font-size: 11px;
      padding-top: 4px;
      color: #b5b5b5;
      cursor: pointer;
      position: relative;
      // span{
      //   &:last-child {
      //     display: block;
      //     width: 15px;
      //     height: 15px;
      //     position: relative;
      //     top: -2px;
      //     margin-left: 5px;
      //     border: 1px solid #b5b5b5;
      //   }
      // }
      &[data-checked=true]{
        &:after{
          content: '';
          background-image: url(../img/warn_check.png);
          background-size: 25px;
          position: absolute;
          right: 6px;
          bottom: 3px;
          width: 25px;
          height: 25px;
          pointer-events: none;
        }
      }
    }
  }

  .fieldsContact{
    .title{
      padding: 0;
      margin: 0;
      label{
        font-size: 17px;
        margin-bottom: 0;
        padding-bottom: 0;
      }
    }
  }


  .message{
    font-size: 14px;
    color: #b5b5b5;
    padding-top: 5px;
  }

  p.err{
    font-size: 12px;
    color: darkred;
    text-align: left;
    padding-bottom: 10px;
  }

  .fileContainer{
    box-shadow: none;
    background-color: transparent;
    padding: 0;
    display: block;
    height: 50px;
    .many, .errorsContainer{
      display: none;
    }
    .chooseFileButton{
      width: 180px;
      height: 39px;
      display: block;
      border-radius: 4px;
      border: 2px solid #0096fd;
      padding: 7px;
      font-size: 14px;
    
      letter-spacing: 0;
      cursor: pointer;
      //background-image: url(../img/file.svg);
      background-repeat: no-repeat;
      background-size: 17px;
      background-position: right 10px center;
      background-color: transparent;
      color: #4d4d4d;
      text-align: left;
      &:hover{
        background-color: #0096fd;
        color: #ffffff;
        background-position: right 10px center;
      }
    }
    .uploadPicturesWrapper{
      display: none;
      .uploadPictureContainer{
        margin: 15px 17px 0 0;
        padding: 0;
        width: 177px;
        height: 120px;
        >div{
          justify-content: flex-start !important;
        }
      }
    }
  }

  .imagesList{
    min-height: 180px;
    border: 1px solid #dadfe4;
    padding: 5px;
    li{
      width: 32.5%;
      height: 155px;
      position: relative;
      background-size: cover;
      background-position: center;
      margin-right: 10px;
      margin-bottom: 10px;
      &:nth-child(3n){
        margin-right: 0;
      }
      .action{
        position: absolute;
        right: 5px;
        bottom: 5px;
        color: red;
        font-size: 60px;
        cursor: pointer;
        &:after {
          // content: '-';
          position: absolute;
          top: -13px;
        }
      }
    }
  }
  .pic {
    display: flex;
    width: 174px;
    height: 174px;
    border: 7px solid #DADFE4;
    border-radius: 100%;
    cursor: pointer;
    position: relative;
    //background-image: url(../img/wizard/hospital-logo.png);
    //background-image: url(../img/wizard/clinic-logo.png);
    background-repeat: no-repeat;
    background-size: 100px;
    background-position: center;
    justify-content: center;
    align-items: center;
    margin: auto;
    > p.imp {
      position: absolute;
      top: 173px;
      text-align: center;
      width: 100%;
    }
    >p.err{
      line-height: 1;
      position: absolute;
      top: 194px;
    }
    &+ input {
      display: none;
    }
    .pined {
      background-repeat: no-repeat;
      background-position: center;
      background-size: 80%;
      width: 100%;
      height: 100%;
      border-radius: 100%;
      &[data-isset=true]{
        background-color: #ffffff;
      }
    }
    &:after {
      content: '';
      position: absolute;
      width: 46px;
      height: 46px;
      background-color: #0096FD;
      border-radius: 100%;
      right: 0;
      bottom: 0;
      //background-image: url(../img/pencil.svg);
      background-repeat: no-repeat;
      background-position: center;
      background-size: 27px;
    }
  }
  .subTitle{
    padding-bottom: 15px;
  }
  .timings{
    .error{
      font-size: 12px;
      color: darkred;
      text-align: left;
    }
    .cardSpec {
      width: 13%;
      flex-basis: 13%;
      text-align: center;
      height: 35px;
      @extend .flexBox;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      border: 1px solid #dadfe4;
      color: #4d4d4d;
      cursor: pointer;
      background-color: #f9f9f9;
      &:hover{
        border-color: #f49ac1;
      }
      &.selected{
        border-color: #f49ac1;
      }
      &.fixed{
        border-color: #50b5fb;
        background-color: #50b5fb;
        color: #ffffff;
      }
    }
    .typeListContainer{
      display: table;
    }
    .typeList{
      padding-top: 35px;
      width: fit-content;
      .times{
        width: 210px;
        flex-basis: 210px;
        div{
          cursor: pointer;
          width: 50%;
          flex-basis: 50%;
          text-transform: uppercase;
          @extend .flexBox;
          justify-content: center;
          align-items: center;
          height: 35px;
          border: 1px solid #dadfe4;
          color: #5e5e5e;
          background-color: #f9f9f9;
          &:first-child{
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
            border-right: 0;
          }
          &:last-child{
            border-bottom-right-radius: 4px;
            border-top-right-radius: 4px;
            border-left: 0;
          }
          &[data-active=true]{
            background-color: #50b5fb;
            border-color: #50b5fb;
            color: #ffffff;
          }
          &:not([data-active=true]):hover{
            background-color: rgba(80, 181, 251, 0.54);
            border-color: rgba(80, 181, 251, 0.54);
            color: #ffffff;
          }
        }
      }
      .customTimes{
        position: relative;
        width: auto;
        padding-left: 5px;
        .defaultTime, .splitTime{
          width: auto;
        }
        .splitTime {
          padding-left: 20px;
          position: relative;
          &:after {
            content: '&';
            position: absolute;
            left: 2px;
            top: 8px;
            color: #4d4d4d;
          }
        }
        .select{
          height: 35px;
          width: 114px;
          padding: 0 0 0 4px;
          border-radius: 4px;
          cursor: pointer;
          border: 1px solid #dadfe4;
          margin-right: 5px;
          background-color: #f9f9f9;
          align-items: center;
          justify-content: center;
          position: relative;
          .title{
            position: absolute;
            top: -18px;
            font-size: 12px;
            left: 0;
            color: #b5b5b5;
            span{
              color: darkred;
            }
          }
          &:hover{
            border-color: rgba(80, 181, 251, 0.54);
          }
          .selected{
            display: none;
          }
          &[data-selected=true]{
            .placeholder{
              display: none;
            }
            .selected{
              display: flex;
              width: 100%;
              height:100%;
              color: #5e5e5e;
              justify-content: space-between;
              align-items: center;
              .slTime{
                align-items: center;
                width: 44.4%;
                span{
                  &:first-child{
                    &:after{
                      content: ':';
                      padding: 0 1px;
                    }
                  }
                }
              }
            }
          }
          span.dt {
            height:100%;
            display: flex;
            align-items: center;
            width: auto;
            min-width:31.3px;
            padding: 0 4px;
            text-align: center;
            justify-content: center;
            background-color: #d9dee3;
            text-transform: uppercase;
            font-size: 14px;
          }
          .clock{
            width: 28.5px;
            border-radius: 4px;
            height:100%;
            background-repeat: no-repeat;
            background-position: center;
            background-size: 18px;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
          }
          .placeholder{
            color: #b5b5b5;
            align-items: center;
            .plcText {
              width: 100%;
              flex-basis: 100%;
              font-size: 14px;
            }
          }
          .selected{
            .clock{
              //background-image: url("../img/wizard/clock_white.png");
              background-color: #0096fd;
            }
          }
        }

        .popupCalendar{
          position: absolute;
          width: 260px;
          height: 170px;
          background-color: #ffffff;
          padding: 12px;
          border-radius: 4px;
          border: 1px solid #dadfe4;
          box-shadow: 0 1px 7px 0 rgba(0, 0, 0, 0.2);
          top: 36px;
          .close{
            width: 20px;
            position: absolute;
            right: 0px;
            top: 0px;
            border-radius: 50%;
            text-align: center;
            font-size: 18px;
            padding-top: 2px;
            cursor: pointer;
          }
          &.from{
            left: 13px;
          }
          &.to{
            left: 132px;
          }
          &.split_from{
            left: 281px;
          }
          &.split_to{
            left: auto;
            right: 0;
          }
          &[data-footer=true]{
            height: 220px;
            .footerBack {
              border-top: 1px solid #dadfe4;
              justify-content: center;
              align-items: center;
              height: 60px;
              >div{
                &:first-child{
                  margin-right: 5px;
                }
                @extend .flexBox;
                width: auto;
                flex-basis: 75px;
                height: 35px;
                background-color: #0096FD;
                color: #ffffff;
                text-transform: uppercase;
                border-radius: 4px;
                cursor: pointer;
                justify-content: center;
                align-items: center;
              }
            }
          }
          .fulK{
            //border: 1px solid #dadfe4;
            height: 144px;
            border-radius: 4px;
            .head {
              padding: 0 35px;
              height: 30px;
              background-color: #50b5fb;
              color: #ffffff;
              align-items: center;
              border-top-left-radius: 4px;
              border-top-right-radius: 4px;
            }
            .body{
              height: 114px;
              .left,
              .right{
                width: 90px;
              }
              .center{
                width: 50px;
                justify-content: space-between;
                flex-direction: column-reverse;
                align-items: center;
                padding: 4px 0;
                position: relative;
                &:after{
                  // content: ':';
                  position: absolute;
                  top: 0;
                  bottom: 0;
                  left: 0;
                  right: 0;
                  margin: auto;
                  display: table;
                  width: 10px;
                  font-size: 37px;
                  font-weight: bold;
                  line-height: 0.4;
                  color: #4d4d4d;
                }
                .day{
                  cursor: pointer;
                  width: 32px;
                  height: 29px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  border-radius: 4px;
                  background-color: #d9dee3;
                  color: #5a5a5a;
                  font-size: 13px;
                  &[data-active=true]{
                    background-color: #0096fd;
                    color: #fff;
                  }
                }
              }
              .items{
                li{
                  width: 33%;
                  height: 27px;
                  color: #4d4d4d;
                  border-radius: 4px;
                  border: 1px solid #dadfe4;
                  cursor: pointer;
                  margin-top: 1px;
                  @extend .flexBox;
                  justify-content: center;
                  align-items: center;
                  &[data-active=true],
                  &:hover{
                    color: #ffffff;
                    background-color: #50b5fb;
                    border-color: #50b5fb;
                  }
                }
              }
              .choosed{
                @extend .flexBox;
                justify-content: center;
                align-items: center;
                height: 100%;
                span{
                  font-size: 70px;
                  color: #4d4d4d
                }
              }
            }
          }
        }
        .addSplit {
          border: 1px solid #0096fd;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 4px;
          padding: 0 15px;
          cursor: pointer;
          color: #0096fd;
          //margin-left: 10px;
          margin-left: 0;
          min-width: 102px;
          &:hover{
            background-color:  #0096fd;
            color: #ffffff;
          }
        }
      }
    }
    .setRow{
      padding-top: 15px;
      width: 100%;
      @extend .flexBox;
      // justify-content: flex-end;
      .set{
        width: 75px;
        flex-basis: 75px;
        height: 35px;
        background-color: #0096FD;
        color: #ffffff;
        text-transform: uppercase;
        border-radius: 4px;
        cursor: pointer;
        @extend .flexBox;
        justify-content: center;
        align-items: center;
        box-shadow: 0 1px 7px 0 rgba(0, 0, 0, 0.34);
        &:hover{

          box-shadow: 0 1px 7px 0 rgba(0, 0, 0, 0.51);
        }
      }
    }
  }
  .openingDaysList{
    padding-top: 80px;
    .item{
      padding-bottom: 8px;
      padding-top: 8px;
      border-bottom: 1px solid #dadfe4;
    
      font-size: 17px;
      width: 70%;
      .name{
        flex-basis: 14%;
        width: 14%;
      }
      .time{
        flex-basis: 33%;
        width: 33%;
      }
      .remove{
        flex-basis: 23px;
        width: 23px;
        .delButt{
          span{
            color: #000000;
            cursor: pointer;
          
            padding: 0 15px;
            display: table;
          }
        }
      }
    }
  }
  .topSpecialties{
    // padding-bottom: 10px;
    .items{
      height: 300px;
      .item{
        width: 16%;
        flex-basis: 16%;
        margin-bottom: 5px;
        height: 140px;
        border-radius: 4px;
        color: #a6b4bc;
      
        padding: 0 5px 15px 5px;
        cursor: pointer;
        flex-direction: column-reverse;
        align-items: center;
        border: 1px solid #dadfe4;
        font-size: 14px;
        position: relative;
        text-align: center;
        > div {
          position: absolute;
          width: 100%;
          height: 100%;
          background-position: center;
          background-repeat: no-repeat;
          background-size: 46%;
        }
        .enter{
          display: none;
        }
        .leave{
          display: block;
        }
        &:hover,
        &[data-active=true]{
          background-color: #0096fd;
          color: #ffffff;
          border-color: #0096fd;
          .enter{
            display: block;
          }
          .leave{
            display: none;
          }
        }
      }
    }
  }
  .SuggestedSpecialties{
    .items {
      height: 139px;
      //padding-bottom: 20px;
      padding-top: 10px;
      overflow: hidden;
      .item {
        width: 32.5%;
        flex-basis: 32.5%;
        margin-bottom: 8px;
        height: 35px;
        border-radius: 4px;
        color: #616161;
      
        padding: 0 0 0 15px;
        cursor: pointer;
        align-items: center;
        border: 1px solid #dadfe4;
        span{
          width: 90%;
          display: block;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          line-height: 3;
        }
        &:hover{
          background-color: #0096fd;
          color: #ffffff;
          border-color: #0096fd;
        }
      }
    }
  }
  .selectedSpecialties{
    background-color: #f9f9f9;
    padding: 5px;
    border: 1px solid #dadfe4;
    border-radius: 4px;
    margin-bottom: 20px;
    min-height: 300px;
    .items {
      padding-bottom: 20px;
      padding-top: 10px;
      .item {
        width: 33%;
        flex-basis: 33%;
        margin-bottom: 8px;
        height: 35px;
        border-radius: 4px;
        color: #616161;
      
        padding: 0 0 0 15px;
        align-items: center;
        border: 1px solid #dadfe4;
        background-color: #ffffff;
        position: relative;
        cursor: pointer;
        &:not(:nth-child(3n)){
          margin-right: 0.5%;
        }
        span{
          &:first-child {
            width: 90%;
            display: block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            line-height: 3;
          }
          &:last-child{
            color: #d86969;
            position: absolute;
            right: 10px;

          }
        }
        &:hover{
          background-color: #008ced;
          color: #ffffff;
          border-color: #008ced;
          span{
            color: #ffffff;
            &:last-child{
              color: #d86969;
            }
          }
        }
      }
    }
    &.custom{
      .items{
        .item{
          background-color: #d9d9d9;
          color: #d9d9d9;
          border-color: #d9d9d9;
          span{
            color: #999797;
            &:last-child{
              color: #d86969;
              position: absolute;
              right: 10px;

            }
          }
          &:hover{
            background-color: #c0c0c0;
            border-color: #c0c0c0;
          }
        }
      }
    }
    &.ins{
      .items{
        >div{
          width: 100%;
        }
        .itemParent{
          width: 32%;
          flex-basis: 32%;
          margin-bottom: 8px;
          &:not(:nth-child(3n)){
            margin-right: 0.5%;
          }
          .item{
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            width: 100%;
            flex-basis: 100%;
            margin-bottom: 0;
            margin-right: 0;
          }
          .networks{
            width: 100%;
            padding: 10px 0;
            border: 1px solid #dadfe4;
            background-color: #ffffff;
            border-radius: 4px;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            border-top: 0;
            &.custom{
              background-color: #d9d9d9;
            }
            p{
              color: #707070;
              padding: 5px 20px;
              margin: 2px 0;
              cursor: pointer;
              position: relative;
              span{
                &.check{
                  display: block;
                  width: 17px;
                  height: 17px;
                  position: relative;
                  top: 1px;
                  margin-right: 8px;
                  border: 1px solid #b5b5b5;
                }
              }
              &[data-checked=true]:after {
                content: '';
                background-image: url(../img/warn_check.png);
                background-size: 25px;
                position: absolute;
                left: 20px;
                bottom: 6px;
                width: 25px;
                height: 25px;
                pointer-events: none;
              }
              &.waiting{
                cursor: default;
                &:after{
                  display: none;
                }
                span{
                  &.check{
                    background-color: #b5b5b5;
                  }
                  &.remove {
                    position: absolute;
                    right: 23px;
                    text-transform: uppercase;
                    font-size: 12px;
                    padding: 3px 5px;
                    color: darkred;
                    cursor: pointer;
                  }
                }
              }
              &.itemIns{
                span{
                  &:last-child {
                    display: block;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    width: 85%;
                  }
                }
              }
            }
            .addNewNetwork{
              background-color: #f4f5f9;
              border: 1px solid #dadfe4;
              border-radius: 4px;
              padding: 5px 7px;
              width: 90%;
              margin: auto !important;
              .action{
                position: absolute;
                right: -10px;
                top: 0;
                left:unset;
                bottom: 0;
                margin: auto;
                font-size: 16px;
                font-weight: bold;
              }
              &:hover{
                span{
                  background-color: #2e97ff;
                  color: #ffffff;
                }
              }
              input{
                height: 22px;
                margin: 0;
                padding: 0;
                border: 0;
                background-color: #f4f5f9;
              }
            }
          }
        }
      }
    }
  }
  .findSpecialties{
    .datalist{
      height: 35px;
      width: 260px;
    }
  }
  .Select-placeholder {
    font-size: 14px;
  
    color: #bfbfbf;
    padding-left:8px;
  }
  .Select-control {
    height: 35px;
    border: 1px solid #dadfe4;
    position: static;
    overflow: visible;
    cursor: pointer;
    background-color: #f9f9f9;
  }
  .Select{
    width: 260px;
  }
  .findInsurances{
    .Select {
      width: 404px;
    }
  }
  .search{
    height: 100%;
    margin: 0;
    padding: 0
  }
  .Select-noresults{
    padding: 20px 10px;
    font-size: 14px;
    color: #616161;
  }
  #insurance,
  #speciality{
    border: 0;
    height: 35px;
    padding: 0;
    margin: 0;
    background-color: transparent;
  }
  .spSelect{
    position: relative;
    display: table;
    .addNewSpec {
      width: 24px;
      height: 24px;
      position: absolute;
      border-radius: 20px;
      background-color: #2e97ff;
      border: 1px solid #2e97ff;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #ffffff;
      font-size: 23px;
      line-height: 0;
      right: 3px;
      top: 4px;
      cursor: pointer;
      //-webkit-animation: pulsate .6s ease-out;
      //-webkit-animation-iteration-count: 3;
      &:hover{
        background-color: #ffffff;
        color: #2e97ff;
        animation: none;
        -webkit-animation: none;
      }
    }
  }
  .verification{
    .wrapper{
      .adInfo{
        width: 45%;
        .inputWrap{
          padding: 0;
          &.number{
            .title{
              padding-bottom: 0;
            }
            .inputParent > div.fRt{
              width: 252px;
              padding-top: 1px;
            }
          }
        }
        input{
          height: 35px;
        }
        .subMini{
          color: #b5b5b5;
          width: 100%;
          font-size: 14px;
          padding-top: 10px;
        }
        h3{
          font-size: 16px;
          color: #4d4d4d;
          padding: 10px 0;
        }
      }
      .adVerify{
        width: 45%;
        .inputWrap {
          padding: 0;
        }
        #attachVerify{
          display: none;
        }
        .added{
          width: 45%;
          @extend .flexBox;
          align-items: center;
          p{
            //cursor: pointer;
            span{
              &:first-child{
                display: block;
                max-width: 139px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
              &:last-child{
                font-weight: bold;
                font-size: 23px;
              }
            }
          }
        }
      }
    }
    .acceptSection{
      padding-top: 30px;
      margin-top: 60px;
      border-top: 1px solid #b5b5b5;
      .terms{
        display: table;
        p{
          color: #4d4d4d;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          margin-bottom: 15px;
          cursor: pointer;
          position: relative;
          span{
            &:first-child {
              display: block;
              width: 22px;
              height: 22px;
              margin-right: 15px;
              position: relative;
              border: 1px solid #b5b5b5;
            }
            &:nth-child(2){
            
              font-size: 14px;
              text-decoration: underline;
            }
            &:last-child{
              padding-left: 3px;
            }
          }
          &[data-checked=true]{
            &:after{
              content: '';
              background-image: url(../img/warn_check.png);
              pointer-events: none;
              background-size: 25px;
              position: absolute;
              left: 1px;
              bottom: 1px;
              width: 25px;
              height: 25px;
            }
          }
        }
      }
    }
  }
  .chooseFileButton{
    width: 180px;
    height: 39px;
    border-radius: 4px;
    border: 2px solid #0096fd;
    padding: 7px;
    font-size: 14px;
  
    letter-spacing: 0;
    cursor: pointer;
    //background-image: url(../img/file.svg);
    background-repeat: no-repeat;
    background-size: 17px;
    background-position: right 10px center;
    background-color: transparent;
    color: #4d4d4d;
    text-align: left;
    display: flex;
    align-items: center;
    @extend .tr3;
    &:hover{
      background-color: #0096fd;
      color: #ffffff;
      background-position: right 10px center;
    }
  }
  .hospitalFields{
    padding: 25px 0;
    .apply{
      margin: 0 0 0 10px;
    }
    .inputWrap{
      width: 42%;
      flex-basis: 42%;
      &:last-child{
        flex-basis: 57%;
        width: 57%;
      }
      &:first-child{
        margin-right: 1%;
      }
      .inputParent{
        &.numberOfBeds{
          width: 140px;
          span{
            width: 35px;
            height: 35px;
            background-color: #0096fd;
            border-radius: 4px;
            text-align: center;
            color: #ffffff;
            font-size: 36px;
            cursor: pointer;
            border: 1px solid #0096fd;
            &:first-child{
              border-top-right-radius: 0;
              border-bottom-right-radius: 0;
              line-height: 0.7;
            }
            &:last-child{
              border-top-left-radius: 0;
              border-bottom-left-radius: 0;
              line-height: 0.9;
            }
          }
          input{
            width: 70px;
            height: 35px;
            margin: 0;
            border-radius: 0;
            border-left: 0;
            border-right: 0;
            text-align: center;
            font-size: 27px;
            color: #4d4d4d;
            padding: 0;
            //pointer-events: none;
            &{
              -moz-appearance:textfield;
            }
            /* Webkit browsers like Safari and Chrome */
            &::-webkit-inner-spin-button,
            &::-webkit-outer-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }
          }
        }
        &.emergencyService{
          p{
            @extend .flexBox;
            justify-content: flex-start;
            align-items: center;
            span{
              color: #4d4d4d;
              cursor: pointer;
              &:nth-child(2){
                &:after{
                  content: '';
                  width: 28px;
                  height: 28px;
                  top: 1px;
                  left: 1px;
                  position: absolute;
                  display: block;
                  background-color: #0096fd;
                  border-radius: 100%;
                  @extend .tr3;
                }
                position: relative;
                width: 90px;
                margin: 0 5px;
                border: 1px solid #dadfe4;
                border-radius: 19px;
                height: 32px;
                background-color: #ffffff;
              }
            }
            &[data-checked=true]{
              span{
                &:nth-child(2){
                  &:after{
                    left: 59px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  &[data-branches-length="1"]{
    .inputWrap .title .apply{
      display: none;
    }
    .branchNav{
      display: none;
    }
  }
  .add-docs{
    .addNewDoc {
      width: 24px;
      height: 24px;
      position: absolute;
      border-radius: 20px;
      background-color: #2e97ff;
      border: 1px solid #2e97ff;
      display: none;
      align-items: center;
      justify-content: center;
      color: #ffffff;
      font-size: 23px;
      line-height: 0;
      right: 7px;
      top: 11px;
      cursor: pointer;
      z-index: 10;
      &:hover{
        background-color: #ffffff;
        color: #2e97ff;
        animation: none;
        -webkit-animation: none;
      }
    }
    .existDocs{
      &:last-child{
        margin-top: 10px;
      }
    }
    .docFull {
        padding: 5px 15px;
        background-color: #f9f9f9;
        border: 1px solid #dadfe4;
        border-radius: 8px;
      }
      .inputParent{
        &.docFull{

        .inputWrap{
          input{
            background-color: #fff;
          }
        }
        .typeahead .typeahead-selector {
          top: 42px;
        }
        [data-found=false]{
          .addNewDoc{
            display: flex;
      }
    }
  }
      h3{
        font-size: 15px;
        color: #4d4d4d;
      
      }
      .inputWrap {
        input {
          height: 40px;
        }
      }
    }
    .existDocs{
      .dList{
        padding-top: 20px;
        .doc{
          padding: 5px;
          width: 100%;
          background-color: #fff;
          border: 1px solid #dadfe4;
          border-radius: 4px;
          margin-bottom: 10px;
          .headR{
            & *{
              @extend .tr3;
            }
            width: 100%;
            margin-right: 10px;
            padding: 5px;
            cursor: pointer;
            position: relative;
            .close{
              position: absolute;
              right: 3px;
              font-size: 30px;
              color: darkred;
              padding: 0 10px;
              cursor: pointer;
              top: 5px;
              @extend .tr3;
              &:hover {
                transform: rotate(180deg);
              }
            }
            .edit{
              position: absolute;
              right: 42px;
              top: 4px;
              padding: 0 10px;
              display: flex;
              align-items: center;
              height: 42px;
              font-size: 18px;
              color: #0096fd;
            }
            .glob, .info, .lng{
              @extend .flexBox;
            }
            .info, .lng{
              width: 33%;
              flex-basis: 33%;
            }
            .photo {
              width: 40px;
              height: 40px;
              background-size: cover;
              background-position: center;
              border-radius: 100%;
              padding: 2px;
              border: 1px solid #dadfe4;
            }
            .docName{
              padding-left: 10px;
              font-size: 14px;
              line-height: 14pt;
              max-width: 80%;
              p{
                &:first-of-type{
                
                  max-width: 120px;
                  white-space:nowrap
                }
              }
            }
            .lng{
              padding-left: 30px;
              display: flex;
              align-items: center;
              //background-image: url(../img/glob.png);
              background-repeat: no-repeat;
              background-position: left center;
              .ln{
                &:after{
                  content: ',';
                  padding-right: 5px;
                }
                &:last-child:after{
                  content: '';
                }
              }
            }
          }
          &[data-opened=true]{
            padding: 10px 5px 10px 20px;
            .headR{
              .lng{
                display: none;
              }
              .docName{
                padding-left: 20px;
                font-size: 16px;
                p:last-child{
                  font-style: italic;
                  padding-top: 5px;
                }
              }
              .info{
                align-items: center;
              }
              .photo {
                width: 85px;
                height: 85px;
              }
            }
          }
          .body{
            margin-top: 10px;
            .info{
              margin-bottom: 10px;
              .all {
                padding-top: 12px;
                font-size: 12px;
                opacity: 0.7;
                span,
                p{
                  font-size: 14px;
                
                }

                p.spec, .t p, .lng{
                  background-size: 18px;
                  background-repeat: no-repeat;
                  background-position: left center;
                  padding: 5px 0 5px 27px;
                }
                .lng {
                  //background-image: url(../img/glob.png);
                  .ln:not(:last-child):after {
                    content: ', ';
                  }
                }
                .t{
                  p{
                    white-space: nowrap;
                    width: 100%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    padding-top: 4px;
                    padding-bottom: 5px;
                  }
                }
              }
              .left{
                width: 25%;
                flex-basis: 25%;
              }
              .right{
                width: 40%;
                flex-basis: 40%;
                .head{
                  padding-top: 15px;
                }
                .twoFa{
                  .facility{
                    padding-top: 10px;
                    align-items: center;
                    .logo{
                      width: 30px;
                      height: 30px;
                      background-size: cover;
                      background-position: center;
                      border-radius: 100%;
                      border: 1px solid #0096fd;
                    }
                    .title{
                      padding-left: 10px;
                      h3{
                        text-align: left;
                      }
                      .address{
                        padding-left: 20px;
                        //background-image: url(../img/location.png);
                        background-repeat: no-repeat;
                        background-size: 15px;
                        background-position: left center;
                        color: #999999;
                      }
                    }
                  }
                }
              }
              .right2{
                padding-top: 15px;
                width: 25%;
                flex-basis: 35%;
              
                font-size: 13px;
                line-height: 1.6;
                .cons{

                  padding-bottom: 20px;
                }
              }
            }
          }
        }
      }
    }
    .new-wizard{
      margin-bottom: 20px;
      margin-top: 10px;
      h3{
        text-transform: uppercase;
      }
      input {
        height: 40px;
        background-color: #fff;
        &[type="number"] {
          width: 100px !important;
        }
      }
      .years {
        select{
          width: 144px;
        }
      }
      .picWrap {
        align-items: center;
        justify-content: space-between;
        .profilePicture{
          width: 80px;
          height: 80px;
          display: block;
          border-radius: 100%;
          border: 2px solid #dadfe4;
          //background-image: url(../img/no_photo.jpg);
          background-size: 100%;
          background-position: center;
          background-repeat: no-repeat;
          .pined{
            background-repeat: no-repeat;
            background-position: center;
            background-size: 80%;
            width: 100%;
            height: 100%;
            border-radius: 100%;
            background-color: #fff;
          }
        }
        .chooseFileButton{
          & + input{
            display: none;
          }
        }
      }
      .gender{
        .item {
          border: 1px solid #dadfe4;
          background-color: #fff;
          padding: 25px 13px 6px 30px;
          width: 105px;
          border-radius: 18px;
          cursor: pointer;
          font-weight: bold;
          color: #cdd4db;
          font-size: 16px;
          margin-right: 15px;
          background-size: 14px;
          background-position: left 7px center;
          background-repeat: no-repeat;
          display: flex;
          align-items: flex-end;
          &:hover,
          &[data-active=true]{
            background-color: #0096fd;;
            border-color: #0096fd;
            color: #fff;
          }
          &.Female{
            background-size: 16px;
            //background-image: url(../img/female-blue.png);
          }
        }
      }
      select{
        width: 100%;
        height: 40px;
        border-radius: 4px;
        border: 1px solid #dadfe4;
        outline: 0;
        padding: 0 8px;
      }
      .selected {
        padding: 12px;
        border-radius: 8px;
        border: 1px solid #dadfe4;
        margin-top: 10px;
        background-color: #fff;
        &:empty {
          display: none;
        }
        .item{
          padding: 10px 20px 10px 10px;
          border-radius: 4px;
          background-color: #f9f9f9;
          border: 1px solid #dadfe4;
          position: relative;
          width: 31.4%;
          flex-basis: 31.4%;
          margin-bottom: 5px;
          &:nth-child(3n-1) {
            margin: 0 10px 5px 10px;
          }
          .removeSelected{
            color: darkred;
            position: absolute;
            padding: 11px 5px;
            right: 0;
            top: 0;
            cursor: pointer;
          }
        }
      }
      .doc-titles{
        > div{
          background-color: #f2f3f7;
          border: 1px solid #dadfe4;
          position: relative;
          width: 31.4%;
          flex-basis: 31.4%;
          cursor: pointer;
          text-align: center;
          padding: 10px;
          border-radius: 4px;
          &:nth-child(3n-1){
            margin: 0 10px;
          }
          &[data-active=true],
          &:hover{
            background-color: #0096fd;
            border-color: #0096fd;
            color: #fff;
          }
          span{
            pointer-events: none;
          }
        }
      }
      h3,.h3{
        text-transform: uppercase;
      }
      .consultFee{
        position: relative;
        &:before{
          content: 'OMR';
          padding: 9px 9px 10px 9px;
          border: 1px solid #dadfe4;
          border-radius: 4px;
          border-right: 0;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
          background-color: #fff;
        }
        input{
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
      }
      .save{
        display: flex;
        justify-content: flex-end;
        position: relative;
        span{
          display: block;
          padding: 10px 20px;
          color: #fff;
          background-color: #2e97ff;
          border-radius: 4px;
          cursor: pointer;
          border: 1px solid #2e97ff;
          @extend .tr3;
          &:hover{
            background-color: #fff;
            color: #2e97ff;
          }
        }
        &[data-has-erroe="true"]:before {
          content: 'Please fill all required fields';
          color: darkred;
          left: 0;
          position: absolute;
          top: 11px;
        }
      }
    }
  }
}
.topSpecialties{
  // padding-bottom: 10px;
  .items{
    height: 300px;
    .item{
      width: 16%;
      flex-basis: 16%;
      margin-bottom: 5px;
      height: 140px;
      border-radius: 4px;
      color: #a6b4bc;
    
      padding: 0 5px 15px 5px;
      cursor: pointer;
      flex-direction: column-reverse;
      align-items: center;
      border: 1px solid #dadfe4;
      font-size: 14px;
      position: relative;
      text-align: center;
      > div {
        position: absolute;
        width: 100%;
        height: 100%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: 46%;
      }
      .enter{
        display: none;
      }
      .leave{
        display: block;
      }
      &:hover,
      &[data-active=true]{
        background-color: #0096fd;
        color: #ffffff;
        border-color: #0096fd;
        .enter{
          display: block;
        }
        .leave{
          display: none;
        }
      }
    }
  }
  &.filter{
    position: absolute !important;
    bottom: 126px;
    background-color: white;
    padding: 15px;
    border-radius: 12px;
    box-shadow: 1px 1px 8px 0 rgba(169, 172, 189, 0.32);
    width: 102%;
    left: -2%;
    .items{
      height: 320px;
      .item{
        font-size: 12px;
        width: 14%;
        flex-basis: 14%;
        margin-right: 3px;
        margin-bottom: 25px;
        & > span,
        & > div{
          pointer-events: none;
        }
      }
    }
  }
}
    .docs.M{
      .listR {
        padding-top: 20px;
        &:empty{
          display: none;
        }
        .my_doc {
          width: 14%;
          flex-basis: 14%;
          margin-right: 29px;
          height: 150px;
          border-radius: 8px;
          box-shadow: 1px 1px 8px 0 rgba(169, 172, 189, 0.32);
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          position: relative;
          .exp {
            height: 54px;
            position: absolute;
            width: 100%;
            bottom: 0;
            background-color: rgba(0, 150, 253, 0.76);
            border-bottom-right-radius: 8px;
            border-bottom-left-radius: 8px;
            overflow: hidden;
            padding: 3px 10px 5px;
            color: #ffffff;
            @extend .tr3;
            .top {
              p{
                &:first-child {
                  position: relative;
                  font-size: 15px;
                
                  padding-bottom: 4px;
                  margin-bottom: 3px;
                  &:after{
                    content: '';
                    height: 1px;
                    width: 32px;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    background-color: #fff;
                  }
                }
              }
            }
            .all {
              padding-top: 12px;
              font-size: 12px;
              opacity: 0.7;
              .lng {
                padding-left: 20px;
                //background-image: url(../img/glob.svg);
                background-size: 12px;
                background-repeat: no-repeat;
                background-position: left center;
                .ln:not(:last-child):after {
                  content: ', ';
                }
              }
              .t{
                p{
                  background-size: 12px;
                  background-repeat: no-repeat;
                  padding-left: 20px;
                  background-position: left center;
                }
                p:first-child{
                  white-space: nowrap;
                  width: 100%;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  padding-top: 4px;
                  padding-bottom: 5px;
                }
                p:last-child{
                  //background-image: url(../img/ic3.svg);
                  line-height: 10pt;
                }
              }
            }
          }
          &:hover{
            .exp{
              height: 100%;
            }
          }
        }
        &.inner{
          padding-bottom: 45px;
          .my_doc{
            width: 29%;
            flex-basis: 29%;
            margin-right: 0;
            height: 180px;
            margin-bottom: 25px;
            &:nth-child(3n-1){
              margin: 0 29px 10px 29px;
            }
            &:hover{
              .exp{
                padding-top: 22px;
              }
            }
          }
        }
      }
    }
.fully .inputs._de{
  position: relative;
  .areS{
    width: 290px;
  }
}
#clinicProf{
  .mobOpen{
    display: none;
    width: 30px;
    cursor: pointer;
    padding-top: 7px;
    position: absolute;
    right: 0;
    top: 23px;
    z-index: 110;
    span{
      display: block;
      height: 4px;
      margin-bottom: 4px;
      background-color: #0096FD;
    }
  }
  .pic {
    display: flex;
    width: 174px;
    height: 174px;
    border: 7px solid #DADFE4;
    border-radius: 100%;
    cursor: pointer;
    position: relative;
    //background-image: url(../img/wizard/hospital-logo.png);
    //background-image: url(../img/wizard/clinic-logo.png);
    background-repeat: no-repeat;
    background-size: 100px;
    background-position: center;
    justify-content: center;
    align-items: center;
    margin: auto;
    &.color{
      border-color: #0096FD;
      &[data-featured=false]{
        border-color: #DADFE4;
      }
    }
    > p.imp {
      position: absolute;
      top: 173px;
      text-align: center;
      width: 100%;
    }
    >p.err{
      line-height: 1;
      position: absolute;
      top: 194px;
    }
    &+ input {
      display: none;
    }
    .pined {
      background-repeat: no-repeat;
      background-position: center;
      background-size: 80%;
      width: 100%;
      height: 100%;
      border-radius: 100%;
      &[data-isset=true]{
        background-color: #ffffff;
      }
    }
    &:after {
      content: '';
      position: absolute;
      width: 46px;
      height: 46px;
      background-color: #0096FD;
      border-radius: 100%;
      right: 0;
      bottom: 0;
      //background-image: url(../img/pencil.svg);
      background-repeat: no-repeat;
      background-position: center;
      background-size: 27px;
    }
  }
  h1{
    font-size: 25px;
    letter-spacing: 0.4pt;
    max-width: 100%;
    display: table;
    padding-right: 50px;
    position: relative;
    min-height: 28px;
    //padding-bottom: 20px;
    .ok{
      //background-image: url(../img/green_check.png);
      background-repeat: no-repeat;
      width: 35px;
      background-size: contain;
      background-position: center;
      height: 40px;
      position: absolute;
      right: -30px;
      top: 0;
    }
  }
  >div{
    &.left{
      width: 68%;
      .header{
        padding-bottom: 30px;
        padding-top: 20px;
        >div{
          position: relative;
        }
        .claim{
          position: static;
        }
        h1{

          margin-bottom: 9px;
        }
      }
      .desc{
        padding-bottom:35px;
        margin-bottom: 40px;
      }
      .gallery{
        border-radius: 4px;
        border: 1px solid #dadfe4;
        padding: 20px;
        background-color: #f4f5f9;
        img{
          max-width: 100%;
        }
        .lG{
          width: 27%;
          flex-basis: 27%;
          .slick-list{
            height: 378px;
            overflow: hidden;

            .slick-slide{
              height: 116px;
              margin: 5px 0;
              background-size: cover;
              background-repeat: no-repeat;
              background-position: center;
              cursor: pointer;
            }
          }
        }
        .rG{
          width: 73%;
          padding-left: 25px;
          &:first-child{
            width: 100%;
            height: 450px;
            padding-left: 0;
          }
          .one{
            width: 100%;
            height: 98.5%;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
          }
        }
      }
      .SpecInsTab{
        margin: 25px 0;
        .list{
          border-radius: 4px;
          border: 1px solid #dadfe4;
          background-color: #e9ebf3;
          width: 28%;
          flex-basis: 28%;
          padding: 25px 0;
          position: relative;
          li{
            padding: 15px 10px 15px 55px;
            border-left: 4px solid transparent;
            cursor: pointer;
            margin-left: -3px;
            background-repeat: no-repeat;
            background-size: 33px;
            background-position: 10px center;
            &[data-active="true"] {
              background-color: #ffffff;
              width: 113%;
              border: 1px solid #dadfe4;
              border-left: 4px solid #0095fc;
              color: #0095fc;
              border-right: 0;
              z-index: 13;
              position: relative;
            }
          }
        }
        .tabs{
          border-radius: 4px;
          border: 1px solid #dadfe4;
          width: 69%;
          flex-basis: 69%;
          position: relative;
          .tab{
            padding: 25px 25px 30px 25px;
            &.specialties{
              .item{
                position: relative;
                .span{
                  display: none;
                }
                &[data-has-doctors=true]{
                  .name{
                    cursor: pointer;
                  }
                  .span{
                    width: 23px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    height: 40px;
                    position: absolute;
                    right: 11px;
                    span{
                      width: 4px;
                      height: 4px;
                      background-color: #0096fd;
                    }
                  }
                }
                .name{
                  //background-image: url(../img/speciality_icon.svg);
                  &[data-opened="true"]{
                    .span{
                      right: 2px;
                      height: 20px;
                      flex-direction: column;
                    }
                  }
                  &[data-opened="false"] + div{
                    display: none;
                  }
                }
                .docs{
                  padding: 0 10px;
                  .more{
                    background-color: transparent;
                  }
                 
                    .hearth{
                      color: #cacaca;
                      position: absolute;
                      left: 7px;
                      top: 5px;
                      z-index: 100;
                      cursor: pointer;
                      &[data-loved=true]{
                        color: #f76155;
                      }

                      &[data-anim=true]{
                        .fa{
                          animation: pulsate2 .3s ease-out;
                          -webkit-animation: pulsate2 .3s ease-out;
                          animation-iteration-count: infinite;
                          -webkit-animation-iteration-count: infinite;
                        }
                      }
                      span.fa{
                        cursor: pointer;
                      }
                    }
                  }
                  .doctors{
                    padding: 20px 10px;
                    justify-content: flex-start;
                    .more{
                      background-color: transparent;
                    }
                    &:empty {
                      display: none;
                    }
                    .doctor{
                      //height: 220px;
                      height: 180px;
                      width: 30%;
                      flex-basis: 30%;
                      border: 1px solid #dadfe4;
                      border-radius: 4px;
                      background-color: #ffffff;
                      position: relative;
                      margin-bottom: 20px;
                      &:nth-child(3n-1) {
                        margin: 0 11px;
                      }
                    .glob{
                      width: 100%;
                      height: 100%;
                      top: 0;
                      position: absolute;
                      -webkit-transition: -webkit-transform 0.5s;
                      -moz-transition: -moz-transform 0.5s;
                      -o-transition: -o-transform 0.5s;
                      transition: transform 0.5s;
                      -webkit-transform-style: preserve-3d;
                      -moz-transform-style: preserve-3d;
                      -o-transform-style: preserve-3d;
                      transform-style: preserve-3d;
                    }
                    .box1{
                      .photo{
                        width: 85px;
                        height: 85px;
                        border: 4px solid #dadfe4;
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: contain;
                        border-radius: 100%;
                        overflow: hidden;
                        margin: 0 auto 20px auto;
                      }
                      .docName {
                        font-size: 13px;
                      
                        text-align: center;
                      }
                    }
                    .box1, .box2{
                      padding: 7px 20px;
                      position: absolute;
                      width: 100%;
                      height: 100%;
                      -webkit-backface-visibility: hidden;
                      -moz-backface-visibility: hidden;
                      -o-backface-visibility: hidden;
                      backface-visibility: hidden;
                      left: 0;
                    }
                    .box2{
                      -webkit-transform: rotateY(180deg);
                      -moz-transform: rotateY(180deg);
                      -o-transform: rotateY(180deg);
                      transform: rotateY(180deg);
                      padding: 15px 20px;
                      .exp{
                        padding-bottom: 20px;
                        border-bottom: 1px solid #dadfe4;
                        text-align: center;
                        margin-bottom: 20px;
                        &:last-child:not(:first-child){
                          border: 0;
                        }
                        h4{
                          padding-bottom: 4px;
                        
                        }
  
                        span.ln {
                        
                          text-transform: uppercase;
                          padding: 5px 3px 0;
                          display: inline-block;
                        }
                      }
                    }
                    &:hover{
                      .glob{
                        transform: rotateY(180deg);
                      }
                      .box1{
                        pointer-events: none;
                        z-index: -2;
                      }
                    }
                  }
                }
                .miniDoctors{
                  width: 147px;
                  padding-right: 35px;
                  justify-content: flex-end;
                  .doctor{
                    width: 25%;
                    flex-basis: 25%;
                    height: 28px;
                    border: 2px solid #dadfe4;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: contain;
                    border-radius: 100%;
                    overflow: hidden;
                    &:nth-child(2){
                      margin: 0 8px;
                    }
                  }
                }
              }
            }
            &.amenities{
              .hosp{
                display: flex;
                padding-bottom: 50px;
                padding-top: 15px;
                color: #616161;
                >div{
                  width: 50%;
                  flex-basis: 50%;
                  background-repeat: no-repeat;
                  background-position: left;
                  font-size: 15px;
                  padding: 5px 0 5px 45px;
                }
              }
            }
            &.insurances{
              .item{
                .name{
                  //background-image: none;
                  cursor: pointer;
                  position: relative;
                  span{
                    &:first-child{
                      position: absolute;
                      right: 0;
                      color: #2e97ff;
                    }
                    &:last-child{
                      position: absolute;
                      width: 30px;
                      height: 30px;
                      left: 5px;
                      top: 6px;
                      border-radius: 100%;
                      overflow: hidden;
                      background-repeat: no-repeat;
                      background-position: center;
                      background-size: contain;
                    }
                  }
                  &:after{
                    content: '';
                    position: absolute;
                    right: 0;
                    color: #0095fc;
                    font-size: 13px;
                    padding-bottom: 1px;
                    border-bottom: 1px solid;
                  
                  }
                }
              }
            }
            &.custom{
              background-color: #eff1f9;
              .item{
                .name{
                  cursor: default;
                  &:after{
                    display: none;
                  }
                }
              }
            }

          }
          .more{
            color: #0096FD;
            text-transform: uppercase;
            position: absolute;
            right: 0;
            font-weight: bold;
            bottom: 1px;
            font-size: 12px;
            padding: 10px;
            cursor: pointer;
            background-color: white;
            width: 100%;
            >span{
              pointer-events: none;
              display: flex;
              align-items: center;
              justify-content: flex-end;
              .action{
                width: 17px;
                height: 17px;
                background-color: #f3f5f8;
                border-radius: 100%;
                align-items: center;
                justify-content: center;
                border: 1px solid #2e97ff;
                color: #2e97ff;
                cursor: pointer;
                margin-left: 5px;
                font-size: 13px;
                text-align: center;
                line-height: 15px;
              }
            }
          }
          .item {
            margin-bottom: 10px;
            border-radius: 4px;
            border: 1px solid #dadfe4;
            //display: flex;
            //align-items: center;
            padding: 0 10px 0;
            background-color: #f4f5f9;
            color: #616161;
            .name{
              background-repeat: no-repeat;
              background-size: 23px;
              background-position: 8px center;
              height: 40px;
              padding-left: 40px;
              display: flex;
              align-items: center;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            .networks{
              width: 100%;
              padding: 18px 30px;
              ul{
                li{
                  width: 48%;
                  padding: 5px 5px 5px 16px;
                  margin-bottom: 10px;
                  //background-image: url(../img/wizard/warn_check.png);
                  background-repeat: no-repeat;
                  background-size: 14px;
                  background-position: 1px center;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
                &.custom{
                  li{
                    //background-image:none;
                    background-color: #e9ebf3;
                    position: relative;
                    span{
                      right: 4px;
                      position: absolute;
                      top: 8px;
                    }
                  }
                }
              }
            }
          }
        }
      }
      .adminInfo{
        color: #4d4d4d;
        padding: 35px;
        border: 1px solid #dadfe4;
        border-radius: 4px;
        .head{
          padding-bottom: 25px;
          h2{
            color: #616161;
          }
          p{
            font-size: 14px;
            color: #b5b5b5;
          }
        }
        .mid{
          .l{
            font-size: 15px;
            .position, .name{
            
              padding-bottom: 7px;
            }
            .mob {
              padding-top: 30px;
            
              p{
                &:first-child{
                  padding-bottom: 7px;
                }&:last-child{
                  font-weight: 500;
                }
              }
            }
          }
          .r{
            h3{
            
              text-transform: uppercase;
              letter-spacing: -0.5pt;
              padding-bottom: 15px;
            }
            .cert{
              padding-left: 30px;
              //background-image: url(../img/file.svg);
              background-repeat: no-repeat;
              background-position: 1px center;
              p{
              
                font-size: 14px;
                padding-bottom: 3px;
                border-bottom: 1px solid;
                display: table;
              }
            }
          }
        }
      }
      .bottom{
        padding-top: 15px;
        font-size: 14px;
      
        margin-top: 25px;
        border-top: 1px solid #dadfe4;
      }
    }
    &.right{
      width: 32%;
      flex-basis: 32%;
      max-width: 32%;
      min-width: 374px;
     /* width: 100%;
      flex-basis: 100%;*/
      padding-left: 35px;
      .rightBar{
        position: static;
        .Logo{
          margin-bottom: 40px;
          .pic{
            cursor: default;
            &:after{
              display: none;
            }
          }
        }
        .contact{
          margin-bottom: 20px;
          .call{
            border-radius: 20px;
            background-color: rgb( 140, 202, 17 );
            //box-shadow: 1.5px 2.598px 32px 0px rgb( 0, 0, 0 );
            width: 165px;
            height: 46px;
            margin: 0 auto 35px auto;
            position: relative;
            cursor: pointer;
            .buttC{
              border-radius: 50%;
              background-color: rgb( 100, 151, 0 );
              box-shadow: 1.5px 2.598px 14px 0 rgba(0, 0, 0, 0.14);
              position: absolute;
              left: 0;
              top: 0;
              width: 46px;
              height: 46px;
              //background-image: url(../img/wizard/Phone_icon.svg);
              background-repeat: no-repeat;
              background-position: center;
              background-size: 62%;
            }
            p{
              padding-left: 56px;
              display: flex;
              align-items: center;
              position: relative;
              height: 100%;
              text-transform: uppercase;
              color: #f8f8f7;
              text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.28);
            }
          }
        }
        .sHead{
          padding: 9px 0;
        }
        .sCont{
          background-size: 105%;
          background-color: #ffffff;
          padding: 15px 20px 35px 20px;
          .map{
            margin-top: 10px;
            height: 140px;
            .GMap-canvas{
              min-height: 140px;
              max-height: 140px;
              zoom: 0;
            }
          }
          .info{
            >p{
              background-repeat: no-repeat;
              background-size: 18px;
              background-position: 0 center;
              padding: 5px 0 5px 35px;
              font-size: 14px;
              letter-spacing: 0.1pt;
              &.phone, &.email{
                max-height: 31px;
              }
              &.address{
                //background-image: url(../img/location_grey.svg);
                padding-top: 15px;
              }
            }
          }
          .branchList{
            h2{
              text-align : center;
              padding-bottom: 20px;
            
              span{
                font-size: 22px;
              }
              color: #0095fc;
              font-size: 15px;
            }
            .branch {
              padding-bottom: 24px;
              &[data-current=true] * {
                color: #0096fd;
              }
              h3 a{
                color: #929292;
                font-size: 18px;
                text-transform: uppercase;
                padding-bottom: 0;
              
              }
              p.address{
                background-repeat: no-repeat;
                background-position: 0 center;
                padding: 5px 0 5px 27px;
                font-size: 14px;
                letter-spacing: 0.1pt;
                //background-image: url(../img/location_grey.svg);
                color: #000000;
                background-size: 15px;
                height: 27px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
            ul{
              &[data-all=false] {
                //height: 355px;
                overflow: hidden;
              }
              &[data-all=true] {
                height: auto;
                overflow: hidden;
              }
            }
          }
          .more{
            cursor: pointer;
            span{
            
              &:first-child{
                padding-right: 8px;
                color: #0096fd;
                text-transform: uppercase;
                font-size: 13px;
                padding-top: 3px;
              }
            }
          }
        }
        .timing{
          border-radius: 4px;
          margin-bottom: 20px;
          .sHead{
            h2{
              position: relative;
              span{
                position: absolute;
                right: 13px;
                font-size: 16px;
                top: 11px;
                cursor: pointer;
              }
            }
          }
          .sCont{
            padding: 0;
            background-color: #ffffff;
          }
        }
        .fees{
          margin-bottom: 20px;
          .sCont{
            padding-top: 35px;
            height: auto;
            min-height: inherit;
          }
          ul{
            li{
              padding-bottom: 10px;
              padding-left: 30px;
              //background-image: url(../img/wizard/fee_mini.svg);
              background-repeat: no-repeat;
              background-size: 20px;
              display: flex;
              justify-content: space-between;
            }
          }
        }
      }
    }
  }
  button.slick-arrow {
    border: 0;
    background-color: #2e97ff;
    color: #ffffffff;
    width: 100%;
    padding: 10px;
    cursor: pointer;
    border-radius: 4px;
    margin: 7px 0;
  }
  button.slick-arrow.slick-disabled {
    background-color: #e6e6e6;
    cursor: no-drop;
  }
}
.tab{
  padding: 25px 25px 30px 25px;
  &.specialties{
    .item{
      position: relative;

      .span{
        display: none;
      }
      &[data-has-doctors=true]{
        .name{
          cursor: pointer;
        }
        .span{
          width: 23px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 40px;
          position: absolute;
          right: 11px;
          span{
            width: 4px;
            height: 4px;
            background-color: #0096fd;
          }
        }
      }
      .name{
        //background-image: url(../img/speciality_icon.svg);
        &[data-opened="true"]{
          .span{
            right: 2px;
            height: 20px;
            flex-direction: column;
          }
        }
        &[data-opened="false"] + div{
          display: none;
        }
      }
      .docs{
        padding: 0 10px;
        .more{
          background-color: transparent;
        }

        .hearth{
          color: #cacaca;
          position: absolute;
          left: 7px;
          top: 5px;
          z-index: 100;
          cursor: pointer;
          &[data-loved=true]{
            color: #f76155;
          }

          &[data-anim=true]{
            .fa{
              animation: pulsate2 .3s ease-out;
              -webkit-animation: pulsate2 .3s ease-out;
              animation-iteration-count: infinite;
              -webkit-animation-iteration-count: infinite;
            }
          }
          span.fa{
            cursor: pointer;
          }
        }
      }
      .doctors{
        padding: 20px 10px;
        justify-content: flex-start;
        .more{
          background-color: transparent;
        }
        &:empty {
          display: none;
        }
        .doctor{
          //height: 220px;
          height: 180px;
          width: 30%;
          flex-basis: 30%;
          border: 1px solid #dadfe4;
          border-radius: 4px;
          background-color: #ffffff;
          position: relative;
          margin-bottom: 20px;
          &:nth-child(3n-1) {
            margin: 0 11px;
          }
          .glob{
            width: 100%;
            height: 100%;
            top: 0;
            position: absolute;
            -webkit-transition: -webkit-transform 0.5s;
            -moz-transition: -moz-transform 0.5s;
            -o-transition: -o-transform 0.5s;
            transition: transform 0.5s;
            -webkit-transform-style: preserve-3d;
            -moz-transform-style: preserve-3d;
            -o-transform-style: preserve-3d;
            transform-style: preserve-3d;
          }
          .box1{
            .photo{
              width: 85px;
              height: 85px;
              border: 4px solid #dadfe4;
              background-position: center;
              background-repeat: no-repeat;
              background-size: contain;
              border-radius: 100%;
              overflow: hidden;
              margin: 0 auto 20px auto;
            }
            .docName {
              font-size: 13px;
            
              text-align: center;
            }
          }
          .box1, .box2{
            padding: 7px 20px;
            position: absolute;
            width: 100%;
            height: 100%;
            -webkit-backface-visibility: hidden;
            -moz-backface-visibility: hidden;
            -o-backface-visibility: hidden;
            backface-visibility: hidden;
            left: 0;
          }
          .box2{
            -webkit-transform: rotateY(180deg);
            -moz-transform: rotateY(180deg);
            -o-transform: rotateY(180deg);
            transform: rotateY(180deg);
            padding: 15px 20px;
            .exp{
              padding-bottom: 20px;
              border-bottom: 1px solid #dadfe4;
              text-align: center;
              margin-bottom: 20px;
              &:last-child:not(:first-child){
                border: 0;
              }
              h4{
                padding-bottom: 4px;
              
              }
              span.ln {
              
                text-transform: uppercase;
                padding: 5px 3px 0;
                display: inline-block;
              }
            }
          }
          &:hover{
            .glob{
              transform: rotateY(180deg);
            }
            .box1{
              pointer-events: none;
              z-index: -2;
            }
          }
        }
      }
      .miniDoctors{
        width: 147px;
        padding-right: 35px;
        justify-content: flex-end;
        .doctor{
          width: 25%;
          flex-basis: 25%;
          height: 28px;
          border: 2px solid #dadfe4;
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
          border-radius: 100%;
          overflow: hidden;
          &:nth-child(2){
            margin: 0 8px;
          }
        }
      }
    }
  }
  &.amenities{
    .hosp{
      display: flex;
      padding-bottom: 50px;
      padding-top: 15px;
      color: #616161;
      >div{
        width: 50%;
        flex-basis: 50%;
        background-repeat: no-repeat;
        background-position: left;
        font-size: 15px;
        padding: 5px 0 5px 45px;
      }
    }
  }
  &.insurances{
    .item{
      .name{
        //background-image: none;
        cursor: pointer;
        position: relative;
        span{
          &:first-child{
            position: absolute;
            right: 0;
            color: #2e97ff;
          }
          &:last-child{
            position: absolute;
            width: 30px;
            height: 30px;
            left: 5px;
            top: 6px;
            border-radius: 100%;
            overflow: hidden;
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;

          }
        }
        &:after{
          content: '';
          position: absolute;
          right: 0;
          color: #0095fc;
          font-size: 13px;
          padding-bottom: 1px;
          border-bottom: 1px solid;
        
        }
      }
    }
  }
  &.custom{
    background-color: #eff1f9;
    .item{
      .name{
        cursor: default;
        &:after{
          display: none;
        }
      }
    }
  }

}
.actions.events{
  padding-top: 20px;
  margin-bottom: 30px;
  font-size: 18px;
  border-top: 1px solid #dadfe4;
  &.featured{
    padding-right: 35px;
    //background-image: url(../img/list-featured.png);
    background-repeat: no-repeat;
    background-size: 25px 41px;
    background-position: top 0 right;
    margin-bottom: 0;
    padding-bottom: 13px;
    padding-top: 10px;
    border-top: 0;
  }
  .hearth{
    color: #cacaca;
    &[data-loved=true]{
      color: #f76155;
    }

    &[data-anim=true]{
      .fa{
        animation: pulsate2 .3s ease-out;
        -webkit-animation: pulsate2 .3s ease-out;
        animation-iteration-count: infinite;
        -webkit-animation-iteration-count: infinite;
      }
    }
    span.fa{
      margin-right: 11px;
      cursor: pointer;
    }
  }
  .list{
    > div {
      width: 60px;
      display: flex;
      align-items: center;
      position: relative;
      .count{
        font-size: 12px;
      }
      &.share{
        color: #8cc63f;
        width: 32px;
        justify-content: center;
        margin-right: 3px;
        span.fa{
          margin: 0;
          cursor: pointer;
          &:hover + div{
            display: block;
          }
        }
      }
      &.view{
        color: #707072;
        span.fa{
          margin-right: 11px;
          cursor: pointer;
        }
      }
      &.hearth{
        width: 47px;
        margin: 0 20px;
      }
    }
    .likeC {
      position: relative;
      top: -1px;
    }
  }
  .lf {
    display: none;
    position: absolute;
    width: 150px;
    height: 68px;
    top: 16px;
    padding: 15px;
    z-index: 120;
    &:hover{
      display: block;
    }
    .c{
      border: 1px solid #dadfe4;
      background-color: #ffffff;
      position: relative;
      height: 100%;
      width: 100%;
      padding: 5px 5px;
      display: flex;
      justify-content: space-between;
      .SocialMediaShareButton {
        width: 30px;
        height: 30px;
        cursor: pointer;
        text-align: center;
        outline: 0;
        font-size: 23px;
        &:before{
          font: normal normal normal 14px/1 FontAwesome;
          font-size: inherit;
          text-rendering: auto;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
        }
        &.SocialMediaShareButton--facebook{
          color: #4267b2;
          &:before{
            content: "\f082"
          }
        }
        &.SocialMediaShareButton--twitter{
          color: #1da1f2;
          &:before{
            content: "\f081"
          }
        }
        &.SocialMediaShareButton--googlePlus{
          color: #dc4a38;
          &:before{
            content: "\f0d4"
          }
        }
        &.SocialMediaShareButton--linkedin{
          color: #0077b5;
          &:before{
            content: "\f08c"
          }
        }
      }
    }
  }
}
.timing{

  .timeHead{
    background-color: #e9ebf3;
    li{
      padding: 3px 0;
      font-size: 12px;
      font-weight: bold;
      width: 27px;
      box-sizing: content-box;
      text-align: left;
      position: relative;
      left: -5px;
      &.amR{
        color: #7ecaf1;
      }
    }
    .wrapperHead{
      width: 337px;
      position: relative;
      overflow: hidden;
      //padding: 9px 30px;
      height: 40px;
      .am{
        width: 710px;
        position: relative;
        padding: 9px 0 9px 45px;
      }
      .arrows {
        display: none;
        position: absolute;
        height: 40px;
        width: 40px;
        background-color: white;
        top: 0;
        border: 1px solid #e9ebf3;
        align-items: center;
        & > div {
          width: 50%;
          text-align: center;
          display: flex;
          height: 100%;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          &:hover{
            background-color: #e9ebf3ab;
          }
        }
      }
    }
  }
  .timeCont{
    position: relative;
    min-height: 380px;
    .slide{
      width: 2px;
      height: 100%;
      position: absolute;
      top: 0;
      z-index: 30;
      background-color: #ff4b1f;
      left: 52%;
    }
    .timeDays{
      width: 45px;
      position: absolute;
      left: 0;
      background-color: white;
      z-index: 500;
      &:after{
        content: "";
        position: absolute;
        width: 5px;
        height: 100%;
        right: 9px;
        top: 0;
        background-color: #0096fd;
      }
      li{
        height: 50px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        align-items: center;
        padding-left: 7px;
        span{
        
          font-size: 15px;
          color: #959699;
          &:last-child {
            width: 16px;
            height: 16px;
            background-color: #0096fd;
            border-radius: 100%;
            position: absolute;
            right: 4px;
            border: 2px solid #f9f9f9;
            z-index: 10;

            display: flex;
            align-items: center;
            justify-content: center;
            &.Closed{
              &:after{
                content: 'x';
                color: #ffffff;
                font-size: 8px;
                font-weight: bold;
              }
            }
          }
        }
        &[data-today=true] {
          .Opened, .Closed {
            background-color: #038403;
            width: 14px;
            height: 14px;
            &:after{
              content: '' !important;
              display: block;
              position: absolute;
              width: 17px;
              height: 17px;
              border: 2px solid #8cc63f;
              border-radius: 100%;
            }
            &:before{
              content: "\f00c";
              color: #ffffff;
              font: normal normal normal 14px/1 FontAwesome;
              font-size: 6px;
            }
          }
        }
      }
    }
    .fullPart{
      padding-left: 45px;
      width: 710px;
      position: relative;
      z-index: 10;
      .parts{
        width: 100%;
        li{
          width: 27px;
          height: 50px;
          position: relative;
          display: flex;
          align-items: center;
          &:after{
            content: '';
            height: 6px;
            width: 100%;
            background-color: #dadfe4;
            position: absolute;
            z-index: 1;
          }
          .time{
            height: 6px;
            width: 0;
            background-color: #7ecaf1;
            position: absolute;
            z-index: 2;
          }
          &.t24{
            .time{
              width: 100%;
            }
            &:first-child{
              &:before {
                content: '24 hours';
                width: max-content;
              }
            }
          }
          &:before {
            content: attr(data-time);
            font-size: 10px;
            position: absolute;
            top: 8px;
            z-index: 10;
          
            left: 0;
            display: none;
          }
          &.right{
            &:before {
              top: 33px;
              right: 0;
              left: unset;
            }
          }
          &:hover{
            &:before{
              display: block;
            }
          }
          &[data-today=true]{
            .time{
              height: 14px;
              background-color: #8cc63f;
            }
            &:after {
              height: 14px;
            }
            &:before {
              top: 2px;
              display: block;
            }
            &.right{
              &:before {
                top: 40px;
              }
            }
          }
        }
      }
      [data-hovered=true]{
        li{
          &:before{
            display: block;
          }
        }
      }
    }
    .bgPart{
      top: 0;
      padding-left: 45px;
      width: 710px;
      position: absolute;
      .parts {
        width: 100%;
        li {
          width: 27px;
          border-right: 1px solid #dadfe4;
          height: 50px;
        }
      }
    }
    .wrapperSlide{
      width: 327px;
      overflow: hidden;
      position: relative;
    }
  }
}
[data-featured=true]{
  #clinicProf{
    > div.left .actions{
      padding-right: 35px;
      //background-image: url(../img/list-featured.png);
      background-repeat: no-repeat;
      background-size: 25px 57px;
      background-position: top 0 right;
      margin-bottom: 0;
      padding-bottom: 30px;
    }
  }
}
.thrive_logout.swal2-modal{
  width: 480px !important;
  //background-image: url('../img/cloud.png') !important;
  background-repeat: no-repeat !important;
  padding-bottom: 33px !important;
  .swal2-icon.swal2-warning{
    width: 35px !important;
    height: 35px !important;
    color: #0095fc;
    border-color: #0095fc;
    font-size: 31px;
    line-height: 1.2;
  }
  .swal2-title{
    font-weight: 500;
    font-size: 19px;
    margin-bottom: 20px;
  }
  button{
    outline: 0;
    &.swal2-confirm{
      color: #000000;
      background-color: #aaaaaa !important;
      border-left-color: #aaaaaa !important;
      border-right-color: #aaaaaa !important;
      &:hover{
        background-color: #a3a3a3 !important;
      }
    }
    &.swal2-cancel{
      color: #ffffff;
      background-color: #0095fc !important;
      border-left-color: #0095fc !important;
      border-right-color: #0095fc !important;
      &:hover{
        background-color: #0084e1 !important;
      }
    }
    &.swal2-close{
      color: #0095fc !important;
    }
  }
  &.continue{

    button{
      &.swal2-cancel{
        color: #000000;
        background-color: #aaaaaa !important;
        border-left-color: #aaaaaa !important;
        border-right-color: #aaaaaa !important;
        &:hover{
          background-color: #a3a3a3 !important;
        }
      }
      &.swal2-confirm{
        color: #ffffff;
        background-color: #0095fc !important;
        border-left-color: #0095fc !important;
        border-right-color: #0095fc !important;
        &:hover{
          background-color: #0084e1 !important;
        }
      }
    }
  }
}

@media screen and (max-width: 1100px){
  #clinicProf {
    position: relative;
    overflow: hidden;
    min-height: 1750px;
    .mobOpen{
      display: block;
    }
    > div{
      &.left {
        width: 100%;
        .header{
          .claim{
            margin-right: 50px;
          }
        }
      }
      &.right{
        display: block !important;
        flex-basis: 360px;
        max-width: 360px;
        padding-left: 14px;
        min-width: 360px;
        width: 360px;
        position: absolute;
        right: -390px;
        background-color: #f9f9f9;
        z-index: 10;
        -webkit-transition: all .3s ease;
        -moz-transition: all .3s ease;
        -ms-transition: all .3s ease;
        -o-transition: all .3s ease;
        transition: all .3s ease;
        height: auto;
        &[data-opened=true]{
          right: 0;
          z-index: 100;
          max-width: 100%;
        }
        &[data-opened=false]{
            width: 100%;
            flex-basis: 100%;
            max-width: 0%;
        }
      }
    }
  }
  .wizardMain{
    .inputWrap {
      .title{
        h2{
          font-size: 14px;
          color: #4d4d4d;
          padding-bottom: 4px;
          display: block;
        }
      }
      p.setDef{
        font-size: 9px;
      }
    }
  }
  .wizardMain .inputWrap.number .inputParent > div.fRt .code{
    width: 44px;
  }
  .wizardMain .inputWrap.s3.number {
    width: 47%;
  }
  .wizardMain .inputWrap.number .inputParent > div.fRt.facilityEmail {
    margin-top: 2px;
  }
}
@media screen and (max-width: 860px){
  .sign > div.fcl{
    display: none;
  }
  .account-page-wizard{
    .desktop{
      display: none;
    }
    .mobile{
      display: block;
    }
  }
}
@media screen and (max-width: 750px){
  #clinicProf {

    > div{
      &.left {
        .SpecInsTab {
          .tabs {
            .tab{
              &.specialties {
                .item {
                  .doctors {
                    .doctor{
                      width: 43%;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 550px){
  #clinicProf {

    > div{
      &.left {
        .SpecInsTab {
          .list{
            width: 100%;
            flex-basis: 100%;
            margin-bottom: 20px;
            li{
              margin-left: 0;
              width: 100%;
              flex-basis: 100%;
              &[data-active="true"]{
                width: 100%;
                flex-basis: 100%;
              }
            }
          }
          .tabs {
            width: 100%;
            flex-basis: 100%;
            .tab{
              &.specialties {
                .item {
                  .miniDoctors{
                    display: none;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 450px){
  #clinicProf {

    > div{
      &.right{
        width: 100%;
        flex-basis: 100%;
        max-width: 100%;
        >.rightBar{
          max-width: 346px;
          margin: auto;
        }
      }
    }
  }
}


.cls{
  position: absolute;
  padding: 10px;
  cursor: pointer;
  right: 0;
  top: 0;
  font-family: serif;
  color: #0095fc;
  font-size: 32px;
  line-height: 0.6;
}
.claimPup {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: rgba(0,0,0,.4);
  z-index: 1000;
  .claimContainer{
    @extend .flexBox;
    align-items: center;
    justify-content: center;
    height: 100%;
    .pop{
      width: 460px;
      height: 340px;
      background-color: #f4f5f9;
      border-radius: 4px;
      box-shadow: 0 2px 23px 2px rgba(128, 128, 128, 0.35);
      padding: 40px;
      position: relative;
      .cls{
        position: absolute;
        padding: 10px;
        cursor: pointer;
        right: 0;
        top: 0;
        font-family: serif;
        color: #0095fc;
        font-size: 32px;
        line-height: 0.6;
      }
      h3{
        font-size: 17px;
      }
      .clinicItem{
        margin: 25px 0;
        padding: 10px;
        box-shadow: 0 2px 23px 2px rgba(128, 128, 128, 0.35);
        background-color: #fafafb;
        border-radius: 4px;
        height: 130px;
        .left{
          width: 30%;
          flex-basis: 30%;
          .logo{
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            border-radius: 100%;
            overflow: hidden;
            border: 5px solid #dadfe4;
            height: 100%;
          }
        }
        .right{
          width: 70%;
          flex-basis: 70%;
          padding-left: 10px;
          @extend .flexBox;
          justify-content: space-between;
          // flex-direction: column;
          align-items: flex-start;
          h2{
            font-size: 15px;
            color: #4d4d4d;
            padding: 7px 0px;
          }
          .address{
            width: 100%;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            font-size: 14px;
            padding: 5px 0;
          }
          .phone{
            background-repeat: no-repeat;
            background-size: 15px;
            background-position: 0 center;
            padding: 4px 0 4px 25px;
            margin-bottom: 5px;
            font-size: 14px;
            //background-image: url(../img/call_grey.svg);
          }
        }
      }
      .butts{
        @extend .flexBox;
        justify-content: space-between;
        width: 80%;
        margin: 20px auto 0;
        .claim{
          width: 49%;
          margin-right: 2%;
        }
        .skip{
          width: 49%;
          background-color: rgba(0, 150, 253, 0.71);
        }
      }
      &.call{
        width: 300px;
        height: auto;
        ul{
          li{
            padding: 5px 0;
            margin: 3px 0;
            //border-bottom: 1px solid #128fe933;
            span{
              margin-right: 7px;
            }
          }
        }
      }
      &.fullTime{
        height: 80%;
        width: 100%;
        // background: url(../img/cloud.png) top left no-repeat, url(../img/cloud2.png) top right no-repeat, white;
        h2{
          padding: 10px 0 50px 0;
          text-align: center;
          color: #0095fc;
        }
        .timing {
          .timeHead{
            .wrapperHead{
              width: 100%;
              .am{
                left: 0 !important;
              }
              .arrows{
                display: none;
              }
            }
          }
          .timeCont{
            .wrapperSlide{
              width: 100%;
              .fullPart{
                left: 0 !important;
                [data-hovered]{
                  pointer-events: none;
                }
                li:before {
                  display: block;
                }
              }
              .bgPart{
                left: 0 !important;
              }
            }
          }
        }
      }
    }
  }
  &.doctorP{
    .claimContainer {
      .pop {
        height: auto;
        width: 600px;
        padding: 50px 25px 25px 25px;
        h3{
          text-align: center;
          font-size: 16px;
          color: #737374;
          padding-top: 5px;
          position: relative;
        }
        >h3{
          padding-top: 40px;
          &:after{
            content: '!';
            position: absolute;
            color: #0096fd;
            font-size: 32px;
            border-radius: 100%;
            border: 1px solid;
            width: 36px;
            height: 36px;
            top: -20px;
            left: 0;
            right: 0;
            margin: auto;
          }
        }
        .clinicItem {
          min-height: 180px;
          height: auto;
          padding: 20px;
          .top{
            .left {
              width: 80px;
              flex-basis: 80px;
              height: 80px;
              .logo{
                border: 2px solid #0096fd;
                background-size: cover;
              }
            }
            .right{
              justify-content: center;
              h2 {
                font-size: 16px;
              }
              p{
                font-style: italic;
              }
            }
          }
          .info{
            .all {
              padding-top: 12px;
              font-size: 12px;
              opacity: 0.7;
              span,
              p{
                font-size: 14px;
              }

              p.spec, .t p, .lng{
                background-size: 18px;
                background-repeat: no-repeat;
                background-position: left center;
                padding: 5px 0 5px 27px;
              }
              .lng {
                //background-image: url(../img/glob.svg);
                .ln:not(:last-child):after {
                  content: ', ';
                }
              }
              .t{
                p{
                  white-space: nowrap;
                  width: 100%;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  padding-top: 4px;
                  padding-bottom: 5px;
                }
              }
            }
            .left{
              width: 40%;
              flex-basis: 40%;
            }
            .right{
              width: 60%;
              flex-basis: 60%;
              .head{
                padding-top: 15px;
              }
              .twoFa{
                .facility{
                  padding-top: 10px;
                  align-items: center;
                  .logo{
                    width: 30px;
                    height: 30px;
                    background-size: cover;
                    background-position: center;
                    border-radius: 100%;
                    border: 1px solid #0096fd;
                  }
                  .title{
                    padding-left: 10px;
                    h3{
                      text-align: left;
                    }
                    .address{
                      padding-left: 20px;
                      //background-image: url(../img/location.png);
                      background-repeat: no-repeat;
                      background-size: 15px;
                      background-position: left center;
                      color: #999999;
            }
          }
        }
      }
    }
  }
}
        .butts{
          width: 345px;
          .defX{
            height: auto;
            font-size: 17px;
            margin: 15px 5px 0;
            padding: 10px 32px;
            &.claim,
            &.skip{
              width: 44%;
            }
            &.skip{
              color: #000;
              background-color: #aaaaaa
            }
          }
        }
      }
    }
  }
}
.Select.location_field{
  height: 48px;
  .Select-control {
    height: 100%;
    .Select-value{
      padding-top: 6px;
      span{
        &:before{
          display: inline-block;
          font: normal normal normal 14px/1 FontAwesome;
          font-size: inherit;
          text-rendering: auto;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          content: "\f124";
          padding-right: 12px;
          color: #868686;
          padding-left: 2px;
        }
      }
    }
    .Select-placeholder{
      padding-top: 6px;
      &:before{
        display: inline-block;
        font: normal normal normal 14px/1 FontAwesome;
        font-size: inherit;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        content: "\f124";
        padding-right: 12px;
        color: #999999;
        padding-left: 2px;
      }
    }
    .Select-input{
      width: 100%;
      height: 47px;
      input {
        padding: 0;
        border: 0;
        min-width: 100%;
        outline: 0;
        box-shadow: none;
      }
    }
  }
  .Select-clear{
    display: block;
  }
}
.defX{
  @extend .flexBox;
  height: 35px;
  background-color: #0096FD;
  color: #ffffff;
  text-transform: uppercase;
  border-radius: 4px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  box-shadow: 0 1px 7px 0 rgba(0, 0, 0, 0.34);
}
body.const{
  overflow: hidden;
}
.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__input {
  width: 240px;
  height: 30px;
  padding: 10px 20px;
  // font-family: Helvetica, sans-serif;
  font-size: 16px;
  border: 1px solid #aaa;
  border-radius: 4px;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}
.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 51px;
  width: 100%;
  border: 1px solid #aaa;
  background-color: #fff;
  // font-family: Helvetica, sans-serif;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  max-height: 192px;
  overflow-y: auto;
  z-index: 2;
}
.react-autosuggest__input--focused + .react-autosuggest__suggestions-container--open {
  display: block;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}

.saveContinue{
  //background-image: url(../img/wizard/saveContinue.png);
  background-repeat: no-repeat;
  width: 60px;
  height: 35px;
  background-color: #ff552c;
  border-top-left-radius: 19px;
  border-bottom-left-radius: 19px;
  box-shadow: 0 2px 23px 2px rgba(128, 128, 128, 0.24);
  cursor: pointer;
  right: 0;
  top: 50%;
  position: fixed;
  background-position: center;
  background-size: 20px auto;
  z-index: 40;
  &[data-loading=true]{
    animation: animatedBackground 1s linear  infinite;
    overflow: hidden;
  }
}
@keyframes animatedBackground {
  0% { background-position: center -200%; }
  100% { background-position: center 300%; }
}








.def-enter {
  opacity: 0.01;
}

.def-enter.def-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.def-leave {
  opacity: 1;
}

.def-leave.def-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}


































body.sticky{
  .account-page-wizard{
    padding-top: 45px;
    >.row{
      z-index: 10;
    }
  }
}

@media screen and (min-width: 900px){
  body.sticky{
    div.defHeader{
      .defLogo {
        width: 150px;
        margin-right: 16px;
      }
    }
    main:not(.home){
      .fully {
        min-height: 100px;
        .buttons{
          .button{
            button {
              height: 40px;
              a{
                padding: 9px 0;
              }
            }
          }
        }
        .inputs {
          > div{
            input{
              height: 40px;
            }
          }
        }
        .bar_filter {
          height: 41px !important;
          ul{
            li{
              > div{
                min-height: 41px;
                .Select-placeholder{
                  background-size: 20px;
                  font-size: 13px;
                  padding-top: 3px;
                  padding-left: 31px;
                }
                .Select-control {
                  min-height: 35px;
                }
              }
            }
          }
        }
      }
    }
  }
}


@-webkit-keyframes pulsate {
  0% {-webkit-transform: scale(0.1, 0.1); opacity: 0.0;}
  50% {opacity: 1.0;}
  100% {-webkit-transform: scale(1.4, 1.4); opacity: 0.0;}
}
@keyframes pulsate {
  0% {-webkit-transform: scale(0.1, 0.1); opacity: 0.0;}
  50% {opacity: 1.0;}
  100% {-webkit-transform: scale(1.4, 1.4); opacity: 0.0;}
}
@-webkit-keyframes pulsate2 {
  0% {-webkit-transform: scale(1.0, 1.0); }
  100% {-webkit-transform: scale(1.4, 1.4); }
}
@keyframes pulsate2 {
  0% {-webkit-transform: scale(1.0, 1.0); }
  100% {-webkit-transform: scale(1.4, 1.4); }
}


::-webkit-scrollbar{width:0px;}
::-webkit-scrollbar-thumb{border-width:1px 1px 1px 2px;border-color: #575757;background-color: #585858;}
::-webkit-scrollbar-thumb:hover{border-width:2px 2px 2px 2px;border-color: #2d2d2d;background-color:#ffffff;}
::-webkit-scrollbar-track{border-width:0;}
::-webkit-scrollbar-track:hover{border-left:solid 1px #aaa;background-color:rgba(48, 48, 48, 0.61)}
::-moz-scrollbar{width:5px;}
::-mozt-scrollbar-thumb{border-width:1px 1px 1px 2px;border-color: #575757;background-color: #585858;}
::-moz-scrollbar-thumb:hover{border-width:1px 1px 1px 2px;border-color: #2d2d2d;background-color:rgba(48, 48, 48, 0.61);}
::-moz-scrollbar-track{border-width:0;}
::-moz-scrollbar-track:hover{border-left:solid 1px #aaa;background-color:rgba(48, 48, 48, 0.61);}



#pac-input {
  background-color: #fff;
  font-family: Roboto;
  font-size: 15px;
  margin-left: 12px;
  padding: 0 11px 0 13px;
  text-overflow: ellipsis;
  margin-top: 10px;
  border: 1px solid transparent;
  border-radius: 2px 0 0 2px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  height: 29px;
  outline: none;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.17);
  width: 300px;
  &:focus{
    border-color: #4d90fe;
  }
}

.pac-container {
  font-family: Roboto;
  margin-top: 133px;
  margin-left: 131px;
  width: 350px !important;
}

#type-selector {
  color: #fff;
  background-color: #4d90fe;
  padding: 5px 11px 0px 11px;
}

#type-selector label {
  font-family: Roboto;
  font-size: 13px;
}
#target {
  width: 345px;
}
.ClinicFull{
  .daysList{
    .days{
      @extend .flexBox;
      >div{
        width: 25%;
        flex-basis: 25%;
        margin-bottom: 25px;
        text-align: center;
        .name{
          text-align: center;
          padding-bottom: 10px;
        }
        span{
          font-size: 13px;
        }
      }
    }
  }
}


@media (max-width: 1300px) {
  .default {
    .nTop {
      .wrapCity {
        .sign {
          &.loginF{
            & > div {
              ul.list{
                right: 0;
                left: auto;
              }
            }
          }
        }
      }
    }
  }
  .sign {
    & > div {
      &.not {
        >div{
          ul.list{
            right: 0;
            left: auto;
          }
        }
      }
    }
  }
}
@media (max-width: 1200px) {
 
  .ListDoctors.row {
    .content {
      img.arrow1 {
        top: 17%;
        left: 54%;
        width: 36%;
      }
      img.arrow2 {
        top: 59%;
        left: 73%;
        width: 18%;
      }
      img.arrow3 {
        bottom: 11px;
        left: 25.3%;
        width: 10%;
      }
      img.arrow4 {
        bottom: 33%;
        left: 13%;
        width: 8%;
      }
    }
  }
  .ClinicFull{
    .gallery{
      .slider{
        width: 50%;
      }
    }
  }
}



@media (max-width: 800px) {

  .logo {
    img {
      width: 250px;
      height: auto;
    }
  }


  .fully {

    .buttons {
      .filter {
        margin-left: 10px;
      }

    }

  }

}


@media (max-width: 900px) {
  .ClinicFull {
    .header {
      .right {
        -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
        max-width: 100%;
      }
      .left {
        -ms-flex-preferred-size: 200px;
        flex-basis: 200px;
        max-width: 200px;
        margin: 0 auto 26px auto;
      }
    }
    .alls > div > div{
      -webkit-column-count: 3;
      -moz-column-count: 3;
      column-count: 3;
    }
  }
  div.defHeader{
    position: static;
  }
  header {
    > .topHolder {
      box-shadow: 0px 1px 10px 1px #ccc;
      margin-bottom: 20px;
    }
  }

  .headerTop {
    align-items: center;
    padding: 10px 0;
  }

  .logo {
    img {
      width: 200px;
    }
  }

  .nTop {
    margin-top: 0;
    .buildings {
      display: none;
    }
    .buildings-mobile {
      display: inherit;
    }
    .car,
    .marker,
    .truck {
      display: none;
    }
  }


  .fully {
    .buttons {
      flex-basis: 32%;
    }
    .bar_filter {
      ul {
        flex-wrap: wrap;
        li {
          flex-basis: 48%;
          margin-bottom: 15px;
        }
      }
    }
  }
  body.sticky main:not(.home) .fully .bar_filter {
    height: 109px !important;
  }
  div.defHeader{
    .fully{
      height: auto;
      .bar_filter{
        margin-top: 20px;
      }
    }
  }
  .ClinicsSlider.row .sliders .slider{

    > div .slick-slide{

      .content{
        .description{
          line-height: 14pt;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .fully {
    .buttons {
      flex-basis: 33%;
    }
    .inputs {
      > div:nth-child(2) {
        flex-basis: 35%;
      }
      > div:last-child {
        flex-basis: 64%;
      }
    }
  }
}

@media (max-width: 750px) {
  .default{
    .nTop{
      .wrapCity{
        .sign{
          width:100%;
        }
      }
    }
  }
  .ClinicFull {
    .alls > div > div{
      -webkit-column-count: 2;
      -moz-column-count: 2;
      column-count: 2;
    }
    .gallery{
      .slider{
        width: 70%;
      }
    }
  }
  div.defHeader, .Sidebar > div{
    position: static;
  }
  .fully {
    flex-wrap: wrap;
    min-height: initial;
    .vis {
      flex-wrap: wrap;
    }
    .inputs {
      flex-basis: 100%;
      margin-right: 0;
      > div {
        flex-basis: 100%;
        max-width: initial;
        margin-bottom: 15px;
        img{
          width: 200px;
        }
      }
      > div:nth-child(2) {
        flex-basis: 100%;
        max-width: initial;
      }
      > div:last-child {
        flex-basis: 100%;
        max-width: initial;
      }
    }
    .buttons {
      flex-basis: 100%;
      .filter {
        order: 2;
      }
    }
    &.expand {
      .bar_filter {
        //height: 255px;
        margin-top: 15px;
      }
    }
  }

  .fully {
    .bar_filter{
      ul{
        li:nth-child(2),
        li:nth-child(3){
          display: none;
        }
      }
    }
  }

  body.sticky main:not(.home) .fully .bar_filter{
    height: auto !important;
  }
  div.defHeader{
    .fully{
      .buttons{
        display: none;
      }
      .mobSearch{
        display: block;
      }
      .inputs{
        max-width: 100%;
        > div{
          &:nth-child(2){
            width: 100%;
            flex-basis:100%;
            margin-bottom: 10px;
          }
          &:last-child{
            flex-basis: 100%;
            width: 100%;
            max-width: 100%;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            justify-content: space-between;
          }
          input#location{
            width: 100%;
          }
        }
      }
    }
  }
  .default .nTop{
    min-height: 140px;
  }
  main.list-page{
    .results{
      .Sidebar{
        display: none;
      }
      .clinicsList{
        -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
        max-width: 100%;
        padding: 5px;
        .each {
          position: relative;
          .logo{
            // display: none;
            width: 100px;
            height: 100px;
            align-self: center;
            margin-left: 1%;
            border: 4px solid #dadfe4;
          }
          &[data-featured=true] {
            // &:after{
            //   // display: none;
            // }
            
            .info{
              border: 2px solid #108ee9;
              //background-image: none;
              .leftSect{
                max-width: 80%;
                .name{
                  text-overflow: ellipsis;
                  display: inline-block;
                  width: 100%;
                  white-space: nowrap;
                  overflow: hidden;                  
                }
                .each_one{
                  display: none;                  
                }
              }
              .rightSect{
                flex-basis: 100%;
                max-width: 100%
              }
            }
          }
          .info{
            position: static;
            //background-image: none;
            max-width: 100%;
            padding-left: 25%;
            padding-right: 10px;
            border-radius: 5px;
            border: 2px solid transparent;
            background-color: #ffffff;
            &:hover{
              box-shadow: -2px 2px 5px #c4c4c4;
              filter: none;
              -webkit-filter: none;
            }
            .leftSect{
              max-width: 80%;
              .name{
                text-overflow: ellipsis;
                display: inline-block;
                width: 100%;
                white-space: nowrap;
                overflow: hidden;                
              }
              .each_one{
                display: none;                  
              }
            }
            .rightSect{
              flex-basis: 100%;
              max-width: 100%
            }
          }
        }
        .bottom {
          display: block;
          .left {
            position: relative;
            top: -18px;
            margin: auto;
            text-align: center;
          }
          .newClinic {
            margin: 10px auto;
          }
          .pagination {
            width: 275px;
            display: block;
            margin: auto;
            li{
              display: none;
              &:first-child,
              &:last-child,
              &.active,
              &.active + li{
                display: inline-block;
              }
            }
          }
        }
      }
    }
  }

}

.socialMob, .mobSearch{
  display: none;
}
@media (max-width: 650px) {
  .wrapCity {
    padding-top: 90px;
  }
  .logo, .defLogo {
    width: 100%;
    text-align: center;
    padding-bottom: 20px;
    img{
      width: 200px;
    }
  }
  .SearchBar.row{
    display:none;
  }
  .headerTop{
    .sign{
      display:none !important;
    }
    .logo{
      padding-bottom: 0px;
    }
  }
  .fully{
    padding: 42px 25px 15px 25px;
    .inputs{
      max-width: 100%;
    }
  }
  footer{
    > .row{
      height: auto;

      padding-bottom: 10px;
      padding-top: 60px;
    }
    .items {
      padding-top: 40px;
      display: block;
      >div{
        display: table;
        margin: 10px auto;
      }
      .footer-logo{
        margin: 50px auto;
        right: 0;
      }
    }
  }


  .ClinicsSlider.row .sliders .slider{
    height: 500px;
    overflow: hidden;
    &.newly,
    &.rated{
      display: none;
    }
    &.featured{

      -ms-flex-preferred-size: 80%;
      flex-basis: 80%;
      max-width: 80%;
      order: 0;
      margin: auto;
    }
    > div .slick-slide{

      height: 370px;
      .picture {
        max-height: 200px;
        height: 200px;
      }
      .content{
        .description{
          line-height: 14pt;
        }
      }
    }
  }


}
@media (max-width: 550px) {

  

  .report-page {
    .form {
      form {
        div.e {
          flex-basis: 100%;
          &:first-child {
            margin-right: 0;
          }
        }
      }
    }
  }

  .report-page {
    .form {
      form {
        .go {
          justify-content: space-between;
          button, a {
            width: auto;
            flex-basis: 48%;
            margin: 0;
          }
        }
      }
    }
  }
  footer{
    .items{
      padding-top: 0;
      .footer-logo{
        margin: 0 auto;
        a{
          display: none;
        }
      }
      .feedback{
        ul{
          li{
            a{
              span{
                display: none;
              }
            }

            &:last-child{
              a {
                padding-right: 0;
              }
            }
          }
        }
      }
      .social{
        display: none;
      }
    }
    .socialMob{
      display: block;
      width: 200px;
      margin: 0 auto 30px auto;
      li{
        text-align: center;
        img{
          max-height: 30px;
          text-align: center;
        }
        &:last-child{
          img{
            width: 31px;
          }
        }
      }
    }
  }
  .ClinicFull {
    .daysList{
      .days{
        > div {
          width: 50%;
          flex-basis: 50%;
        }
      }
    }
  }
}

