.listdoctor {
    width: 100%;
    min-width: 1440px;
    height: 100%;
    position: fixed;
    z-index: 1;
    margin: 0px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: rgba(9, 28, 40, 0.87);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.listdoctor .listdoctorview {
    width: 895px;
    height: 469px;
    position: fixed;
    margin: 0px;
    background-color: white;
    border-radius: 12px;
    display: flex;
    overflow: hidden;
}

.listdoctor .listdoctorview .dismissButton {
    position: absolute;
    top: 10px;
    right: 18px;
    color: red;
    width: 10px;
    height: 10px;
    font-size: 18px;
}

.listdoctor .listdoctorview .listdoctorleftside {
    position: relative;
    margin: 0 auto;
    height: 100%;
    width: 313px;
    overflow: hidden;
    list-style: none;
    padding: 0;
    z-index: 1;
    background-color: #3598db;
}

.listdoctor .listdoctorview .listdoctorleftside .subContainer {
    position: relative;
    width: fit-content;
    height: 100%;
    z-index: 1;
    display: flex;
    box-sizing: border-box;
    justify-content: flex-start;
}

.listdoctor .listdoctorview .listdoctorleftside .swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets {
    position: relative;
    top: -90px;
    z-index: 1;
    display: flex;
    justify-content: center;
    width: auto;
}

.listdoctor .listdoctorview .listdoctorleftside .swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets .swiper-pagination-bullet {
    width: 10px;
    height: 10px;
    border-radius: 400px;
    background: #3598db;
    border: 1px solid white;
    margin: 5px;
}

.listdoctor .listdoctorview .listdoctorleftside .swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets .swiper-pagination-bullet.swiper-pagination-bullet-active {
    width: 10px;
    height: 10px;
    border-radius: 400px;
    background: #fff;
    margin: 5px;
}

.listdoctor .listdoctorview .listdoctorleftside .subContainer .leftsidecell {
    width: 313px;
    height: 100%;
    position: relative;
    margin: 0px;
    background-color: #3598db;
    display: grid;
    justify-items: center;
    padding: 123px 0;
}

.listdoctor .listdoctorview .listdoctorleftside .subContainer .leftsidecell .doctorimg {
    width: auto;
    height: 140px;
    position: relative;
}

.listdoctor .listdoctorview .listdoctorleftside .subContainer .leftsidecell .attractright {
    width: auto;
    height: auto;
    position: relative;
    color: white;
    font-size: 18px;
    margin-top: 10px;
}

.listdoctor .listdoctorview .listdoctorleftside .subContainer .leftsidecell .newpatient {
    width: auto;
    height: auto;
    position: relative;
    color: white;
    font-size: 12px;
    margin: 10px;
    text-align: center;
}

.listdoctor .listdoctorview .listdoctorrightside {
    width: 582px;
    height: auto;
    position: relative;
    margin: 40px 30px;
    background-color: white;
    border: none;
    display: grid;
    justify-items: center;
}

.listdoctor .listdoctorview .listdoctorrightside .titleparent {
    width: 100%;
    height: auto;
    position: relative;
    margin: 0px;
    background-color: white;
    display: grid;
    justify-items: center;
}

.listdoctor .listdoctorview .listdoctorrightside .titleparent .text {
    width: auto;
    height: auto;
    position: relative;
    margin: 0px;
    color: #4a4a4a;
    font-size: 24px;
    font-weight: bold;
}

.listdoctor .listdoctorview .listdoctorrightside .titleparent .view {
    width: 103px;
    height: 8px;
    position: relative;
    background-color: #3598db;
    border-radius: 4px;
    align-self: center;
}

.listdoctor .listdoctorview .listdoctorrightside .doctorinfoparent {
    width: 100%;
    height: auto;
    position: relative;
    margin: 0px;
    background-color: white;
    border: none;
    display: flex;
    justify-content: space-between;
    align-self: center;
}

.listdoctor .listdoctorview .listdoctorrightside .doctorinfoparent .cell {
    width: 50%;
    height: auto;
    position: relative;
    margin: 10px;
}

.listdoctor .listdoctorview .listdoctorrightside .doctorinfoparent .cell .title {
    width: 100%;
    height: auto;
    position: relative;
    margin: 0px;
    font-size: 11px;
    color: #4a4a4a;
}

.listdoctor .listdoctorview .listdoctorrightside .doctorinfoparent .cell .inputfield {
    width: 100%;
    height: auto;
    position: relative;
    margin: 0px;
}

.listdoctor .listdoctorview .listdoctorrightside .sendrequestbutton {
    height: 45px;
    position: relative;
    margin: 0px;
    font-size: 18px;
    display: grid;
    justify-content: center;
    align-items: center;
    align-self: end;
}