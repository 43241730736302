#facility-grid-page {
	padding: 20px 30px;
	position: relative;
	height: calc( 100% - 60px);
	overflow: hidden;
}

@media (max-width: 800px) {
	#facility-grid-page {
		padding: 0;
	}
}

#facility-grid-page #myTab {
	width: 100%;
	position: relative;
}

#facility-grid-page #myTab li {}

#facility-grid-page .page-format {
	position: absolute;
	line-height: 60px;
}

#facility-grid-page .page-format a i {
	color: #999;
	font-size: 18px;
}

#facility-grid-page .page-format a i:hover {
	color: #0196FD;
}

#facility-grid-page .page-grid {
	right: 28px;
}

#facility-grid-page .page-list {
	right: 0;
}

#facility-grid-page .nav-tabs .nav-link {
	color: #999;
	padding: 20px;
	position: relative;
	/* text-transform: uppercase; */
}

#facility-grid-page .nav-tabs .nav-link::after {
	display: block;
	content: '';
	border: 1px solid #C5C5C6;
	border-width: 0 2px 2px 0px;
	display: inline-block;
	padding: 5px;
	transform: rotate(-135deg);
	-webkit-transform: rotate(-135deg);
	position: absolute;
	bottom: -6px;
	left: 44%;
	display: none;
}

#facility-grid-page .nav-tabs .nav-item.show .nav-link, #facility-grid-page .nav-tabs .nav-link.active {
	color: #0196FD;
	background-color: transparent;
	border-color: transparent;
}

#facility-grid-page .nav-tabs .nav-item.show .nav-link::after, #facility-grid-page .nav-tabs .nav-link.active::after {
	display: block;
}

#facility-grid-page .nav-tabs .nav-link span {
	background-color: #C5C5C6;
	padding: 1px 8px;
	border-radius: 10px;
	color: #fff;
}

#facility-grid-page .nav-tabs .nav-item.show .nav-link span, #facility-grid-page .nav-tabs .nav-link.active span {
	background-color: #0196FD;
}

#facility-grid-page #myTabContent {
	width: 100%;
	height: 100%;
	padding: 30px 0px;
}

.single-medical {
	display: -webkit-flex;
	display: -webkit-box;
	display: -moz-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	flex: 1;
	min-height: 300px;
}

.single-medical ul {
	align-content: center;
	flex: 1;
	min-height: 300px;
}

.single-medical li {
	flex: 1;
	background: #fff;
	margin: 10px 15px;
	box-shadow: 1px 3px 4px #ddd;
	padding: 10px;
	text-align: center;
	width: 15%;
	max-width: 225px;
	height: auto;
	align-items: stretch;
	display: inline-block;
	white-space: nowrap;
	border-radius: 16px;
}

@media (max-width: 576px) {
	.single-medical li {
		width: 85%;
	}
}

@media (max-width: 768px) and (min-width: 576px) {
	.single-medical li {
		width: 35%;
	}
}

@media (max-width: 992px) and (min-width: 768px) {
	.single-medical li {
		width: 45%;
	}
}

@media (max-width: 1200px) and (min-width: 992px) {
	.single-medical li {
		width: 50%;
	}
}

.single-medical .medical-logo {
	display: inline-block;
	width: 110px;
	height: 110px;
	position: relative;
	overflow: hidden;
	border-radius: 50%;
	border: 7px solid #dadfe4;
	align-items: center;
	justify-content: center;
	margin-top: 8px;
}

.single-medical li img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	margin: 0 auto;
	background-color: #ffffff;
}

.single-medical li p {
	font-size: 14px;
}

.single-medical li .single-branch {
	width: 100%;
	/* border: 1px solid #ddd; */
	padding: 6px 4px;
	text-align: center;
	margin-top: 10px;
	color: #4a4a4a;
}

.single-medical li .single-branch .branch-name {
	/* text-transform: uppercase; */
	font-size: 14px;
	color: #4d4d4d;
	margin-bottom: 0px;
	overflow: hidden;
	text-overflow: ellipsis;
}

.single-medical li .single-branch .branch-location {
	font-size: 10px;
	line-height: 18px;
	margin-bottom: 0px;
	text-transform: capitalize;
}

.single-medical li .single-branch .button {
	background: #fff;
	border: 1px solid #0196FD;
	color: #0196FD;
	padding: 4px 8px;
	/* text-transform: uppercase; */
	border-radius: 6px;
	margin: 15px 2px 0px;
	font-size: 12px;
	/* width: auto; */
	min-width: 100%;
	display: inline-block;
	text-align: center;
}

.single-medical li .single-branch .button:hover {
	background: #0196FD;
	color: #fff;
}

.emptystate {
	min-height: 75vh;
	width: 100%;
	height: 100%;
	display: grid;
	justify-content: center;
	align-items: center
}

.emptystate .emptystate1 {
	width: auto;
	height: auto;
	display: grid;
	justify-items: center;
	align-items: center
}

.emptystate .emptystate1 img {
	width: 60px;
	padding-bottom: 24px;
	height: auto;
	align-self: end;
	position: relative;
}

.emptystate .emptystate1 .textMesg {
	align-self: baseline;
	/* top: 21px; */
	height: auto;
	position: relative;
	text-align: center;
	font-size: 30px;
	color: #4a4a4a;
	padding: 10px;
}

.emptystate .emptystate1 .detailMesg {
	align-self: baseline;
	height: auto;
	position: relative;
	text-align: center;
	font-size: 16px;
	color: #4a4a4a;
	padding: 10px;
}

.emptystate .emptystate1 .addfacbutton {
	align-self: baseline;
	width: 200px;
	border-radius: 4px;
	color: #3598DB;
	border: 1px solid #3598DB;
	height: auto;
	position: relative;
	text-align: center;
	font-family: 'tfBold';
	padding: 8px;
}

.ReactTable {
	border: initial;
	width: 100%;
	height: 100vh;
}

.ReactTable .scrollbar-container {
	margin: initial;
	padding: initial;
}

.ReactTable .rt-thead.-filters input {
	/* border-radius: $border-radius; */
	outline: initial !important;
	box-shadow: initial !important;
	font-size: 0.8rem;
	padding: 0.7rem 0.75rem 0.65rem 0.75rem;
	line-height: 1.5;
	border: 1px solid;
	/* background: $input-background; */
	/* color: $primary-color; */
}

.ReactTable .rt-th, .ReactTable .rt-td {
	padding-top: 20px;
	padding-bottom: 20px;
}

.react-table-fixed-height {
	height: 500px;
}

.ReactTable .rt-thead.-header {
	box-shadow: initial;
}

.ReactTable .rt-thead .rt-th, .ReactTable .rt-thead .rt-td {
	padding-top: 10px;
	padding-bottom: 10px;
	box-shadow: initial;
	border: initial;
	text-align: left;
	font-weight: 700;
}

.ReactTable .rt-tbody {
	display: block;
}

.ReactTable .list-item-heading {
	margin-bottom: 0;
}

.ReactTable .rt-tbody .rt-td {
	border-right: initial;
}

.ReactTable .rt-thead .rt-th.-sort-desc, .ReactTable .rt-thead .rt-td.-sort-desc {
	box-shadow: inset 0 -3px 0 0;
}

.ReactTable .rt-thead .rt-th.-sort-asc, .ReactTable .rt-thead .rt-td.-sort-asc {
	box-shadow: inset 0 3px 0 0;
}

.ReactTable .rt-resizer {
	right: initial;
	left: -18px;
}

.custuomReactTable .rt-table {
	overflow: visible;
}

.custuomReactTable .rt-table .rt-thead.-header {
	padding: 10px 1.5rem !important;
}

.providerfieldText {
	color: #4a4a4a !important;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	margin: 0;
}

.custuomReactTable .rt-table .rt-tbody {
	overflow: auto;
	margin-bottom: 20px;
	height: 90vh;
}

.custuomReactTable .rt-table .rt-tbody::-webkit-scrollbar {
	width: 0;
	background-color: transparent;
}

.custuomReactTable .rt-table .rt-tbody .rt-tr-group {
	padding: 0 1.5rem !important;
	background: white;
	border-radius: .75rem;
	margin: 30px 0;
	box-shadow: 0 1px 15px rgba(0, 0, 0, .04), 0 1px 6px rgba(0, 0, 0, .04);
	border-collapse: initial;
	border-spacing: 0 .6rem;
}

.custuomReactTable .rt-table .rt-tbody .rt-tr-group .rt-tr.-odd {
	align-items: center;
}

.custuomReactTable .rt-table .rt-tbody .rt-tr-group .rt-tr.-even {
	align-items: center;
}

.custuomReactTable .rt-table .rt-tbody .rt-tr-group .rt-tr.-odd .rt-td {
	overflow: visible;
}

.custuomReactTable .rt-table .rt-tbody .rt-tr-group .rt-tr.-even .rt-td {
	overflow: visible;
}

.ReactTable .-pagination .-btn {
	background: #3498db;
	color: white;
}

.facilityInfo {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 10px 0px;
}

.facilityInfo .cardHeader {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	background: white;
	border-radius: .75rem;
	margin: 20px;
	box-shadow: 0 1px 15px rgba(0, 0, 0, .04), 0 1px 6px rgba(0, 0, 0, .04);
	border-collapse: initial;
	border-spacing: 0 .6rem;
	padding: 2rem .5rem;
}

.facilityInfo .cardHeader .cardImage {
	color: #3498db;
	font-size: 39px;
}

.facilityInfo .cardHeader .cardTitle {
	color: #8f8f8f;
	height: 30px;
	line-height: 26px;
	font-weight: 600;
	font-size: 16px;
	margin: 8px 0 ;
}

.facilityInfo .cardHeader .cardCount {
	color: #3498db;
	font-weight: 300;
	font-size: 48px;
	line-height: 66px;
}

.dashboardCardHeader {
	width: auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	background: white;
	border-radius: .75rem;
	margin: 20px;
	box-shadow: 0 1px 15px rgba(0, 0, 0, .04), 0 1px 6px rgba(0, 0, 0, .04);
	border-collapse: initial;
	border-spacing: 0 .6rem;
	padding: 2rem .5rem;
}

.dashboardCardHeader .cardImage {
	font-size: 80px;
	color: #3498db;
	line-height: 66px;
}

.dashboardCardHeader .cardTitle {
	color: #8f8f8f;
	height: 30px;
	line-height: 26px;
	font-weight: 600;
	font-size: 16px;
	margin: 15px 0;
}

.dashboardCardHeader .cardCount {
	color: #3498db;
	font-size: 24px;
	font-weight: 300;
	line-height: 2rem;
}

.topParent {
	display: flex;
	justify-content: center;
	align-items: flex-start;
}

.topParent .topContainer {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	background: white;
	border-radius: .75rem;
	margin: 20px 7.5px;
	box-shadow: 0 1px 15px rgba(0, 0, 0, .04), 0 1px 6px rgba(0, 0, 0, .04);
	border-collapse: initial;
	border-spacing: 0 .6rem;
	padding: 2rem;
}

.topParent .topContainer .title {
	font-size: 18px;
	font-weight: 700;
	margin-bottom: 14px;
}

.topParent .topContainer .providerItem {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 14px;
	padding-bottom: 14px;
	border-bottom: 1px solid #f3f3f3;
}

.topParent .topContainer .providerItem .infoParent {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.topParent .topContainer .providerItem .infoParent .providerImage {
	min-height: 60px;
	min-width: 60px;
	width: 60px;
	height: 60px;
	border-radius: 200px;
	border: 1px solid lightgrey;
	padding: 2px;
	object-fit: cover;
	margin-right: 20px;
}

.topParent .topContainer .providerItem .infoParent .providerName {
	font-size: 14px;
	line-height: 1.3rem;
	font-weight: 500;
}

.topParent .topContainer .providerItem .providerCount {}

.averageParent {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.averageParent .averageItem {
	width: auto;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	background: white;
	border-radius: .75rem;
	margin: 20px 7.5px;
	box-shadow: 0 1px 15px rgba(0, 0, 0, .04), 0 1px 6px rgba(0, 0, 0, .04);
	border-collapse: initial;
	border-spacing: 0 .6rem;
	padding: 2rem;
}

.averageParent .averageItem .text {
	font-size: 18px;
	margin-right: 20px;
	line-height: 1.5;
}

.averageParent .averageItem .countText {
	font-size: 14px;
	width: 70px;
	height: 70px;
	border-radius: 200px;
	border: 3px solid #3498db;
	padding: 12px;
	object-fit: cover;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
}