.rightside {
  background-color: rgba(255, 255, 255, 0.0);
  height: 100%;
  width: 100%;
  position: relative;
  margin: 0;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
  overflow: hidden;
  border-top: 1px solid #D4D2D2;
}

.rightside .changeday {
  background-color: rgba(255, 255, 255, 0.0);
  top: 0;
  height: 40px;
  position: relative;
  text-align: center;
  border-bottom: 1px solid #DADFE4;
  border-top: 1px solid #DADFE4;
  padding: 10px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
  display: flex;
  justify-content: center;
  align-items: center;
}

.rightside .changeday .searchinput {
  background-color: rgba(255, 255, 255, 0.0);
  height: auto;
  width: 60%;
  position: relative;
  text-align: start;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
  color: #4A4A4A;
  font-size: 14px;
  margin-left: 10px;
  border: none
}

.rightside .addnewpatient {
  background-color: rgba(255, 255, 255, 0.0);
  height: fit-content;
  position: relative;
  text-align: center;
  border: 1px solid #3498db;
  margin: 15px 35px;
  padding: 10px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
  border-radius: 5px;
  color: #3498db;
}

.rightside .manage_newrequests {
  background-color: #d7d7d7;
  height: 40px;
  max-height: 40px;
  width: 100%;
  position: relative;
  margin: 0;
  -ms-transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  display: flex;
  justify-content: center;
  align-items: center;
}

.rightside .manage_newrequests .areaselected {
  background-color: rgba(255, 255, 255, 0.0);
  background-color: rgba(255, 255, 255, 0);
  height: 100%;
  width: 100%;
  position: relative;
  margin: 0;
  -ms-transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  font-family: "tfMedium";
  font-size: 13px;
  color: #4a494a;
  text-align: center;
  line-height: 14.0px;
  align-content: center;
  display: grid;
  cursor: pointer;
}

.rightside .onlineoffline {
  height: 50px;
  width: 100%;
  position: relative;
  margin: 0;
  -ms-transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  display: flex;
  justify-content: center;
  align-items: center;
}

.rightside .onlineoffline .selected {
  background-color: rgba(255, 255, 255, 0.0);
  background-color: rgba(255, 255, 255, 0);
  height: auto;
  width: 100%;
  position: relative;
  margin: 5px;
  padding: 6px;
  border: 1px solid #3498db;
  border-radius: 3px;
  -ms-transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  font-family: "SFProDisplay-Light", Helvetica, Arial, serif;
  font-size: 11.62px;
  color: #3498db;
  text-align: center;
  line-height: 14.0px;
  align-content: center;
  display: grid;
  cursor: pointer;
}

.rv-sticky-parent-node .date{
  width: auto;
  position: sticky;
  position: -webkit-sticky;
  -ms-transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  font-family: 'tfMedium';
  font-size: 14px;
  padding: 10px 10px 10px 6px;
  color: #4a4a4a;
  text-align: start;
  line-height: 14.0px;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  top: 0px;
  background: white;
  z-index: 100;
  -webkit-box-shadow: 0px 2px 5px 0px hsla(0,0%,89%,1);
  -moz-box-shadow: 0px 2px 5px 0px hsla(0,0%,89%,1);
  box-shadow: 0px 2px 5px 0px hsla(0,0%,89%,1);
  
}

.patientsInfoParent .date .datefixed {
  height: auto;
  width: auto;
  -ms-transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  font-family: "tfMedium";
  font-size: 14px;
  margin: 0 6px;
  margin-left: 0;
  color: #4A4A4A;
  text-align: start;
  line-height: 14.0px;
  cursor: pointer;
}
.rmdp-container {
  width: 100%;
}