.addnewpatientpopup {
	background-color: rgba(0, 0, 0, 0.8);
	position: fixed;
	width: 100%;
	height: 100%;
	z-index: 100;
	overflow: auto;
	display: flex;
	justify-content: center;
	/* align-items: center; */
}
.addnewpatientpopup .ui.input>input{
	color: inherit !important;
}
.addnewpatientpopup::-webkit-scrollbar {
	width: 0;
	background: transparent
}

.addnewpatientpopup .popup {
	background-color: white;
	width: auto;
	position: absolute;
	/* top: 60px; */
	min-width: 670px;
	/* min-height: 600px; */
	height: auto;
	margin: 40px 0;
	-ms-transform: rotate(0deg);
	/* IE 9 */
	-webkit-transform: rotate(0deg);
	/* Chrome, Safari, Opera */
	transform: rotate(0deg);
	/* overflow: auto;
	max-height: 95%; */
	box-shadow: 0px 0px 14px #4A4A4A;
	border-radius: 4px;
	justify-content: center;
	display: block;
}

@media (max-width: 800px) {
	.addnewpatientpopup .popup {
		width: 100%;
		height: auto;
		margin: auto;
		min-width: auto;
	}
}

.addnewpatientpopup .popup .submitButtons {
	background-color: rgba(255, 255, 255, 0.0);
	height: auto;
	/* width: 100%; */
	position: relative;
	margin: 12px 30px;
	-ms-transform: rotate(0deg);
	/* IE 9 */
	-webkit-transform: rotate(0deg);
	/* Chrome, Safari, Opera */
	transform: rotate(0deg);
	display: flex;
	/* justify-content: space-between; */
	align-self: flex-end;
	/* padding-bottom: 20px; */
	justify-self: end;
}

.addnewpatientpopup .popup .submitButtons .next {
	height: auto;
	min-width: 80px;
	width: auto;
	position: relative;
	margin: 0;
	padding: 12px;
	border-radius: 4.5px;
	text-align: center;
	margin: 3px;
	-ms-transform: rotate(0deg);
	/* IE 9 */
	-webkit-transform: rotate(0deg);
	/* Chrome, Safari, Opera */
	transform: rotate(0deg);
	font-size: 14px;
	/* cursor: pointer; */
}

.addnewpatientpopup .popup .submitButtons .cancel {
	height: auto;
	min-width: 80px;
	width: auto;
	position: relative;
	margin: 0;
	border-radius: 4.5px;
	text-align: center;
	margin: 3px;
	padding: 12px;
	-ms-transform: rotate(0deg);
	/* IE 9 */
	-webkit-transform: rotate(0deg);
	/* Chrome, Safari, Opera */
	transform: rotate(0deg);
	font-size: 14px;
}

.addnewpatientpopup .popup .addpatientcopy {
	background-color: rgba(255, 255, 255, 0.0);
	height: auto;
	width: auto;
	position: relative;
	margin: 16px;
	text-align: center;
	-ms-transform: rotate(0deg);
	/* IE 9 */
	-webkit-transform: rotate(0deg);
	/* Chrome, Safari, Opera */
	transform: rotate(0deg);
	font-size: 22.0px;
	color: rgba(74, 73, 74, 1.0);
	line-height: 26.0px;
	display: grid;
	align-content: center;
}

.addnewpatientpopup .popup .addpatientcopy .blueLineContainer {
	display: flex;
	justify-content: center;
	padding: 10px;
}

.addnewpatientpopup .popup .addpatientcopy .blueLineContainer .blueLine {
	background: rgb(53, 152, 219);
	width: 91.8px;
	height: 6.3px;
	border-radius: 3px;
}

.addnewpatientpopup .popup .stackedgroup4 {
	display: flex;
	justify-content: center;
	flex-direction: column;
	/* align-items: flex-start; */
	background-color: rgba(255, 255, 255, 0.0);
	height: auto;
	width: calc(100% - 40px);
	position: relative;
	margin: 0 20px;
	-ms-transform: rotate(0deg);
	/* IE 9 */
	-webkit-transform: rotate(0deg);
	/* Chrome, Safari, Opera */
	transform: rotate(0deg);
	font-size: 12.0px;
	color: rgba(155, 154, 155, 1.0);
	text-align: justify;
	line-height: 14.0px;
}

.addnewpatientpopup .popup .stackedgroup4 .stackedgroup41 {
	display: flex;
	justify-content: center;
	flex-direction: column;
	/* align-items: flex-start; */
	background-color: rgba(255, 255, 255, 0.0);
	position: relative;
	height: auto;
	margin: 0 20px;
	/* width: calc(100% - 40px); */
	-ms-transform: rotate(0deg);
	/* IE 9 */
	-webkit-transform: rotate(0deg);
	/* Chrome, Safari, Opera */
	transform: rotate(0deg);
}

.addnewpatientpopup .popup .stackedgroup4 .stackedgroup41 .stackedgroup42 {
	display: flex;
	justify-content: flex-start;
	flex-direction: row;
	align-items: center;
	background-color: rgba(255, 255, 255, 0.0);
	/* position: relative; */
	/* height: 52px; */
	margin: 0;
	width: 100%;
	-ms-transform: rotate(0deg);
	/* IE 9 */
	-webkit-transform: rotate(0deg);
	/* Chrome, Safari, Opera */
	transform: rotate(0deg);
}

@media (max-width: 500px) {
	.addnewpatientpopup .popup .stackedgroup4 .stackedgroup41 .stackedgroup42 {
		display: block;
	}
}

.addnewpatientpopup .popup .stackedgroup4 .stackedgroup41 .stackedgroup42 .inputfieldscopy11 {
	background-color: rgba(255, 255, 255, 0.0);
	position: relative;
	/* height              : 52px; */
	margin: 10px;
	width: 50%;
	-ms-transform: rotate(0deg);
	/* IE 9 */
	-webkit-transform: rotate(0deg);
	/* Chrome, Safari, Opera */
	transform: rotate(0deg);
}

@media (max-width: 500px) {
	.addnewpatientpopup .popup .stackedgroup4 .stackedgroup41 .stackedgroup42 .inputfieldscopy11 {
		width: 96%;
		margin-top: 14px;
	}
}

.addnewpatientpopup .popup .stackedgroup4 .stackedgroup41 .stackedgroup42 .inputfieldscopy11 .inputfieldmandatory {
	height: 32px;
	width: 100%;
	position: relative;
	top: 6px;
	margin: 0;
	left: 0px;
	-ms-transform: rotate(0deg);
	/* IE 9 */
	-webkit-transform: rotate(0deg);
	/* Chrome, Safari, Opera */
	transform: rotate(0deg);
	text-transform: capitalize;
}

.addnewpatientpopup .popup .stackedgroup4 .stackedgroup41 .stackedgroup42 .inputfieldscopy11 .DatePicker {
	height: 32px;
	width: 94%;
	position: relative;
	top: 6px;
	margin: 0;
	border: 1px solid rgba(34, 36, 38, .15);
	left: 0px;
	-ms-transform: rotate(0deg);
	/* IE 9 */
	-webkit-transform: rotate(0deg);
	/* Chrome, Safari, Opera */
	transform: rotate(0deg);
}

.addnewpatientpopup .popup .stackedgroup4 .stackedgroup41 .stackedgroup42 .inputfieldscopy11 .calenderview {
	height: 202px;
	width: 100%;
	position: relative;
	border-radius: 3px;
	border: 1px solid #EDEDED;
	top: 6px;
	padding-left: 10px;
	margin: 0;
	left: 0px;
	z-index: 100;
	-ms-transform: rotate(0deg);
	/* IE 9 */
	-webkit-transform: rotate(0deg);
	/* Chrome, Safari, Opera */
	transform: rotate(0deg);
}

.addnewpatientpopup .popup .stackedgroup4 .stackedgroup41 .stackedgroup42 .inputfieldscopy11 .patientname {
	background-color: rgba(255, 255, 255, 0.0);
	top: 0px;
	height: auto;
	width: auto;
	position: relative;
	margin: 0;
	left: 0px;
	-ms-transform: rotate(0deg);
	/* IE 9 */
	-webkit-transform: rotate(0deg);
	/* Chrome, Safari, Opera */
	transform: rotate(0deg);
	font-size: 13.0px;
	color: rgba(155, 154, 155, 1.0);
	text-align: justify;
	line-height: 14.0px;
}

.addnewpatientpopup .popup .stackedgroup4 .stackedgroup41 .stackedgroup42 .inputfieldscopy10 {
	background-color: rgba(255, 255, 255, 0.0);
	position: relative;
	height: 52px;
	margin: 0 0 0 30px;
	width: 50%;
	-ms-transform: rotate(0deg);
	/* IE 9 */
	-webkit-transform: rotate(0deg);
	/* Chrome, Safari, Opera */
	transform: rotate(0deg);
}

.addnewpatientpopup .popup .stackedgroup4 .stackedgroup41 .stackedgroup42 .inputfieldscopy10 .inputfieldmandatory {
	background-color: rgba(255, 255, 255, 0.0);
	height: 32px;
	width: 100%;
	position: relative;
	border-radius: 3px;
	border: 1px solid #EDEDED;
	top: 6px;
	padding-left: 10px;
	margin: 0;
	left: 0px;
	-ms-transform: rotate(0deg);
	/* IE 9 */
	-webkit-transform: rotate(0deg);
	/* Chrome, Safari, Opera */
	transform: rotate(0deg);
}

.addnewpatientpopup .popup .stackedgroup4 .stackedgroup41 .stackedgroup42 .inputfieldscopy10 .patientname {
	background-color: rgba(255, 255, 255, 0.0);
	top: 0px;
	height: auto;
	width: auto;
	position: relative;
	margin: 0;
	left: 0px;
	-ms-transform: rotate(0deg);
	/* IE 9 */
	-webkit-transform: rotate(0deg);
	/* Chrome, Safari, Opera */
	transform: rotate(0deg);
	font-size: 13.0px;
	color: rgba(155, 154, 155, 1.0);
	text-align: justify;
	line-height: 14.0px;
}
.addnewpatientpopup .ui.input>input{
	text-transform: capitalize;
}
.addnewpatientpopup .ui.selection.dropdown .menu>.item{
	text-transform: capitalize;
}

.addnewpatientpopup .popup .stackedgroup4 .stackedgroup55 {
	display: flex;
	justify-content: flex-start;
	flex-direction: row;
	/* align-items: center; */
	/* display: flex;
	justify-content: space-between;
	flex-direction: row;
	align-items: center; */
	background-color: rgba(255, 255, 255, 0.0);
	position: relative;
	/* height              : 52px; */
	margin: 0 20px;
	/* padding-top: 10px; */
	/* width: 100%; */
	-ms-transform: rotate(0deg);
	/* IE 9 */
	-webkit-transform: rotate(0deg);
	/* Chrome, Safari, Opera */
	transform: rotate(0deg);
	/* z-index: 100; */
}

@media (max-width: 500px) {
	.addnewpatientpopup .popup .stackedgroup4 .stackedgroup55 {
		display: block;
	}
}

.addnewpatientpopup .popup .stackedgroup4 .stackedgroup55 .inputfieldscopy11 {
	background-color: rgba(255, 255, 255, 0.0);
	position: relative;
	/* height              : 52px; */
	margin: 10px;
	width: 50%;
	-ms-transform: rotate(0deg);
	/* IE 9 */
	-webkit-transform: rotate(0deg);
	/* Chrome, Safari, Opera */
	transform: rotate(0deg);
}

@media (max-width: 500px) {
	.addnewpatientpopup .popup .stackedgroup4 .stackedgroup55 .inputfieldscopy11 {
		width: 96%;
		margin-top: 14px;
	}
}

.addnewpatientpopup .popup .stackedgroup4 .stackedgroup55 .inputfieldscopy11 .inputfieldmandatory {
	height: 32px;
	width: 100%;
	position: relative;
	top: 6px;
	margin: 0;
	left: 0px;
	-ms-transform: rotate(0deg);
	/* IE 9 */
	-webkit-transform: rotate(0deg);
	/* Chrome, Safari, Opera */
	transform: rotate(0deg);
}

.addnewpatientpopup .popup .stackedgroup4 .stackedgroup55 .inputfieldscopy11 .patientname {
	background-color: rgba(255, 255, 255, 0.0);
	top: 0px;
	height: auto;
	width: auto;
	position: relative;
	margin: 0;
	left: 0px;
	-ms-transform: rotate(0deg);
	/* IE 9 */
	-webkit-transform: rotate(0deg);
	/* Chrome, Safari, Opera */
	transform: rotate(0deg);
	font-size: 13.0px;
	color: rgba(155, 154, 155, 1.0);
	text-align: justify;
	line-height: 14.0px;
}

.addnewpatientpopup .popup .stackedgroup4 .stackedgroup55 .inputfieldscopy11 .calenderview {
	background: white;
	padding: 10px;
	height: 200px;
	width: 100%;
	position: absolute;
	bottom: 30px;
	margin: 0;
	left: 0px;
	z-index: 100;
	-ms-transform: rotate(0deg);
	/* IE 9 */
	-webkit-transform: rotate(0deg);
	/* Chrome, Safari, Opera */
	transform: rotate(0deg);
	display: flex;
	justify-content: center;
	box-shadow: 0px 0px 14px rgba(155, 154, 155, 1.0);
	border-radius: 4px;
	overflow: hidden;
}

.addnewpatientpopup .popup .stackedgroup4 .stackedgroup55 .inputfieldscopy11 .calenderview .calendersection {
	background-color: white;
	height: 100%;
	width: 100%;
	position: relative;
	z-index: 100;
}

.addnewpatientpopup .popup .stackedgroup4 .stackedgroup55 .inputfieldscopy11 .calenderview .calendersection .title {
	height: auto;
	width: 100%;
	padding: 10px;
	position: relative;
	text-align: center;
	border-bottom: .5px solid #9b9a9b87;
}

.addnewpatientpopup .popup .stackedgroup4 .stackedgroup55 .inputfieldscopy11 .calenderview .calendersection .picker {
	height: 84%;
	width: 100%;
	position: relative;
	text-align: start;
	overflow: auto;
	text-align: center;
}

.addnewpatientpopup .popup .stackedgroup4 .stackedgroup55 .inputfieldscopy11 .calenderview .calendersection .picker::-webkit-scrollbar {
	width: 0px;
	/* remove scrollbar space */
	background: transparent;
	/* optional: just make scrollbar invisible */
}

.addnewpatientpopup .popup .stackedgroup4 .stackedgroup55 .inputfieldscopy11 .calenderview .calendersection .picker::-webkit-scrollbar-thumb {
	background: #FF0000;
}

.addnewpatientpopup .popup .stackedgroup4 .stackedgroup41 .stackedgroup51 {
	display: flex;
	justify-content: flex-start;
	flex-direction: row;
	align-items: center;
	background-color: rgba(255, 255, 255, 0.0);
	position: relative;
	height: 52px;
	margin: 15px 0 0 0;
	width: 100%;
	-ms-transform: rotate(0deg);
	/* IE 9 */
	-webkit-transform: rotate(0deg);
	/* Chrome, Safari, Opera */
	transform: rotate(0deg);
}

.addnewpatientpopup .popup .stackedgroup4 .stackedgroup41 .stackedgroup51 .inputfieldscopy19 {
	background-color: rgba(255, 255, 255, 0.0);
	position: relative;
	height: 52px;
	margin: 0 0 0 30px;
	width: 50%;
	-ms-transform: rotate(0deg);
	/* IE 9 */
	-webkit-transform: rotate(0deg);
	/* Chrome, Safari, Opera */
	transform: rotate(0deg);
}

.addnewpatientpopup .popup .stackedgroup4 .stackedgroup41 .stackedgroup51 .inputfieldscopy19 .inputfieldmandatory {
	background-color: rgba(255, 255, 255, 0.0);
	height: 32px;
	width: 100%;
	position: relative;
	border-radius: 3px;
	border: 1px solid #EDEDED;
	top: 6px;
	padding-left: 10px;
	margin: 0;
	left: 0px;
	-ms-transform: rotate(0deg);
	/* IE 9 */
	-webkit-transform: rotate(0deg);
	/* Chrome, Safari, Opera */
	transform: rotate(0deg);
}

.addnewpatientpopup .popup .stackedgroup4 .stackedgroup41 .stackedgroup51 .inputfieldscopy19 .patientname {
	background-color: rgba(255, 255, 255, 0.0);
	top: 0px;
	height: auto;
	width: auto;
	position: relative;
	margin: 0;
	left: 0px;
	-ms-transform: rotate(0deg);
	/* IE 9 */
	-webkit-transform: rotate(0deg);
	/* Chrome, Safari, Opera */
	transform: rotate(0deg);
	font-size: 13.0px;
	color: rgba(155, 154, 155, 1.0);
	text-align: justify;
	line-height: 14.0px;
}

.addnewpatientpopup .popup .stackedgroup4 .stackedgroup5 {
	display: flex;
	flex-direction: row;
	align-items: center;
	background-color: rgba(255, 255, 255, 0.0);
	position: relative;
	height: 52px;
	width: 100%;
	-ms-transform: rotate(0deg);
	/* IE 9 */
	-webkit-transform: rotate(0deg);
	/* Chrome, Safari, Opera */
	transform: rotate(0deg);
}

.addnewpatientpopup .popup .stackedgroup4 .stackedgroup7 {
	display: flex;
	flex-direction: row;
	align-items: center;
	background-color: rgba(255, 255, 255, 0.0);
	position: relative;
	height: 52px;
	width: 100%;
	-ms-transform: rotate(0deg);
	/* IE 9 */
	-webkit-transform: rotate(0deg);
	/* Chrome, Safari, Opera */
	transform: rotate(0deg);
}

.addnewpatientpopup .popup .stackedgroup4 .inputfieldscopy14 {
	background-color: rgba(255, 255, 255, 0.0);
	position: relative;
	height: auto;
	width: 47%;
	margin: 16px 28px;
	-ms-transform: rotate(0deg);
	/* IE 9 */
	-webkit-transform: rotate(0deg);
	/* Chrome, Safari, Opera */
	transform: rotate(0deg);
	z-index: 100;
}

@media (max-width: 500px) {
	.addnewpatientpopup .popup .stackedgroup4 .inputfieldscopy14 {
		width: 93%;
	}
}

.addnewpatientpopup .popup .stackedgroup4 .inputfieldscopy14 .inputfieldmandatory {
	height: 32px;
	width: 95%;
	position: relative;
	margin: 0;
	left: 0px;
	-ms-transform: rotate(0deg);
	/* IE 9 */
	-webkit-transform: rotate(0deg);
	/* Chrome, Safari, Opera */
	transform: rotate(0deg);
	top: 5px;
}

.addnewpatientpopup .popup .stackedgroup4 .inputfieldscopy14 .patientname {
	background-color: rgba(255, 255, 255, 0.0);
	top: 0px;
	height: auto;
	width: auto;
	position: relative;
	margin: 0;
	left: 0px;
	-ms-transform: rotate(0deg);
	/* IE 9 */
	-webkit-transform: rotate(0deg);
	/* Chrome, Safari, Opera */
	transform: rotate(0deg);
	font-size: 13.0px;
	color: rgba(155, 154, 155, 1.0);
	text-align: justify;
	line-height: 14.0px;
}

.addnewpatientpopup .popup .stackedgroup4 .stackedgroup6 {
	display: flex;
	justify-content: flex-start;
	flex-direction: row;
	align-items: flex-start;
	background-color: rgba(255, 255, 255, 0.0);
	position: relative;
	height: 98px;
	margin: 30px 0 0 0;
	width: auto;
	-ms-transform: rotate(0deg);
	/* IE 9 */
	-webkit-transform: rotate(0deg);
	/* Chrome, Safari, Opera */
	transform: rotate(0deg);
}

.addnewpatientpopup .popup .stackedgroup4 .stackedgroup6 .inputfieldscopy15 {
	background-color: rgba(255, 255, 255, 0.0);
	position: relative;
	height: 98px;
	margin: 0;
	width: 280px;
	-ms-transform: rotate(0deg);
	/* IE 9 */
	-webkit-transform: rotate(0deg);
	/* Chrome, Safari, Opera */
	transform: rotate(0deg);
}

.addnewpatientpopup .popup .stackedgroup4 .stackedgroup6 .inputfieldscopy15 .rectanglecopy2 {
	background-color: rgba(255, 255, 255, 1.0);
	top: 6px;
	height: 76px;
	width: 100%;
	position: relative;
	margin: 0;
	left: 0px;
	border-radius: 3px;
	border: 1px solid rgba(237, 237, 237, 1.0);
	-ms-transform: rotate(0deg);
	/* IE 9 */
	-webkit-transform: rotate(0deg);
	/* Chrome, Safari, Opera */
	transform: rotate(0deg);
}

.addnewpatientpopup .popup .stackedgroup4 .stackedgroup6 .inputfieldscopy15 .patientname {
	background-color: rgba(255, 255, 255, 0.0);
	top: 0px;
	height: auto;
	width: auto;
	position: relative;
	margin: 0;
	left: 0px;
	-ms-transform: rotate(0deg);
	/* IE 9 */
	-webkit-transform: rotate(0deg);
	/* Chrome, Safari, Opera */
	transform: rotate(0deg);
	font-size: 13.0px;
	color: rgba(155, 154, 155, 1.0);
	text-align: justify;
	line-height: 14.0px;
}

.addnewpatientpopup .popup .stackedgroup4 .stackedgroup6 .inputfieldscopy15 .group {
	background-color: rgba(255, 255, 255, 0.0);
	top: 36px;
	height: 24px;
	width: 100px;
	position: absolute;
	margin: 0;
	left: 12px;
	border-radius: 4px;
	border-style: solid;
	border-width: 1px;
	border-color: rgba(150, 150, 150, 1.0);
	-ms-transform: rotate(0deg);
	/* IE 9 */
	-webkit-transform: rotate(0deg);
	/* Chrome, Safari, Opera */
	transform: rotate(0deg);
	display: flex;
	align-items: center;
}

.addnewpatientpopup .popup .stackedgroup4 .stackedgroup6 .inputfieldscopy15 .group .teethwhitening {
	background-color: rgba(255, 255, 255, 0.0);
	height: auto;
	width: auto;
	position: relative;
	margin: 0;
	left: 7px;
	-ms-transform: rotate(0deg);
	/* IE 9 */
	-webkit-transform: rotate(0deg);
	/* Chrome, Safari, Opera */
	transform: rotate(0deg);
	font-size: 10.0px;
	color: rgba(73, 143, 225, 1.0);
	text-align: justify;
	line-height: 12.0px;
}

.addnewpatientpopup .popup .stackedgroup4 .stackedgroup6 .inputfieldscopy15 .group .group2 {
	background-color: rgba(255, 255, 255, 0);
	height: auto;
	width: auto;
	position: absolute;
	margin: 0;
	right: 5px;
	-ms-transform: rotate(0deg);
	-webkit-transform: rotate(0deg);
	transform: rotate(0deg);
}

.addnewpatientpopup .popup .stackedgroup4 .stackedgroup55 .inputfieldscopy16 {
	background-color: rgba(255, 255, 255, 0.0);
	position: relative;
	height: 52px;
	width: 50%;
	margin: 10px;
	-ms-transform: rotate(0deg);
	/* IE 9 */
	-webkit-transform: rotate(0deg);
	/* Chrome, Safari, Opera */
	transform: rotate(0deg);
}

.addnewpatientpopup .popup .stackedgroup4 .stackedgroup55 .inputfieldscopy16 .inputfieldmandatory {
	background-color: rgba(255, 255, 255, 0.0);
	height: 32px;
	width: 100%;
	position: relative;
	margin: 0;
	border-radius: 3px;
	/* border: 1px solid #EDEDED; */
	top: 6px;
	/* padding-left: 10px; */
	left: 0px;
	-ms-transform: rotate(0deg);
	/* IE 9 */
	-webkit-transform: rotate(0deg);
	/* Chrome, Safari, Opera */
	transform: rotate(0deg);
}

.addnewpatientpopup .popup .stackedgroup4 .stackedgroup55 .inputfieldscopy16 .patientname {
	background-color: rgba(255, 255, 255, 0.0);
	top: 0px;
	height: auto;
	width: auto;
	position: relative;
	margin: 0;
	left: 0px;
	-ms-transform: rotate(0deg);
	/* IE 9 */
	-webkit-transform: rotate(0deg);
	/* Chrome, Safari, Opera */
	transform: rotate(0deg);
	font-size: 13.0px;
	color: rgba(155, 154, 155, 1.0);
	text-align: justify;
	line-height: 14.0px;
}

.addnewpatientpopup .popup .activityLog {
	margin: 20px;
}

.addnewpatientpopup .popup .activityLog .title {
	margin-left: 20px;
	color: rgba(74, 74, 74, 1);
	font-family: 'tfBold';
	font-size: 14px;
}

.addnewpatientpopup .popup .activityLog .content {
	/* max-height: 100px; */
	overflow: auto;
}

.addnewpatientpopup .popup .activityLog .content .logRecord {
	margin: 20px;
	color: rgba(74, 74, 74, 1);
	font-size: 12px;
}

.addnewpatientpopup .popup .activityLog .content .logRecord .recordDetail .bolded {
	font-family: 'tfBold'
}

.addnewpatientpopup .popup .activityLog .content .logRecord .timeStamp {
	color: rgba(53, 152, 219, 1);
	font-size: 10px;
}

.addnewpatientpopup .popup .activityLog .content .separator {
	width: 100%;
	height: 1px;
	background-color: rgba(238, 238, 238, 1)
}