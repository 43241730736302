.helpersPopup{
    background-color: white;
    border-radius: 8px;
    color: black;
    min-width: 600px;
    max-height: 90vh;
    overflow: auto;
}
.helpersPopup .helperTitle{
    padding: 16px;
    display: flex;
    justify-content: center;
    font-size: 24px;
}

.helpersPopup .contents{
    padding: 16px;
    text-align: justify;
}
.helpersPopup .contents .cell{
    padding: 4px;
    width: 100%;
}

.helpersPopup .contents .cell .cellTitle{
    padding: 8px 0;
}
.helpersPopup .actionBtn{
    display: flex;
    justify-content: flex-end;
    padding: 16px;
}
.rightSideMenu .tableHeadersHelpers{
    font-size: 12px;
    color: rgba(155, 155, 155, 1);
}
.rightSideMenu .tableHeadersHelpers ,.rightSideMenu .tableCellsHelpers{
    width: 30%;
}
.rightSideMenu .tableActionBtn{
    /* width: 20%; */
    display: flex;
    justify-content: flex-end;
}
.rightSideMenu .tableActionBtn .atnBtn{
    margin: 0 4px;

}