.leftSideMenu{
    width: 20%;
    height: 100%;
    background: rgb(233, 235, 243);
}
.rightSideMenu{
    width: 80%;
    height: 100%;
    background: white;
}
.leftSideMenu .mainHeaderContainer{
    width: 100%;
    height: 70px;
    display: flex;
    align-items: center;
}
.leftSideMenu .mainHeaderContainer .mainHeaderTitle{
    color: rgb(74, 74, 74);
    font-size: 17px;
    padding: 10px;
    display: grid;
    align-content: center;
    /* line-height: 0px; */
}
.leftSideMenu .menuSection .menuItem{
    font-size: 14px;
    height: 40px;
    color: rgb(74, 74, 74);
    padding: 10px;
    border-bottom: 1px solid lightgrey;
    background: rgb(233, 235, 243);
    cursor: pointer;
    display: grid;
    align-content: center;
    line-height: 0px;


}
.leftSideMenu .menuSection .selected{
    font-size: 14px;
    height: 40px;
    color: rgb(53, 152, 219);
    padding: 10px;
    border-left: 2px solid rgb(53, 152, 219);
    border-bottom: 1px solid lightgrey;
    background: white;
    cursor: pointer;
    display: grid;
    align-content: center;
    line-height: 0px;


}
.leftSideMenu .menuSection .menuItem:hover{
    background: white;
}

.rightSideMenu .selectedHeaderContainer{
    font-size: 24px;
    color: rgba(74, 74, 74, 1);
    width: 100%;
    height: 70px;
    border-bottom: 1px solid lightgray;
    align-items: center;
    display: flex;
    padding-left: 24px;
}
.rightSideMenu .selectedHeaderContainer .headerButtonContainer{
    justify-content: flex-end;
    width: 45%;
    display: flex;
}
.rightSideMenu .content{
    height: calc( 100% - 70px );
    padding: 26px;
    overflow: auto;
}

.rightSideMenu .content .tableHeaders{
    font-size: 12px;
    color: rgba(155, 155, 155, 1);
}
.rightSideMenu .content .tableCells{
    font-size: 14px;
    color: rgba(74, 74, 74, 1);
}
/* .rightSideMenu .content .ui.basic.button{
   color: rgba(53, 152, 219, 1)!important;
   -webkit-box-shadow: none !important;
    box-shadow: none !important;
   font-size: 12px !important;
}
.rightSideMenu .content .ui.basic.button:hover{
    -webkit-box-shadow:none !important;
     box-shadow: none !important;
 } */
.rightSideMenu .selectedHeaderContainer.headerButtonContainer .ui.basic.blue.button, .ui.basic.blue.buttons .button{
    -webkit-box-shadow: 0 0 0 1.2px #2185d0 inset!important;
    box-shadow: inset 0 0 0 1.2px #2185d0!important;
}
.rightSideMenu .selectedHeaderContainer .headerButtonContainer .ui.basic.blue.button, .ui.basic.blue.buttons .button{
    -webkit-box-shadow: 0 0 0 1.2px #2185d0 inset!important;
    box-shadow: inset 0 0 0 1.2px #2185d0!important;
}