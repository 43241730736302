.profileMainContainer{
    height: auto;
    width: 412px;
    background: white;
    border-radius: 8px;
    color: black;
}

.profileMainContainer .popupTitle{
    font-size: 22px;
    font-weight: bold;
    color: rgba(74, 74, 74, 1);
    padding: 24px;
    text-align: center;
}
.profileMainContainer .popupTitle .blueLineContainer{
    display: flex;
    justify-content: center;
    padding: 10px;
}
.profileMainContainer .popupTitle .blueLineContainer .blueLine{
    background: rgb(53, 152, 219);
    width: 91.8px;
    height: 6.3px;
    border-radius: 3px;
}
.profileMainContainer .popupImg img{
    margin-top: 20px;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin-bottom: 12px;
    border: 1px solid rgba(151, 151, 151, 1);
    object-fit: cover;
}
.profileMainContainer .content{
    justify-content: center;
    width: 100%;
    display: flex;
    padding-bottom: 16px;   
}
.profileMainContainer .content .row{
    display: flex;
    justify-content: space-between;
    width: 90%;
}

.profileMainContainer .content .row .cell{
    width: 100%;
}
.profileMainContainer .content .row .cell .btns{
    font-size: 12px;
    color: rgba(53, 152, 219, 1);
    cursor: pointer;
}
.profileMainContainer .content .row .cell .title{
    text-align: left;
    font-size: 12px;
    color: rgba(155, 155, 155, 1);
}
.profileMainContainer .content .row .cell .input{
    padding-top: 6px;
    width: 100%;
    font-size: 14px;
    color: rgba(74, 74, 74, 1);
}

.ui.dimmer .ui.loader:after{
    border-color: #b7b7b7 transparent transparent !important;
}