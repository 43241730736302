.mainContainerthrive{
    width: 418px;
    height: 300px;
    background: white;
    border-radius: 8px;
    color: black;
    position: relative;
    top:-40%;
}
.mainContainerthrive .popupTitle{
    font-size: 22px;
    font-weight: bold;
    color: rgba(74, 74, 74, 1);
    padding: 24px;
    text-align: center;
}
.mainContainerthrive .popupTitle .blueLineContainer{
    display: flex;
    justify-content: center;
    padding: 10px;
}
.mainContainerthrive .popupTitle .blueLineContainer .blueLine{
    background: rgb(53, 152, 219);
    width: 91.8px;
    height: 6.3px;
    border-radius: 3px;
}

.mainContainerthrive .content{
    justify-content: center;
    width: 100%;
    display: flex;
    padding-bottom: 16px;
   
}
.mainContainerthrive .content .row{
    display: flex;
    justify-content: space-between;
    width: 70%;
}
.mainContainerthrive .content .row .cell{
    width: 100%;
}
.mainContainerthrive .content .row .cell .title{
    text-align: left;
    font-size: 12px;
    color: rgba(155, 155, 155, 1);
}
.mainContainerthrive .content .row .cell .input{
    padding-top: 6px;
    width: 100%;
    font-size: 14px;
    color: rgba(74, 74, 74, 1);
}
/* .mainContainerthrive .cancelBtn{
    width: 98px;
    margin-left: 8px;
} */
.mainContainerthrive .ui.basic.button{
    width: 98px;
    margin-left: 8px;
    -webkit-box-shadow: 0 0 0 1.2px rgba(34,36,38,.35) inset;
    box-shadow: inset 0 0 0 1.2px rgba(34,36,38,.35);
}
.mainContainerthrive .ui.basic.button:hover{
    width: 98px;
    margin-left: 8px;
    -webkit-box-shadow: 0 0 0 1.2px rgba(34,36,38,.35) inset;
    box-shadow: inset 0 0 0 1.2px rgba(34,36,38,.35);
}