.addOfflineAppointment{ 
    margin-left: 20px;
    font-size: 14px;
    color: #3598db;
    border: 1px solid #3598db;
    text-align: center;
    width: auto;
    padding: 5px 20px;
    border-radius: 6px; 
    cursor: pointer;
}
.addOfflineAppointment:hover{ 
    background-color: #3598db;
    color:white;
}