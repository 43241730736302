.filtercss {
    background-color: rgba(255, 255, 255, 0.0);
    height: auto;
    width: 100%;
    position: relative;
    padding: 0px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 14px;
  }

  .filtercss .selectParent{
    background-color: rgba(255, 255, 255, 0.0);
    height: 100%;
    width: 100%;
    position: relative;
    margin: 20px;
  }


  .react-daterange-picker.react-daterange-picker--enabled{
    height: 38px;
    width: 100%;
    color: hsl(0,0%,80%);
       }
       .react-daterange-picker.react-daterange-picker--open.react-daterange-picker--enabled{
        border:  1px solid #2684FF !important;
        box-shadow: 0 0 0 1px #2684FF;
        border-radius: 4px;
       }
       .react-daterange-picker__wrapper{
         border-radius: 4px;
         border-color: hsl(0,0%,80%) !important;
         cursor: pointer;
       }
       
       .react-daterange-picker__inputGroup{
        display: flex;
        align-items: center;
        justify-content: center;
      }
       
       .react-daterange-picker__inputGroup input{
        color: hsl(0,0%,50%);
        cursor: pointer;
      }
      .react-daterange-picker__inputGroup span{
        color: hsl(0,0%,50%);
        cursor: pointer;
      }
       .react-daterange-picker__inputGroup input:focus {
    outline: none !important;
       }
       .react-daterange-picker__inputGroup__input.react-daterange-picker__inputGroup__year::selection{
    background: none;
       }
       .react-daterange-picker__inputGroup__input:invalid{
         background: none;
       }
       .react-calendar{
        line-height: 3.125em;
       }
       .react-calendar__month-view__weekdays__weekday abbr{
    text-decoration: none;
       }
       .react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__month-view__days__day{
        border-top-left-radius: 28px !important;
        border-bottom-left-radius: 28px !important;
       }
       .react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeEnd.react-calendar__month-view__days__day{
        border-top-right-radius: 28px !important;
        border-bottom-right-radius: 28px !important;
       }
       .ReactTable .rt-tr-group{
          cursor: pointer;
       }
       .react-daterange-picker__inputGroup input {
      
        padding: 0;
    }