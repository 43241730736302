.patientinfo {
    background-color: rgba(255, 255, 255, 0);
    position: relative;
    margin: 8px 10px 10px;
    border-radius: 6px;
    overflow: hidden;
    -ms-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-box-shadow: 0 0px 4px #9B9B9B;
    box-shadow: 0 0px 4px #9B9B9B;
}

.patientinfo .shownoshow {
    background-color: rgba(255, 255, 255, 0.0);
    bottom: 0;
    height: auto;
    min-height: 35px;
    width: 100%;
    position: relative;
    margin: 0;
    -ms-transform: rotate(0deg);
    /* IE 9 */
    -webkit-transform: rotate(0deg);
    /* Chrome, Safari, Opera */
    transform: rotate(0deg);
    display: flex;
    justify-content: center;
    border-top: 1px solid #EAEAEA;
}

.patientinfo .shownoshow .confirmbutton {
    height: 20px;
    width: 100%;
    position: relative;
    left: 0px;
    -ms-transform: rotate(0deg);
    /* IE 9 */
    -webkit-transform: rotate(0deg);
    /* Chrome, Safari, Opera */
    transform: rotate(0deg);
    font-size: 12.0px;
    text-align: center;
    line-height: 14.0px;
    align-self: center;
    display: grid;
    align-content: center;
    color: white;
    cursor: pointer;
}

.patientinfo .status {
    background-color: rgba(255, 255, 255, 0.0);
    top: 0;
    height: auto;
    width: auto;
    position: absolute;
    margin: 0;
    right: 0;
    -ms-transform: rotate(0deg);
    /* IE 9 */
    -webkit-transform: rotate(0deg);
    /* Chrome, Safari, Opera */
    transform: rotate(0deg);
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.patientinfo .status .online {
    height: 100%;
    width: auto;
    min-width: 40px;
    position: relative;
    margin: 0;
    -ms-transform: rotate(0deg);
    /* IE 9 */
    -webkit-transform: rotate(0deg);
    /* Chrome, Safari, Opera */
    transform: rotate(0deg);
    font-size: 8.0px;
    color: rgba(255, 255, 255, 1.0);
    text-align: center;
    display: grid;
    align-content: center;
    background-color: #ca9e67
}
.patientinfo .status .thrive {
    height: 100%;
    width: auto;
    min-width: 40px;
    position: relative;
    margin: 0;
    -ms-transform: rotate(0deg);
    /* IE 9 */
    -webkit-transform: rotate(0deg);
    /* Chrome, Safari, Opera */
    transform: rotate(0deg);
    font-size: 8.0px;
    color: rgba(255, 255, 255, 1.0);
    text-align: center;
    display: grid;
    align-content: center;
    background-image: linear-gradient(to right, #3498db, #0086e0);
}
.patientinfo .status .onlineConsultation {
    height: 100%;
    width: auto;
    min-width: 40px;
    position: relative;
    margin: 0;
    -ms-transform: rotate(0deg);
    /* IE 9 */
    -webkit-transform: rotate(0deg);
    /* Chrome, Safari, Opera */
    transform: rotate(0deg);
    font-size: 10.0px;
    color: rgba(255, 255, 255, 1.0);
    text-align: center;
    display: grid;
    align-content: center;
    background-image: linear-gradient(to right, #3498db, #0086e0);
}
.patientinfo .status .group1 {
    background-image: linear-gradient(to right, #fe685c, #ff5b61, #ff4d68, #fe3f70, #fc2e79);
    height: auto;
    width: auto;
    min-width: 40px;
    position: relative;
    margin: 0;
    -ms-transform: rotate(0deg);
    /* IE 9 */
    -webkit-transform: rotate(0deg);
    /* Chrome, Safari, Opera */
    transform: rotate(0deg);
    font-size: 8.0px;
    color: rgba(255, 255, 255, 1.0);
    text-align: center;
    display: grid;
    align-content: center;
}

.patientinfo .noteandresaonoft {
    background-color: rgba(255, 255, 255, 0.0);
    height: auto;
    width: 100%;
    position: relative;
    margin-top: 6px;
    padding-left: 12px;
    -ms-transform: rotate(0deg);
    /* IE 9 */
    -webkit-transform: rotate(0deg);
    /* Chrome, Safari, Opera */
    transform: rotate(0deg);
    font-size: 10.0px;
    color: #4A4A4A;
    text-align: left;
    display: grid;
    align-content: center;
}

.patientinfo .drsamehsuliman {
    background-color: rgba(255, 255, 255, 0.0);
    height: auto;
    width: 100%;
    position: relative;
    margin-top: 4px;
    margin-bottom: 10px;
    padding-left: 12px;
    -ms-transform: rotate(0deg);
    /* IE 9 */
    -webkit-transform: rotate(0deg);
    /* Chrome, Safari, Opera */
    transform: rotate(0deg);
    font-size: 11.0px;
    color: #4A4A4A;
    text-align: left;
    display: grid;
    align-content: center;
}

.patientinfo .succeededdate {
    background-color: rgba(255, 255, 255, 0.0);
    height: auto;
    width: 100%;
    position: relative;
    padding-left: 12px;
    margin-top: 4px;
    -ms-transform: rotate(0deg);
    /* IE 9 */
    -webkit-transform: rotate(0deg);
    /* Chrome, Safari, Opera */
    transform: rotate(0deg);
    font-size: 10.0px;
    color: #3498DB;
    text-align: start;
    font-weight: 500;
}

.patientinfo .appointment {
    background-color: rgba(255, 255, 255, 0.0);
    height: auto;
    min-height: 30px;
    width: 100%;
    position: relative;
    margin: 0;
    -ms-transform: rotate(0deg);
    /* IE 9 */
    -webkit-transform: rotate(0deg);
    /* Chrome, Safari, Opera */
    transform: rotate(0deg);
    font-size: 12.0px;
    color: rgba(74, 73, 74, 1.0);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    border-top: 1px solid #EAEAEA;
}

.patientinfo .appointment .appointmentdate {
    background-color: rgba(255, 255, 255, 0.0);
    height: auto;
    min-height: 30px;
    width: auto;
    position: relative;
    margin: 0;
    padding-left: 10px;
    -ms-transform: rotate(0deg);
    /* IE 9 */
    -webkit-transform: rotate(0deg);
    /* Chrome, Safari, Opera */
    transform: rotate(0deg);
    font-size: 12.0px;
    color: #3598DB;
    text-align: center;
    display: grid;
    align-content: center;
}

.patientinfo .tarekjamal {
    background-color: rgba(255, 255, 255, 0.0);
    height: auto;
    width: 100%;
    position: relative;
    margin-top: 9px;
    padding-left: 12px;
    -ms-transform: rotate(0deg);
    /* IE 9 */
    -webkit-transform: rotate(0deg);
    /* Chrome, Safari, Opera */
    transform: rotate(0deg);
    font-family: 'tfMedium';
    font-size: 14.0px;
    color: #4A4A4A;
    text-align: left;
    display: grid;
    align-content: center;
    text-transform: capitalize;
}
