
@mixin optional-at-root($sel) {
  @at-root #{if(not &, $sel, selector-append(&, $sel))} {
    @content;
  }
}

@mixin placeholder {
  @include optional-at-root('::-webkit-input-placeholder') {
    @content;
  }

  @include optional-at-root(':-moz-placeholder') {
    @content;
  }

  @include optional-at-root('::-moz-placeholder') {
    @content;
  }

  @include optional-at-root(':-ms-input-placeholder') {
    @content;
  }
}
.flex {

  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.loginInp{
  @extend .flex;
  justify-content: space-between;
  border: 1px solid #cccccb;
  border-radius: 7px;
  height: 59px;
  padding: 23px;
  width: 100%;
  margin: 9px 0 0 0;
  color: rgba(0, 0, 0, 0.60);
  font-size: 15px;
  &:focus{
    outline: 0;
    border-color: rgba(0, 0, 255, 0.21);
    box-shadow: 0 0 15px rgba(0, 0, 255, 0.21);
  }
  @include placeholder {
    color: rgba(0, 0, 0, 0.29);
    font-size: 15px;
    letter-spacing: -0.6pt;
  }
}
.loginLabel{
  color: rgba(0, 0, 0, 0.51);
  font-size: 14px;
  letter-spacing: -0.1pt;
  margin-bottom: 36px;
  display: block;
  &:last-of-type {
    margin-bottom: 31px;
  }
  p{
    font-size: 14px;
    letter-spacing: -0.1pt;
  }
}
.loginLabel + div.captcha {
  div{
    width: 357px;
    height: 92px;
    margin: 0 auto 15px;
    div{
      width: 100% !important;
      height: 100% !important;
      iframe{
        width: 100%;
        height: 100%;
      }
    }
  }
}
.middleBar{
  @extend .flex;
  justify-content: space-between;

  label{
    cursor: pointer;
    display: block;
  }
  input{
    display: none;
  }
  &>div:first-child{
    @extend .flex;
    justify-content: space-between;
    p{
      color: rgba(0, 0, 0, 0.51);
      font-size: 9pt;
      letter-spacing: 0.1pt;
      padding-top: 2px;
    }
    label:first-child{
      -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
      order: 2;
      padding-left: 20px;
    }
    label:last-child{
      -webkit-box-ordinal-group: 2;
      -ms-flex-order: 1;
      order: 1;
      margin-left: 10px;
      position: relative;
    }
  }
  &>div:last-child{
    a{
      color: #000000;
      text-decoration: none;
      font-size: 12px;
      margin-right: 17px;
      // text-transform: uppercase;
      letter-spacing: -0.3pt;
      display: block;
    }
  }

}
.remember{
  div{
    width: 22px;
    height: 22px;
    border: 1px solid #cccccb;
    background-color: #f4f5f9;
  }
}
.loginButton{
  margin: 48px 0 20px 0;
  text-align: center;
  background-color: #0096fd;
  color: #ffffff;
  // text-transform: uppercase;
  padding: 18px 0;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  box-shadow: none;
  border: 0;
  font-size: 16px;
  letter-spacing: 0.6pt;
  &:hover{
    background-color: #007de0;
  }
  &.forgot{
    margin-top: 0;
  }
}
.loginCheck{
  &+ label img{
    opacity: 0;
    position: absolute;
    top: -3px;
    left: 4px;
    width: 22px;
  }
  &:checked + label img{
    opacity: 1;
    width: 22px;
  }
}
.reg.modal{
  .loginButton{
    margin: 10px 0 16px 0;
  }
  .error{
    font-size: 13px;
    color: darkred;
  }
  &.sent {
    /*width: 588px;
    height: 180px;
    min-height: 180px;
    background-image: url(../img/bg_ver_low.png);
    background-repeat: no-repeat;
    padding-top: 80px;*/
    p{
      /*padding: 30px 0 0 0;
      text-align: center;
      font-size: 15px;*/
    }
    .regS{
      text-transform: none;
      &:after{
        display: none;
      }
    }
  }
}
.plsVerify.modal,.plsVerify,
.reg.modal.sent,.sent{
  width: 700px;
  background-size: contain;
  height: 355px;
  min-height: 180px;
  background-image: url(../img/bg_ver_low.png);
  background-repeat: no-repeat;
  text-align: center;
  z-index: 1100;
}
p.resend {
  text-align: center;
  cursor: pointer;
  font-size: 14px;
  padding: 5px;
  // text-transform: uppercase;
  text-decoration: underline;
}
p.errorMessage {
  color: darkred;
  font-size: 13px;
  text-align: center;
  padding-bottom: 15px;
}
p.conf{
  padding: 10px;
  text-align: center;
  margin-top: 38px;
  font-size: 28px;
  color: #454545;
}
p.please{
  padding: 10px;
  color: #454545;
  line-height: 18pt;
}
p.resendR{
  font-size: 12px;
  span {
    //text-decoration: underline;
    //cursor: pointer;
  }
}
.waitButt {
  margin: 20px auto 0;
  width: 400px;
  background-color: #b1b1b1c7;
  border-radius: 8px;
  cursor: pointer;
}
.waitButt > span > span {
   display: block;
   padding: 16px 0;
 }
.reg.modal{
  .thanks {
    padding-top: 90px;
    h2 {
      font-size: 33px;
      padding-bottom: 12px;
      color: rgba(69, 69, 69, 0.81);
    }
    p {
       font-size: 21px;
       color: rgba(69, 69, 69, 0.81);
   }
  }
}
.goHome {
  font-size: 23px;
  display: table;
  margin: 36px auto 0;
  border-radius: 8px;
  a,span {
    color: #ffffff;
    text-decoration: none;
    display: inline-block;
    background-color: #0096FD;
    padding: 10px 20px;
    border-radius: 8px;
    border: 1px solid #0096fd;
    cursor: pointer;
    &:hover{
      color: #0096FD;
      background-color: #ffffff;
    }
  }
}