.wrap{
  width: 1200px;
  max-width: 100%;
  margin: auto;
  position: relative;
}
.wrap:after{
  content: "";
  display: block;
  position: relative;
  clear: both;
}
.row{
  width: 100%;
  // padding: 5px;
  position: relative;
  z-index: 20;
}
.wrapCity{
  max-width: 1350px;
  width: 100%;
  margin: auto;
  position: relative;
}
main{
  min-height: 800px;
}
::-webkit-input-placeholder {
  -webkit-text-security: none;
  color: rgba(117, 117, 117, 0.49);
  pointer-events: none;
}