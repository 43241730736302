.DoctorPopup {
    background-color: rgba(0, 0, 0, 0.8);
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 100;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.DoctorPopup .innerCard {
    width: 80%;
    /* height: 85%; */
    background-color: white;
    max-width: 933px;
    /* min-height: 600px; */
    position: fixed;
    top: 6%;
    border-radius: 8px;
    padding: 10px;
}

.DoctorPopup .innerCard .doctorHeader {
    width: 100%;
    height: 10%;
    position: relative;
    text-align: center;
}
.DoctorPopup .innerCard .doctorHeader h3{
    font-family: SFProDisplay-Bold, Helvetica, Arial, serif;
    font-size: 22px;
    color: rgb(74, 73, 74);
    line-height: 26px;
    display: grid;
    align-content: center;
}

.DoctorPopup .innerCard .basicInfo {
    width: 70%;
    height: 10%;
    position: relative;
    padding-top: 21px;
    display: flex;
}

.DoctorPopup .innerCard .basicInfo .inputFields {
    width: 50%;
    padding-left: 5%;
}
.DoctorPopup .innerCard .basicInfo .inputFields .title{
    background-color: hsla(0,0%,100%,0);
    top: 0;
    height: auto;
    width: auto;
    position: relative;
    margin: 0;
    left: 0;
    -ms-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    font-family: SFProText-Regular,Helvetica,Arial,serif;
    font-size: 13px;
    color: #9b9a9b;
    text-align: justify;
    line-height: 14px;
}

.DoctorPopup .innerCard .basicInfo .all-days {
    margin: 10px 0px;
    display: -webkit-flex;
    display: -webkit-box;
    display: -moz-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    padding-left: 5%;
}

.DoctorPopup .innerCard .basicInfo .all-days button {
    flex: 1;
    margin: 0px 1px;
    padding: 8px;
    font-weight: normal;
    border: none;
    border-radius: 4px;
}

.DoctorPopup .innerCard .basicInfo .all-days .border-button {
    background-color: #ffffff;
    border: 1px solid #F49AC0;
}

.DoctorPopup .innerCard .set-button {
    width: 75px;
    flex-basis: 75px;
    height: 35px;
    margin-top: 5px;
    background-color: #0096FD;
    color: #ffffff;
    text-transform: uppercase;
    border-radius: 4px;
    border: 1px solid #0096FD;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    box-shadow: 0 1px 7px 0 rgba(0, 0, 0, 0.34);
}

.DoctorPopup .innerCard .basicInfo .all-days .color-button {
    background-color: #50B5FB;
    color: #fff;
}

.DoctorPopup .innerCard .basicInfo .all-days .border-button-notselected {
    background-color: #ffffff;
    border: 1px solid rgb(219, 218, 218);
}