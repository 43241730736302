.setupbooking {
  background-color: white;
  height: auto;
  width: 100%;
  position: relative;
  margin: 0;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
  /* overflow: hidden; */
  padding: 20px;
}

.setupbooking .generalinfo {
  background-color: rgba(255, 255, 255, 0.0);
  height: 191px;
  width: 70%;
  position: relative;
  margin: 0;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.setupbooking .generalinfo .fill11 {
  background-color: #F9F9F9;
  top: 30px;
  height: 161px;
  width: 100%;
  position: absolute;
  margin: 0;
  left: 0px;
  border: 1px solid #DADFE4;
  border-radius: 4px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.setupbooking .generalinfo .bookingemail {
  background-color: rgba(255, 255, 255, 0.0);
  top: 43px;
  height: 52px;
  width: 400px;
  position: relative;
  margin: 0;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.setupbooking .generalinfo .bookingemail .rectangle7 {
  background-color: rgba(255, 255, 255, 1.0);
  height: 30px;
  width: 323px;
  padding-left: 10px;
  position: relative;
  margin: 0;
  left: 0px;
  border-radius: 5px;
  border-style: solid;
  border-width: 0px;
  border-color: rgba(218, 223, 228, 1.0);
  border: 1px solid #DADFE4;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.setupbooking .generalinfo .bookingemail .bookingemail1 {
  background-color: rgba(255, 255, 255, 0.0);
  height: auto;
  width: auto;
  position: relative;
  margin: 4px 0;
  left: 0px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
  font-size: 12.0px;
  color: rgba(94, 94, 94, 1.0);
  text-align: justify;
  letter-spacing: -0.61px;
  line-height: 14.0px;
}
.setupbooking .generalinfo .bookingemail .bookingemail2 {
  background-color: rgba(255, 255, 255, 0.0);
  height: auto;
  width: auto;
  position: relative;
  margin: 0;
  left: 0px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
  font-size: 8.91px;
  color: rgba(94, 94, 94, 1.0);
  text-align: justify;
  line-height: 14.0px;
}

.setupbooking .generalinfo .bookingemail .edit {
  background-color: rgba(255, 255, 255, 0.0);
  top: 21px;
  height: 31px;
  width: 55px;
  position: absolute;
  margin: 0;
  left: 345px;
  border: 1px solid rgba(53, 152, 219, 1.0);
  border-radius: 5px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
  display: flex;
  align-items: center;
}

.setupbooking .generalinfo .bookingemail .edit .edit1 {
  background-color: rgba(255, 255, 255, 0.0);
  height: auto;
  width: 100%;
  position: absolute;
  margin: 0;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
  font-size: 11.88px;
  color: rgba(53, 152, 219, 1.0);
  text-align: center;
  line-height: 14.0px;
}

.setupbooking .generalinfo .contactnumber {
  background-color: rgba(255, 255, 255, 0.0);
  top: 111px;
  height: 52px;
  width: 399px;
  position: absolute;
  margin: 0;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.setupbooking .generalinfo .contactnumber .rectangle7 {
  background-color: rgba(255, 255, 255, 1.0);
  height: 30px;
  width: 326px;
  position: relative;
  padding-left: 10px;
  margin: 0;
  left: 0px;
  border-radius: 6px;
  border-style: solid;
  border-width: 0px;
  border-color: #DADFE4;
  border: 1px solid #DADFE4;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.setupbooking .generalinfo .contactnumber .rectangle71 {
  background-color: rgba(255, 255, 255, 0.0);
  top: 21px;
  height: 31px;
  width: 52px;
  position: absolute;
  margin: 0;
  left: 0px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.setupbooking .generalinfo .contactnumber .fill12 {
  background-color: rgba(255, 255, 255, 0.0);
  top: 34px;
  height: 4px;
  width: 4px;
  position: absolute;
  margin: 0;
  left: 39px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.setupbooking .generalinfo .contactnumber .contactnumber1 {
  background-color: rgba(255, 255, 255, 0.0);
  height: auto;
  width: auto;
  position: relative;
  margin: 4px 0;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
  font-size: 12.0px;
  color: rgba(94, 94, 94, 1.0);
  text-align: justify;
}

.setupbooking .generalinfo .contactnumber .edit {
  background-color: rgba(255, 255, 255, 0.0);
  top: 19px;
  height: 30px;
  width: 55px;
  position: absolute;
  margin: 0;
  left: 344px;
  border: 1px solid rgba(53, 152, 219, 1.0);
  border-radius: 5px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
  display: flex;
  align-items: center;
}

.setupbooking .generalinfo .contactnumber .edit .edit1 {
  background-color: rgba(255, 255, 255, 0.0);
  height: auto;
  width: 100%;
  position: absolute;
  margin: 0;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
  font-size: 11.88px;
  color: rgba(53, 152, 219, 1.0);
  text-align: center;
  line-height: 14.0px;
}

.setupbooking .generalinfo .generalinfo1 {
  background-color: rgba(255, 255, 255, 0.0);
  top: 0px;
  height: auto;
  position: absolute;
  margin: 0;
  left: 0px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
  font-family: "tfBold";
  font-size: 16px;
  color: rgba(94, 94, 94, 1.0);
  text-align: justify;
}

.setupbooking .selecteddoctors {
  background-color: rgba(255, 255, 255, 0.0);
  height: auto;
  min-height: 50px;
  width: 60%;
  position: relative;
  margin: 60px 0 ;
  background-color: #F9F9F9;
  border: 1px solid #DADFE4;
  border-radius: 4px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.setupbooking .selecteddoctors .name {
  background-color: rgba(255, 255, 255, 0.0);
  height: auto;
  width: 25%;
  position: relative;
  margin: 0;
  /* left                : 15px; */
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
  font-size: 13.37px;
  color: rgba(94, 94, 94, 1.0);
  /* text-align: center; */
  /* line-height: 16.0px; */
}

.setupbooking .selecteddoctors .speciality {
  background-color: rgba(255, 255, 255, 0.0);
  height: auto;
  width: 25%;
  position: relative;
  margin: 0;
  /* left                : 258px; */
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
  font-size: 13.37px;
  color: rgba(94, 94, 94, 1.0);
  /* text-align: center; */
  /* line-height: 16.0px; */
}

.setupbooking .selecteddoctors .consultationfees {
  background-color: rgba(255, 255, 255, 0.0);
  height: auto;
  width: 25%;
  position: relative;
  margin: 0;
  /* left                : 516px; */
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
  font-size: 13.37px;
  color: rgba(94, 94, 94, 1.0);
  /* text-align: center; */
  /* line-height: 16.0px; */
}
.setupbooking .selecteddoctors .selecteddoctor .ui.dropdown>.text{
  padding: 2px;
}
.setupbooking .selecteddoctors .averagewaitingtime {
  background-color: rgba(255, 255, 255, 0.0);
  height: auto;
  width: 128px;
  position: relative;
  margin: 0;
  /* left                : 580px; */
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
  font-size: 13.37px;
  color: rgba(94, 94, 94, 1.0);
  text-align: center;
  line-height: 16.0px;
}

.setupbooking .selecteddoctors .selecteddoctor {
  background-color: rgba(255, 255, 255, 0.0);
  margin: 20px;
  height: 45px;
  width: 100%;
  position: relative;
  left: 1px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
  display: flex;
  align-items: center;
  /* justify-content:space-between; */
}

.setupbooking .selecteddoctors .selecteddoctor .drsamehsuliman {
  background-color: rgba(255, 255, 255, 0.0);
  height: auto;
  width: 25%;
  position: relative;
  margin: 0;
  /* left                : 14px; */
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
  font-size: 14.0px;
  color: rgba(94, 94, 94, 1.0);
  /* text-align: justify; */
  /* padding-left: 40px; */
  /* line-height: 16.0px; */
}

.setupbooking .selecteddoctors .selecteddoctor .orthopedics {
  background-color: rgba(255, 255, 255, 0.0);
  height: auto;
  width: 25%;
  position: relative;
  margin: 0;
  /* left                : 258px; */
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
  font-size: 14.0px;
  color: rgba(94, 94, 94, 1.0);
  /* text-align: center;
  line-height: 16.0px; */
}

.setupbooking .selecteddoctors .selecteddoctor .consultationfeesinput {
  background-color: rgba(255, 255, 255, 1.0);
  height: 31px;
  width: 25%;
  position: relative;
  margin: 0;
  /* left                : 509px; */
  border-radius: 5px;
  border: 1px solid rgba(218, 223, 228, 1.0);
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
  /* text-align: center; */
}

.setupbooking .selecteddoctors .selecteddoctor .averagewaitingtime1 {
  background-color: rgba(255, 255, 255, 0.0);
  height: 30px;
  width: 129px;
  position: absolute;
  margin: 0;
  left: 579px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.setupbooking .selecteddoctors .selecteddoctor .averagewaitingtime1 .averagewaitingtime2 {
  background-color: rgba(255, 255, 255, 1.0);
  height: 30px;
  width: 129px;
  position: relative;
  margin: 0;
  left: 0px;
  border-radius: 5px;
  border: 1px solid rgba(218, 223, 228, 1.0);
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
  display: flex;
  align-items: center;
}

.setupbooking .selecteddoctors .selecteddoctor .averagewaitingtime1 .averagewaitingtime2 .a30min {
  background-color: rgba(255, 255, 255, 0.0);
  height: 100%;
  width: 100%;
  position: absolute;
  margin: 0;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
  font-style: normal;
  font-size: 13.36px;
  color: rgba(0, 0, 0, 1.0);
  text-align: justify;
  line-height: 16.0px;
  border: none;
}

.setupbooking .selecteddoctors .selecteddoctor .averagewaitingtime1 .averagewaitingtime2 .page1 {
  background-color: rgba(255, 255, 255, 0.0);
  top: 13px;
  height: 4px;
  width: 4px;
  position: absolute;
  margin: 0;
  left: 92px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.setupbooking .selecteddoctors .selecteddoctor .alldoctors {
  background-color: rgba(255, 255, 255, 0.0);
  height: 31px;
  width: 70px;
  position: absolute;
  margin: 0;
  left: 847px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
  border-radius: 5px;
  /* border: 1px solid rgba(244, 58, 89, 1.0); */
  display: flex;
  align-items: center;
  justify-content: center;
}

.setupbooking .selecteddoctors .selecteddoctor .alldoctors .delete {
  background-color: rgba(255, 255, 255, 0.0);
  height: auto;
  width: 100%;
  position: absolute;
  margin: 0;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
  font-size: 11.88px;
  color: rgba(244, 58, 89, 1.0);
  text-align: center;
}

.setupbooking .selecteddoctors .selecteddoctor .rectangle9 {
  background-color: rgba(218, 223, 228, 1.0);
  top: 45px;
  height: 1px;
  width: 90%;
  position: absolute;
  margin: 0;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.setupbooking .selectdoctor {
  background-color: rgba(255, 255, 255, 0);
  height: 80px;
  top: 30px;
  width: 100%;
  position: relative;
  margin: 0;
  -ms-transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  z-index: 100;
}

.setupbooking .selectdoctor .group5 {
  background-color: rgba(255, 255, 255, 0.0);
  height: 30px;
  width: 324px;
  position: relative;
  margin: 0;
  left: 0px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.setupbooking .selectdoctor .doctors {
  background-color: rgba(255, 255, 255, 0.0);
  height: auto;
  position: relative;
  margin: 0;
  left: 0px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
  font-size: 16.34px;
  color: rgba(94, 94, 94, 1.0);
  text-align: justify;
  font-family: 'tfBold'
}

.setupbooking .selectdoctor .choosethedoctor {
  background-color: rgba(255, 255, 255, 0.0);
  height: auto;
  width: 231px;
  position: relative;
  margin: 0;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
  font-size: 8.91px;
  color: rgba(94, 94, 94, 1.0);
}

.setupbooking .selectdoctor .alldoctors {
  background-color: rgba(255, 255, 255, 0.0);
  top: 47px;
  height: 30px;
  width: 97px;
  position: absolute;
  margin: 0;
  border: 1px solid rgba(53, 152, 219, 1.0);
  border-radius: 5px;
  left: 363px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
  display: flex;
  align-items: center;
  justify-content: center;
}

.setupbooking .selectdoctor .alldoctors .alldoctors2 {
  background-color: rgba(255, 255, 255, 0.0);
  height: auto;
  width: 100%;
  position: absolute;
  margin: 0;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
  font-size: 11.88px;
  color: rgba(53, 152, 219, 1.0);
  text-align: center;
  line-height: 14.0px;
}

.rectangle6 {
  background-color: rgba(53, 152, 219, 1.0);
  top: 302px;
  height: 38px;
  width: 3px;
  position: absolute;
  margin: 0;
  left: 153px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.setupbooking1 {
  background-color: rgba(255, 255, 255, 0.0);
  top: 313px;
  height: auto;
  width: auto;
  position: absolute;
  margin: 0;
  left: 178px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
  font-size: 12.0px;
  color: rgba(0, 0, 0, 1.0);
  text-align: justify;
  line-height: 15.0px;
}