/*
Theme Name: New theme
Author: rajjohin
Author URI: http://www.themezwarrior.com
*/

/*
  ......................................................
  	    Initiative CSS code for any standard theme     
  ......................................................
*/



*:focus {
	outline: none !important;
}

label {
	margin-bottom: .4rem !important;
}

body {
	/* font-family: 'Open Sans', sans-serif; */
	font-size: 0.8750em;
	line-height: 1.250em;
	margin: auto !important;
	overflow-x: hidden;
	color: #676767;
	-webkit-font-smoothing: antialiased;
	width: 100%;
	background-color: #fdfdfd;
}

img {
	max-width: 100%;
	border: none;
	height: auto;
}

.transition {
	-webkit-transition: all 0.9s ease 0s;
	-moz-transition: all 0.9s ease 0s;
	-ms-transition: all 0.9s ease 0s;
	-o-transition: all 0.9s ease 0s;
	transition: all 0.9s ease 0s;
}

button, a, i {
	text-decoration: none !important;
	-webkit-transition: all 0.9s ease 0s;
	-moz-transition: all 0.9s ease 0s;
	-ms-transition: all 0.9s ease 0s;
	-o-transition: all 0.9s ease 0s;
	transition: all 0.9s ease 0s;
}

button:hover, a:hover, i:hover {
	text-decoration: none !important;
	-webkit-transition: all 0.9s ease 0s;
	-moz-transition: all 0.9s ease 0s;
	-ms-transition: all 0.9s ease 0s;
	-o-transition: all 0.9s ease 0s;
	transition: all 0.9s ease 0s;
	cursor: pointer;
}

a:focus {
	outline: none !important;
}

a:active {
	text-decoration: none !important;
}

textarea:focus, input:focus {
	outline: 0;
}

select:focus {
	outline: none !important;
}

ul {
	padding: 0px;
	margin: 0px;
	list-style: none;
}

p {
	margin: 0px!important;
}

h1, h2, h3, h4, h5, h6 {
	margin: 0px!important;
}

/*
  .................................................
  	    Global CSS code for any theme     
  .................................................
*/

.marginTop30 {
	margin-bottom: 30px;
	cursor: pointer;
}

.comment-box {
	position: fixed;
	bottom: 10px;
	right: 10px;
	width: 60px;
}

.save-btn {
	position: relative;
	/* left: 2%; */
	/* width: 100%; */
	/* padding-bottom: 55px;
	padding-top: 15px; */
	padding: 15px 12px 40px 12px;
}

.save-btn .saveToCon button {
	background-color: #ff552c;
	color: #ffffff;
	min-width: 100px;
	padding: 15px 15px;
	border: 1px solid #ff552c;
	cursor: pointer;
	border-top-right-radius: 2em;
	border-bottom-right-radius: 2em;
	border-top-left-radius: 2em;
	border-bottom-left-radius: 2em;
	float: left;
	margin-left: 25%;
	align-self: center;
}

.save-btn .saveToCon button:hover {
	box-shadow: 0 2px 23px 2px rgba(128, 128, 128, 0.5);
}

.save-btn .navigation button {
	min-width: 100px;
	padding: 10px;
	background-color: #0095FC;
	border: 1px solid #0095FC;
	color: #fff;
	cursor: pointer;
	border-radius: 4px;
	float: left;
	margin-right: 10px;
}

.save-btn .navigation button:hover {
	background-color: #fff;
	color: #0095FC;
	border: 1px solid #0095FC;
}

.save-btn .submit button {
	min-width: 100px;
	padding: 10px;
	background-color: #0095FC;
	border: 1px solid #0095FC;
	color: #fff;
	cursor: pointer;
	border-radius: 4px;
	float: right;
	margin-right: 12px;
}

.save-btn .submit button:hover {
	background-color: #fff;
	color: #0095FC;
	border: 1px solid #0095FC;
}

.main-side {
	/* min-height: 110vh; */
}

@media (max-width: 800px) {
	.main-side {
		display: none;
	}
}

.normal-btn {
	min-width: 140px;
	padding: 10px;
	border: 1px solid #ddd;
	background-color: #fff;
	color: #404040;
	cursor: pointer;
	border-radius: 4px;
}

.normal-btn:hover {
	background-color: #0095FC;
	color: #fff;
	border: 1px solid #0095FC;
}

.color-btn {
	min-width: 140px;
	padding: 10px;
	border: none;
	background-color: #0095FC;
	color: #fff;
	cursor: pointer;
	border-radius: 4px;
}

.color-btn:hover {
	background-color: #0074DB;
}

.upload-btn {
	min-width: 150px;
	padding: 10px;
	border: none;
	background-color: #fff;
	color: #0095FC;
	cursor: pointer;
	border-radius: 4px;
	border: 1px solid #0095FC;
}

.upload-btn:hover {
	background-color: #0095FC;
	color: #fff;
}

.tag-button {
	min-width: 31%;
	padding: 10px 6px;
	background-color: #fff;
	color: #777;
	cursor: pointer;
	border-radius: 4px;
	border: 1px solid #ddd;
	font-size: 13px;
	text-align: left;
	margin: 4px;
}

.tag-btn-gray {
	background-color: #D9D9D9;
	border: none;
}

.tag-button i {
	float: right;
	font-size: 12px;
	color: #FF6666;
}

.plus-btn {
	text-align: left;
	padding: 6px;
	border-radius: 4px;
	position: relative;
}

.plus-btn i {
	color: #2D95FF;
	position: absolute;
	right: -6px;
}

.section {
	width: 100%;
	overflow: hidden;
	clear: both;
	margin-bottom: 20px;
}

.section-title {
	font-size: 14px;
	color: #4d4d4d;
	display: block;
}

.sub-title {
	font-size: 12px;
	margin-bottom: 20px !important;
}

.search-input, .gray-box {
	background-color: #F9F9F9;
	padding-left: 20px;
	border: 1px solid #ddd;
	margin-bottom: 24px;
	border-radius: 4px;
}

.search-input {
	width: 60%;
}

.right-side-box {
	background-color: #fff;
	padding: 20px;
}

.card {
	margin-bottom: 6px;
	box-shadow: 1px 1px 2px #ddd;
}

.card .card-header {
	padding: 0px;
	margin-bottom: 0;
	background-color: #fff;
	border-bottom: transparent;
}

.card .card-header button {
	color: #4d4d4d;
	font-size: 14px;
	width: 100%;
	text-align: left;
}

.card .card-header button .card-sideicon {
	position: absolute;
	right: 12px;
	top: 8px;
	color: #0196FD;
}

.box-title {
	margin-bottom: 16px;
}

.box-title strong {
	font-size: 14px;
	color: #4d4d4d;
	margin-bottom: 4px;
	display: block;
	font-family: 'tfBold';
}

.box-title p {
	font-size: 12px;
}

/*********************************************************************************/

/*
==================================
    SIDE-NAV STYLING HERE
==================================
*/

.side-nav {
	padding: 0px;
	background-color: #11313E;
	color: #fff;
	min-height: 110vh;
}

.side-nav ul {
	text-align: center;
}

.side-nav ul li {
	border-bottom: 1px solid #777;
	-webkit-transition: all 0.5s ease 0s;
	-moz-transition: all 0.5s ease 0s;
	-ms-transition: all 0.5s ease 0s;
	-o-transition: all 0.5s ease 0s;
	transition: all 0.5s ease 0s;
	height: 100px;
	border-left: 3px solid transparent;
	cursor: pointer;
	display: grid;
	align-items: center;
}

.side-nav ul li:focus, .side-nav ul li:hover {
	border-left: 3px solid #0096FD;
	-webkit-transition: all 0.5s ease 0s;
	-moz-transition: all 0.5s ease 0s;
	-ms-transition: all 0.5s ease 0s;
	-o-transition: all 0.5s ease 0s;
	transition: all 0.5s ease 0s;
}

.side-nav ul li:focus, .side-nav ul li:hover i {
	color: #0096FD;
}

.side-nav ul li:focus, .side-nav ul li:hover a {
	color: #fff;
}

.side-nav ul li a {
	height: auto;
	color: #777777;
	/* text-transform: uppercase; */
}

.side-nav ul li a img {
	display: block;
	font-size: 2em;
	margin: 0 auto;
}

.side-nav ul li a i {
	display: block;
	font-size: 2.5em;
	margin: 0 auto;
	margin-bottom: 5px;
}

.main-body {
	background-color: #F2F3F7;
	padding: 0px;
}

/*
==================================
    HEADER STYLING HERE
==================================
*/

.main-body header {
	background-color: #fff;
	clear: both;
	box-shadow: 0px 3px 2px #E9E9E9;
	height: 60px;
	z-index: 100;
	position: sticky;
	width: 100%;
	transition:background-color 3s

}

.main-body header .header-left {
	width: 50%;
	height: 100%;
	float: left;
	display: flex;
	align-items: center;
}

.main-body header .header-left ul {
	display: flex;
	margin: 0;
}

.main-body header .header-left ul li {
	display: inline-block;
	margin: 0px 30px;
	line-height: 48px;
}

.main-body header .header-left ul .menuIcon {
	display: inline-block;
	margin: 0px 30px;
	line-height: 48px;
}
/* @media (min-width: 1026px) {
	.main-body header .header-left ul .menuIcon {
		display: none;
	}
} */

.main-body header .header-left ul li a {
	/* background-color: #0096FD; */
	color: #3598db;
	padding: 8px 18px;
	border-radius: 4px;
	border: 1px solid #3598db;
}

.main-body header .header-left ul li .header-button {
	/* background-color: #0096FD; */
	color: #3598db;
	padding: 8px 18px;
	border-radius: 4px;
	border: 1px solid #3598db;
}

@media (max-width: 576px) {
	.main-body header .header-left ul li .header-button {
		display: none;
	}
}

@media (max-width: 768px) and (min-width: 576px) {
	.main-body header .header-left ul li .header-button {
		display: none;
	}
}

/* @media (max-width: 1024px) and (min-width: 768px) {
	.main-body header .header-left ul li .header-button {
		display: none;
	}
} */

.main-body header .header-left ul li a:hover {
	background-color: #3598db;
	color: white;
}

.main-body header .header-right {
	width: 50%;
	float: right;
	height: 100%;
	text-align: right;
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.main-body header .header-right ul {
	display: flex;
	float: right;
	margin: 0;
}

.main-body header .header-right ul li {
	display: grid;
	align-content: center;
	margin: 0px 15px;
}

.main-body header .header-right ul li a {
	color: #97A9B2;
}

.main-body header .header-right ul li .fa-caret-down {
	font-size: 12px;
	color: #97A9B2;
}

.main-body header .header-right .user-notification {
	position: relative;
}

.main-body header .header-right .user-notification .notify {
	position: absolute;
	background: #F43D59;
	color: #fff;
	width: 18px;
	height: 18px;
	border-radius: 50%;
	text-align: center;
	font-size: 12px;
	padding-top: 1px;
	top: -14px;
	right: -4px;
}

.main-body header .header-right .user-profile {
	text-align: left;
}

.main-body header .header-right .user-profile .user-name {
	font-size: 14px;
	color: #7b7b7b;
	/* text-transform: uppercase; */
	;
}

.main-body header .header-right .user-profile .user-designation {
	font-size: 12px;
	color: #7b7b7b;
	align-self: baseline;
	min-width: 85px;
}

.main-body header .header-right .user-picture {
	text-align: left;
}

.main-body header .header-right .user-picture:hover .userlist {
	display: grid;
	transition-duration: 0.5s;
}

.main-body header .header-right .user-picture .userlist {
	display: none;
	width: 200px;
	height: auto;
	position: fixed;
	right: 1%;
	margin-top: 50px;
	z-index: 1;
	text-align: left;
	background-color: #ffffff;
	-webkit-box-shadow: 1px 1px 1px #718899;
	-moz-box-shadow: 1px 1px 1px #718899;
	box-shadow: 0px 6px 24px 0px rgba(113, 136, 153, 0.21);
	padding: 15px 0 20px 28px;
	border-radius: 10px;
}

.main-body header .header-right .user-picture .userlist a {
	width: auto;
	height: auto;
	position: relative;
	text-align: left;
	/* font-family: 'Open Sans', sans-serif; */
	font-size: 14px;
	padding: 8px 0;
	color: #4a4a4a;
}

.main-body header .header-right .user-picture .userlist .listitem {
	width: auto;
	height: auto;
	position: relative;
	text-align: left;
	/* font-family: 'Open Sans', sans-serif; */
	font-size: 14px;
	padding: 8px 0;
	color: #4a4a4a;
	cursor: pointer;
}

.main-body header .header-right .user-picture img {
	width: 50px;
}

.main-body header .header-right .user-picture .border1 {
	display: inline-flex;
	width: 50px;
	height: 50px;
	position: relative;
	overflow: hidden;
	border-radius: 50%;
	border: 1px solid #3498db;
	align-items: center;
	justify-content: center;
}

/*
==================================
    Facility-Page STYLING HERE
==================================
*/

#facility-page {
	/* padding: 30px; */
	display: -webkit-flex;
	display: -webkit-box;
	display: -moz-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
}

#facility-page .left-side {
	background-color: #E8EBF2;
	min-height: 80vh;
	width: 15%;
}

@media (max-width: 800px) {
	#facility-page .left-side {
		display: none;
	}
}

#facility-page .left-side .logo {
	padding: 10px;
	text-align: center;
	position: relative;
	height: 175px;
	display: grid;
	justify-content: center;
}

#facility-page .left-side .logo .clinicPic {
	display: inline-flex;
	width: 150px;
	height: 150px;
	position: relative;
	overflow: hidden;
	border-radius: 50%;
	border: 7px solid #dadfe4;
	align-items: center;
	justify-content: center;
}

#facility-page .left-side .logo .clinicPic img {
	width: 100%;
	margin: 0 auto;
	height: auto;
	background-color: #ffffff;
}

#facility-page .left-side .logo label {
	position: relative;
	color: #3498db;
	z-index: 99;
	padding-top: 4px;
	font-size: 12px;
	cursor: pointer;
}

#facility-page .left-side .logo input[type="file"] {
	display: none;
}

#facility-page .left-side .profile-completition {
	padding: 24px 20px 0;
}

#facility-page .left-side .profile-completition .progress {
	height: 0.6rem;
	background-color: #fff;
	margin-top: 10px;
}

#facility-page .left-side .profile-completition .progress .progress-bar {
	background-color: #8CCA11;
}

#facility-page .left-side .activities-date {
	padding: 30px 20px;
}

#facility-page .left-side .activities-date i {
	font-size: 10px;
	display: block;
	line-height: 22px;
	text-align: start;
	color: #777;
}

#facility-page .left-side .page-nav ul {
	border-bottom: none;
	width: 101%;
}

#facility-page .left-side .page-nav ul li {
	width: 100%;
	display: block;
	cursor: pointer;
}

#facility-page .left-side .page-nav ul li a {
	color: #000;
	padding: 14px 20px;
	display: block;
	border-radius: 0px!important;
}

#facility-page .left-side .page-nav ul li:focus, #facility-page .left-side .page-nav ul li:hover {
	background-color: #fff;
	border-left: 2px solid #0095FC;
	color: #0095FC;
}

#facility-page .left-side .page-nav ul li a:hover {
	color: #0095FB;
}

/*
----- Doctor Appointment System----------
*/

#facility-page .left-side .page-nav ul li .doctor-cell {
	color: #000;
	padding: 8px 12px;
	display: flex;
	align-items: center;
	border-radius: 0px!important;
}

#facility-page .left-side .page-nav ul li .doctor-cell .doctor-info {
	position: relative;
	padding-left: 10px;
	overflow: hidden;
}

#facility-page .left-side .page-nav ul li .doctor-cell .doctor-info .name {
	color: #000;
	font-size: 12px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

#facility-page .left-side .page-nav ul li .doctor-cell .doctor-info .speciality {
	color: #797979;
	font-size: 9px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

#facility-page .left-side .page-nav ul li .doctor-cell img {
	width: 30px;
	height: 30px;
	border-radius: 300px;
}

#facility-page .left-side .page-nav ul li .doctor-cell .doctor-color {
	width: 8px;
	height: 8px;
	border-radius: 300px;
	margin-right: 10px;
}

/*
----- Basic Info Sub Page ----------
*/

.sub-pages {
	overflow: auto;
	width: 85%;
}
@media (max-width: 800px) {
	.sub-pages {
		width: 100% !important;
	}
}
#facility-page .tab-content>.active {
	display: -webkit-flex;
	display: -webkit-box;
	display: -moz-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
}

.sub-pages .main-side {
	background: #fff;
	padding: 20px;
	width: 70%;
	margin-right: 2%;
}

.sub-pages .main-side .desc-input, .sub-pages .main-side .name-input {
	width: 100%;
	margin-bottom: 24px;
}

.sub-pages .main-side .img-input {
	margin-top: 50px;
	margin-bottom: 10px;
}

.sub-pages .main-side .img-input .images, .sub-pages .main-side .name-input {
	display: -webkit-flex;
	display: -webkit-box;
	display: -moz-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
}

.sub-pages .main-side .img-input .images {
	margin: 10px 0px;
}

.sub-pages .main-side .img-input a {
	color: #666666;
}

.sub-pages .main-side .img-input .single-img {
	position: relative;
	width: 24%;
	border: 1px solid #ddd;
	margin-right: 10px;
}

.sub-pages .main-side .img-input .single-img img {
	width: 100%;
	height: auto;
}

.sub-pages .main-side .img-input .single-img input[type="checkbox"] {
	position: absolute;
}

.sub-pages .main-side .name-input div {
	width: 100%;
}

.sub-pages .main-side div label {
	font-size: 14px;
	color: #4d4d4d;
	/* margin-top: 10px; */
	display: block;
	font-family: 'tfBold';
}

.sub-pages .main-side div label span {
	color: darkred;
}

.sub-pages .main-side div input[type="text"] {
	display: block;
	width: 100%;
	padding: 10px 12px;
	height: auto;
	margin: 0;
	border: 1px solid #ddd;
}

.sub-pages .main-side .arabic-input {
	text-align: right;
}

.sub-pages .main-side .name-input .arabic-input input[type="text"] {
	margin-left: 2%;
}

.sub-pages .main-side div textarea {
	width: 100%;
	padding: 10px 12px;
	min-height: 16vh;
	border: 1px solid #ddd;
}

.sub-pages .main-side .button-section .left-button {
	width: 70%;
	float: left;
}

.sub-pages .main-side .button-section .right-button .fileContainer {
	text-align: right;
	box-shadow: none;
	background-color: transparent;
	padding: 0;
	display: block;
}

.sub-pages .main-side .button-section .right-button .fileContainer .uploadPicturesWrapper {
	text-align: right;
	box-shadow: none;
	background-color: transparent;
	padding: 0;
	display: none;
}

.sub-pages .main-side .button-section .right-button .fileContainer .upload-btn {
	min-width: 150px;
	padding: 10px;
	border: none;
	background-color: #fff;
	color: #0095FC;
	cursor: pointer;
	border-radius: 4px;
	border: 1px solid #0095FC;
}

.sub-pages .main-side .button-section .right-button .fileContainer .upload-btn:hover {
	background-color: #0095FC;
	color: #fff;
}

.sub-pages .right-side {
	width: 30%;
}

@media (max-width: 800px) {
	.sub-pages .right-side {
		width: 100% !important;
	}
}

.sub-pages .right-side .fees-box {
	background: #fff;
	padding: 20px;
	margin-bottom: 30px;
}

.sub-pages .right-side .fees-box .single-fee span, .sub-pages .right-side .fees-box .single-fee {
	display: -webkit-flex;
	display: -webkit-box;
	display: -moz-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	margin-bottom: 10px;
}

.sub-pages .right-side .fees-box .single-fee label {
	margin-bottom: 0px;
	width: 150px;
	font-size: 12px;
}

.sub-pages .right-side .fees-box .single-fee span select {
	padding: 4px;
}

.sub-pages .right-side .fees-box .single-fee span input {
	padding: 4px;
	background-color: #F9F9F9;
	border: 1px solid #ddd;
	width: 70%;
}

.sub-pages .right-side .admin-box {
	background: #fff;
	padding: 20px;
}

.sub-pages .right-side .admin-box .name-input, .sub-pages .right-side .admin-box .position-input {
	margin-bottom: 20px;
}

.sub-pages .right-side .admin-box .name-input .ui.dropdown .menu .active.item, .sub-pages .right-side .admin-box .position-input .ui.dropdown .menu .active.item {
	font-size: 12px;
}

.sub-pages .right-side .admin-box div label {
	display: block;
	margin-bottom: 8px;
	color: #4a4a4a;
	font-size: 12px;
}

.sub-pages .right-side .admin-box .name-input input, .sub-pages .right-side .admin-box .position-input input {
	width: 100%;
	padding: 8px 12px;
	background-color: #F9F9F9;
	border: 1px solid #ddd;
}

.sub-pages .right-side .admin-box .contact-input {
	clear: both;
	overflow: hidden;
}

.sub-pages .right-side .admin-box .contact-input .ui.dropdown .menu .active.item {
	font-size: 12px;
}

.sub-pages .right-side .admin-box .contact-input span {
	display: -webkit-flex;
	display: -webkit-box;
	display: -moz-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	float: left;
}

.sub-pages .right-side .admin-box .contact-input span input {
	width: 75%;
	padding: 8px;
}

.sub-pages .right-side .admin-box .contact-input .color-btn {
	min-width: 38%!important;
	margin-left: 2%;
	font-size: 12px;
}

.sub-pages .right-side .admin-box .upload-input {
	margin-top: 30px;
}

.sub-pages .right-side .admin-box .upload-input {
	margin-bottom: 20px;
}

.sub-pages .right-side .admin-box .upload-input .uplad-file {
	padding: 10;
}

.sub-pages .right-side .admin-box .upload-input .uplad-file label {
	border: none;
	min-width: 150px;
	padding: 10px;
	display: inline-block;
	background-color: #fff;
	color: #0095FC;
	cursor: pointer;
	border-radius: 4px;
	text-align: center;
	border: 1px solid #0095FC;
}

.sub-pages .right-side .admin-box .upload-input .uplad-file label:hover {
	border: none;
	min-width: 150px;
	padding: 10px;
	display: inline-block;
	background-color: #0095FC;
	color: #fff;
	cursor: pointer;
	border-radius: 4px;
	text-align: center;
	border: 1px solid #fff;
}

.sub-pages .right-side .admin-box .upload-input .uplad-file i {
	margin-left: 5px;
}

.sub-pages .right-side .admin-box .upload-input .uplad-file i:hover {
	margin-left: 5px;
}

.sub-pages .right-side .admin-box .upload-input .uplad-file input[type="file"] {
	display: none;
}

.sub-pages .right-side .admin-box .box-footer {
	font-size: 12px;
	text-align: center;
	border-top: 1px solid #ddd;
	padding: 10px 0px;
}

.sub-pages .right-side .admin-box .box-footer i {
	color: #91CC1D;
	margin-right: 5px;
}

/*
----- Specialities SubPage ----------
*/

#Specialities .specialities-list .single-specialist {
	width: 18%;
	border: 1px solid #ddd;
	border-radius: 4px;
	float: left;
	min-height: 140px;
	padding: 10px 4px;
	text-align: center;
	margin: 1%;
	padding-top: 20px;
}

#Specialities .main-side {
	width: 100%;
	min-height: 85vh;
}

#Specialities .specialities-list .active {
	background-color: #0196FD;
}

#Specialities .specialities-list .active p {
	color: #fff!important;
}

#Specialities .specialities-list .single-specialist img {
	width: 75%;
	margin-bottom: 12px;
	height: 60px;
}

#Specialities .specialities-list .single-specialist p {
	font-size: 12px;
	color: #999;
}

#Specialities .your-specialist .single-specialist .doctor-list {
	overflow: hidden;
	clear: both;
}

#Specialities .your-specialist .single-specialist .doctor-list .single-doctor {
	width: 48%;
	text-align: center;
	border: 1px solid #ddd;
	padding: 10px;
	border-radius: 4px;
	float: left;
	margin: 1%;
	min-height: 135px;
}

#Specialities .your-specialist .single-specialist .doctor-list .single-doctor img {
	width: 80%;
	margin-bottom: 10px;
	border: 3px solid #ddd;
	border-radius: 50%;
}

#Specialities .your-specialist .single-specialist .doctor-list .single-doctor p {}

/*
----- Insurance SubPage ----------
*/

#Insurance .seleted-insurance .single-insurance {
	background: #fff;
	border: 1px solid #ddd;
	border-radius: 4px;
	width: 30%;
	float: left;
	margin: 1.4%;
}

#Insurance .main-side {
	width: 100%;
	min-height: 85vh;
}

#Insurance .seleted-insurance .single-insurance .insurance-title {
	padding: 6px 10px;
	border-bottom: 1px solid #ddd;
}

#Insurance .seleted-insurance .single-insurance .insurance-title i {
	font-size: 12px;
	float: right;
	padding-top: 2px;
	color: #ff7878;
}

#Insurance .seleted-insurance .single-insurance .insurance-list {
	padding: 6px 10px;
}

#Insurance .seleted-insurance .single-insurance .insurance-list li {
	list-style: none;
	padding: 4px;
}

#Insurance .seleted-insurance .single-insurance .insurance-list li i {
	font-size: 12px;
	float: right;
	padding-top: 2px;
	color: #ff7878;
}

#Insurance .seleted-insurance .single-insurance .insurance-list button {
	background: #F4F5F9;
	border: 1px solid #ddd;
	width: 100%;
	padding: 6px;
	margin-top: 6px;
	color: #666;
	cursor: pointer;
}

#Insurance .your-insurance .insurance-list a {
	width: 46%;
	display: block;
	float: left;
	color: #4d4d4d;
	padding: 8px 5px;
	border-bottom: 1px solid #ddd;
	margin: 0% 2%;
}

#Insurance .your-insurance .insurance-list a i {
	color: #00F08B;
	margin-right: 4px;
}

/*
----- Amenities SubPage ----------
*/

#Amenities .main-side {
	width: 100%;
	min-height: 85vh;
}

/*
----- Location SubPage ----------
*/

#Location .top-location {}

#Location .top-location .single-method {
	width: 32%;
	margin-right: 1%;
	float: left;
}

#Location .main-side {
	width: 100%;
	min-height: 85vh;
}

#Location .top-location .single-method label {
	font-size: 12px;
}

#Location .top-location .single-method form {
	display: -webkit-flex;
	display: -webkit-box;
	display: -moz-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
}

#Location .top-location .single-method form select {
	font-size: 12px;
	width: 28%;
}

#Location .top-location .single-method form input {
	width: 50%;
	padding: 4px;
	font-size: 12px;
	background-color: #F9F9F9;
}

#Location .top-location .single-method form input[type="email"] {
	width: 100%;
	border: 1px solid #ddd;
	border-radius: 4px;
}

#Location .location-details .map iframe {
	margin: 20px 0px;
}

#Location .location-details .single-location {
	width: 32%;
	float: left;
	margin-right: 1%;
	margin-bottom: 20px;
}

#Location .location-details .single-location label {
	font-size: 12px;
	margin-bottom: 8px;
}

#Location .location-details .single-location input {
	background: #F9F9F9;
	padding: 6px;
}

.location-list .single-method {
	display: -webkit-flex;
	display: -webkit-box;
	display: -moz-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	margin: 14px 0px;
}

.location-list .single-method label {
	font-size: 11px;
	margin-bottom: 0px;
	line-height: 12px;
	width: 40%;
}

.location-list .single-method form {
	display: -webkit-flex;
	display: -webkit-box;
	display: -moz-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	width: 60%;
}

.location-list .single-method form select {
	font-size: 12px;
	width: 30%;
}

.location-list .single-method form input {
	width: 60%;
	padding: 4px;
	font-size: 12px;
	background-color: #F9F9F9;
	border: 1px solid #ddd;
}

.location-list .single-method input {
	width: 60%;
	background-color: #F9F9F9;
	border: 1px solid #ddd;
	padding: 4px;
	border-radius: 4px;
}

/*
----- Timing SubPage ----------
*/

#Timing .main-side {
	width: 100%;
	min-height: 90vh;
}

#Timing .all-days {
	margin: 10px 0px;
	display: -webkit-flex;
	display: -webkit-box;
	display: -moz-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
}

#Timing .set-button {
	width: 75px;
	flex-basis: 75px;
	height: 35px;
	margin-top: 5px;
	margin-bottom: 15px;
	background-color: #0096FD;
	color: #ffffff;
	text-transform: uppercase;
	border-radius: 4px;
	border: 1px solid #0096FD;
	cursor: pointer;
	justify-content: center;
	align-items: center;
	box-shadow: 0 1px 7px 0 rgba(0, 0, 0, 0.34);
}

#Timing .all-days .color-button {
	background-color: #50B5FB;
	color: #fff;
}

#Timing .all-days .border-button {
	background-color: #ffffff;
	border: 1px solid #F49AC0;
}

#Timing .all-days .border-button-notselected {
	background-color: #ffffff;
	border: 1px solid rgb(219, 218, 218);
}

#Timing .all-days button {
	flex: 1;
	margin: 0px 1px;
	padding: 8px;
	font-weight: normal;
	border: none;
	border-radius: 4px;
}

#Timing .hours .hours-limit {
	width: 24%;
	float: left;
	margin-right: 10px;
	display: -webkit-flex;
	display: -webkit-box;
	display: -moz-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	padding-top: 18px;
}

#Timing .hours .hours-limit button {
	padding: 8px 6px;
	border: none;
	font-size: 12px;
	flex: 1;
}

#Timing .hours .hours-limit .left-button-active {
	background: #50B5FB;
	color: #fff;
	border-radius: 4px 0px 0px 4px;
	border-left: 1px solid #50B5FB;
	border-top: 1px solid #50B5FB;
	border-bottom: 1px solid #50B5FB;
}

#Timing .hours .hours-limit .right-button-active {
	background: #50B5FB;
	color: #fff;
	border-radius: 0px 4px 4px 0px;
	border-right: 1px solid #50B5FB;
	border-top: 1px solid #50B5FB;
	border-bottom: 1px solid #50B5FB;
}

#Timing .hours .hours-limit .left-button {
	background: #ffffff;
	border-radius: 4px 0px 0px 4px;
	border: 1px solid rgb(219, 218, 218);
}

#Timing .hours .hours-limit .right-button {
	background: #ffffff;
	border-radius: 0px 4px 4px 0px;
	border: 1px solid rgb(219, 218, 218);
}

#Timing .hours .from-to {
	width: 50%;
	float: left;
	display: -webkit-flex;
	display: -webkit-box;
	display: -moz-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	margin-top: 10px;
}

#Timing .hours .from-to .from label, #Timing .hours .from-to .to label {
	font-size: 11px;
	margin-bottom: 0px;
}

#Timing .hours .from-to .from input, #Timing .hours .from-to .to input {
	padding: 4px;
	border-radius: 4px;
}

#Timing .hours .from-to button {
	padding: 4px;
	border-radius: 4px;
	background-color: #0196FD;
	border: none;
	color: #fff;
	min-height: 28px;
	margin-top: 16px;
	padding: 0px 10px;
}

#Timing .hours .split-shift {
	width: 22%;
	float: right;
	margin-left: 8px;
}

#Timing .hours .split-shift label {
	font-size: 11px;
	color: #999;
	line-height: 12px;
	margin-bottom: 2px;
}

#Timing .hours .split-shift button {
	min-width: 100px;
}

#Timing .opening-hour img {
	width: 100%;
}

#Timing .swich-branches button {
	margin-bottom: 6px;
	box-shadow: 1px 1px 2px #ddd;
	padding: 10px;
	border-radius: 4px;
	width: 100%;
	text-align: left;
	background: #fff;
	border: 1px solid #ddd;
	color: #4d4d4d;
	position: relative;
}

#Timing .swich-branches button i {
	position: absolute;
	right: 12px;
	top: 8px;
	color: #0196FD;
}

/*
================================================================

                   RESPONSIVE STYLING HERE

================================================================
*/

@media (max-width: 575px) {}

@media (min-width: 576px) and (max-width: 767px) {}

@media (min-width: 768px) and (max-width: 991px) {}

@media (min-width: 992px) and (max-width: 1199px) {}

@media (min-width: 1200px) {}