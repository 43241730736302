.group15 {
  background-color: white;
  min-height: 56px;
  height: auto;
  position: relative;
  margin: 4px 10px 10px;
  border-radius: 6px;
  overflow: hidden;
  -ms-transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  display: flex;
  align-items: center;
  justify-content: space-between;
  -webkit-box-shadow: 0 0px 4px #9B9B9B;
  box-shadow: 0 0px 4px #9B9B9B;
  padding-left: 12px;
  padding-right: 12px;
}

.group15 .manage {
  background-color: rgba(255, 255, 255, 0.0);
  height: auto;
  width: auto;
  position: relative;
  margin: 0;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
  font-size: 12.0px;
  color: rgba(53, 152, 219, 1.0);
  text-align: center;
  line-height: 14.0px;
  cursor: pointer;
}

.group15 .group3 {
  background-color: rgba(255, 255, 255, 0.0);
  height: 100px;
  width: 70%;
  position: relative;
  margin: 0;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
  display: grid;
}

.group15 .group3 .appointmentdate2 {
  background-color: rgba(255, 255, 255, 0.0);
  height: auto;
  width: auto;
  position: relative;
  margin-top: 4px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
  font-size: 10.0px;
  color: #3498db;
  text-align: left;
  font-weight: 500;
}

.group15 .group3 .tarekjamal {
  background-color: rgba(255, 255, 255, 0.0);
  height: auto;
  width: auto;
  position: relative;
  margin-top: 9px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
  font-size: 14.0px;
  color: #4a4a4a;
  text-align: left;
  line-height: 14.0px;
}

.group15 .group3 .notereason {
  background-color: rgba(255, 255, 255, 0.0);
  height: auto;
  width: auto;
  position: relative;
  margin-top: 6px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
  font-size: 10.0px;
  color: rgba(74, 73, 74, 1.0);
  text-align: left;
  line-height: 14.0px;
}

.group15 .group3 .drsamehsuliman {
  background-color: rgba(255, 255, 255, 0.0);
  height: auto;
  width: auto;
  position: relative;
  margin-top: 4px;
  margin-bottom: 10px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
  font-size: 10.0px;
  color: rgba(74, 73, 74, 1.0);
  text-align: left;
  line-height: 12.0px;
}