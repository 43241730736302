.mainContainer{
    width: 646px;
    /* height: 595px; */
    background: white;
    border-radius: 8px;
    color: black;
}
.mainContainer .popupTitle{
    font-size: 22px;
    font-weight: bold;
    color: rgba(74, 74, 74, 1);
    padding: 24px;
    text-align: center;
}
.mainContainer .popupTitle .blueLineContainer{
    display: flex;
    justify-content: center;
    padding: 10px;
}
.mainContainer .popupTitle .blueLineContainer .blueLine{
    background: rgb(53, 152, 219);
    width: 91.8px;
    height: 6.3px;
    border-radius: 3px;
}

.mainContainer .content{
    justify-content: center;
    width: 100%;
    display: flex;
    padding-bottom: 16px;   
}
.mainContainer .content .row{
    display: flex;
    justify-content: space-between;
    width: 90%;
}
.mainContainer .content .row .cell{
    width: 47%;
}
.mainContainer .content .row .cell .title{
    text-align: left;
    font-size: 12px;
    color: rgba(155, 155, 155, 1);
}
.mainContainer .content .row .cell .input{
    padding-top: 6px;
    width: 100%;
    font-size: 14px;
    color: rgba(74, 74, 74, 1);
}

.ui.checkbox input:checked~.box:after, .ui.checkbox input:checked~label:after{
    color: rgb(53, 152, 219) !important;
}
.ui.checkbox label {
    color:  rgba(74, 74, 74, 1) !important;
    font-size: 12px !important;
}
.mainContainer .ui.basic.button, .ui.basic.buttons .button{
    -webkit-box-shadow: 0 0 0 1.2px rgba(34,36,38,.15) inset!important;
    box-shadow: inset 0 0 0 1.2px rgba(34,36,38,.15) !important;
}