.reschedulesweetalert{
    width: 660px !important;
    height: auto !important;
    min-height: 200px !important;
    display: flex !important;
    justify-content: space-around !important;
    padding: 0 !important;
}
.reschedulesweetalert .swal2-title{
    background-color    : rgba(255,255,255,0.0) !important;
    height              : 40px;
    width               : auto;
    position            : relative;
    margin              : 0;
    -ms-transform       : rotate(0deg); /* IE 9 */
    -webkit-transform   : rotate(0deg); /* Chrome, Safari, Opera */
    transform           : rotate(0deg);
    font-family         : "SFProDisplay-Bold", Helvetica, Arial, serif !important;
    font-size           : 18.0px !important;
    color               : rgba(74, 73, 74, 1.0) !important;
    text-align          : center !important;
    line-height         : 26.0px !important;
    align-self: center !important;
    border-bottom: 1px solid #D8D8D8;
    display: grid !important;
    align-content: center !important;
    width: 100%;
}

.reschedulesweetalert .swal2-content{
    background-color    : rgba(255,255,255,0.0) !important;
    height              : 100%;
    width               : auto;
    position            : relative;
    margin              : 0;
    -ms-transform       : rotate(0deg); /* IE 9 */
    -webkit-transform   : rotate(0deg); /* Chrome, Safari, Opera */
    transform           : rotate(0deg);
    font-family         : "SFProDisplay-Light", Helvetica, Arial, serif !important;
    font-size           : 18.0px !important;
    color               : rgba(120, 120, 120, 1.0) !important;
    text-align          : center !important;
    line-height         : 26.0px !important;
    align-self: center !important;
    display: grid !important;
}

.reschedulesweetalert .swal2-content .title{
    position: relative;
    align-self: end;
  }
  .reschedulesweetalert .swal2-content .appointmentDate{
   position: relative;
   display: flex;
    justify-content: center;
    align-items: center;

  }
  .reschedulesweetalert .swal2-content .appointmentDate .date{
    font-family         : "SFProDisplay-Medium", Helvetica, Arial, serif !important;
    color               : rgba(53, 152, 219, 1.0) !important;
    display: inline-block;
    padding-left: 3px;
  }
  .reschedulesweetalert .swal2-content .appointmentDate .text{
    position: relative;
  }

.reschedulesweetalert .swal2-actions{
    direction: rtl;
    align-items: center;
    justify-content: flex-start;
    display: flex!important;
    bottom: 10px;
    right: 11px;
    width: 100%;
    position: relative;
    margin: 0!important;
    padding-top: 10px;
}

.reschedulesweetalert .swal2-actions .swal2-confirm.swal2-styled{
    height: 30px !important;
    width: auto !important;
    min-width: 100px !important;
    background               : white !important;
    color: rgba(53, 152, 219, 1.0) !important;
    border               :1px solid rgba(53, 152, 219, 1.0) !important;
    padding: 0 !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
    border-radius: 4.5px !important;
    margin: 0px 5px !important;
    outline: none !important;

}
.reschedulesweetalert .swal2-actions .swal2-confirm.swal2-styled:hover{
    background : rgba(53, 152, 219, 1.0) !important;
    color:  white !important;
}

.reschedulesweetalert .swal2-actions .swal2-styled.swal2-cancel{
    height: 30px !important;
    width: auto !important;
    min-width: 100px !important;
    background: white !important;
    color: rgb(216, 29, 29)  !important;
    border:1px solid rgb(216, 29, 29) !important;
    padding: 0 !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
    border-radius: 4.5px !important;
    margin: 0px 5px !important;
    outline: none !important;

}
.reschedulesweetalert .swal2-actions .swal2-styled.swal2-cancel:hover{
    background :rgb(216, 29, 29) !important;
    color: white !important;
}


