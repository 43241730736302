/*
Theme Name: New theme
Author: rajjohin
Author URI: http://www.themezwarrior.com
*/

/*
  ......................................................
  	    Initiative CSS code for any standard theme     
  ......................................................
*/

* {
	margin: 0;
	padding: 0;
}


*:focus {
	outline: none !important;
}

body {
	/* font-family: 'Open Sans', sans-serif; */
	font-size: 0.8750em;
	line-height: 1.250em;
	margin: auto !important;
	overflow-x: hidden;
	color: #676767;
	-webkit-font-smoothing: antialiased;
	width: 100%;
	background-color: #fdfdfd;
}

img {
	max-width: 100%;
	border: none;
	height: auto;
}

.transition {
	-webkit-transition: all 0.9s ease 0s;
	-moz-transition: all 0.9s ease 0s;
	-ms-transition: all 0.9s ease 0s;
	-o-transition: all 0.9s ease 0s;
	transition: all 0.9s ease 0s;
}

button, a, i {
	text-decoration: none !important;
	-webkit-transition: all 0.9s ease 0s;
	-moz-transition: all 0.9s ease 0s;
	-ms-transition: all 0.9s ease 0s;
	-o-transition: all 0.9s ease 0s;
	transition: all 0.9s ease 0s;
}

button:hover, a:hover, i:hover {
	text-decoration: none !important;
	-webkit-transition: all 0.9s ease 0s;
	-moz-transition: all 0.9s ease 0s;
	-ms-transition: all 0.9s ease 0s;
	-o-transition: all 0.9s ease 0s;
	transition: all 0.9s ease 0s;
	cursor: pointer;
}

a:focus {
	outline: none !important;
}

a:active {
	text-decoration: none !important;
}

textarea:focus, input:focus {
	outline: 0;
}

select:focus {
	outline: none !important;
}

ul {
	padding: 0px;
	margin: 0px;
	list-style: none;
}

p {
	margin: 0px!important;
}

h1, h2, h3, h4, h5, h6 {
	margin: 0px!important;
}

/*
  .................................................
  	    Global CSS code for any theme     
  .................................................
*/

.marginTop30 {
	margin-bottom: 30px;
	cursor: pointer;
}

.marginBottom30 {
	margin-bottom: 30px;
}

.comment-box {
	position: fixed;
	bottom: 10px;
	right: 10px;
	width: 60px;
}

.save-btn {
	position: relative;
	/* left: 21%; */
	margin-bottom: 60px;
}

.save-btn-local:hover {
	background-color: #0074DB !important;
}

.save-btn-local {
	min-width: 110px;
	padding: 10px;
	border: 1px solid #0095FC;
	background-color: #0095FC !important;
	color: #fff !important;
	margin-right: 4px;
	cursor: pointer;
	border-radius: 4px;
	font-weight: 600;
	position: relative;
	width: 10%;
	/* left: 60%; */
	top: 30px;
	float: right;
	bottom: 10%;
	margin-bottom: 20px;
}

/* .main-side {
	min-height: 90vh;
} */

.normal-btn {
	min-width: 140px;
	padding: 10px;
	border: 1px solid #ddd;
	background-color: #fff;
	color: #404040;
	margin-right: 4px;
	cursor: pointer;
	border-radius: 4px;
	font-weight: 600;
}

.normal-btn:hover {
	background-color: #0095FC;
	color: #fff;
	border: 1px solid #0095FC;
}

.color-btn {
	min-width: 140px;
	padding: 10px;
	border: 1px solid #0095FC;
	background-color: #0095FC;
	color: #fff;
	margin-right: 4px;
	cursor: pointer;
	border-radius: 4px;
	font-weight: 600;
}

.color-btn:hover {
	background-color: #0074DB;
}

.upload-btn {
	min-width: 150px;
	padding: 10px;
	border: none;
	background-color: #fff;
	color: #0095FC;
	cursor: pointer;
	border-radius: 4px;
	font-weight: 600;
	border: 1px solid #0095FC;
}

.upload-btn:hover {
	background-color: #0095FC;
	color: #fff;
}

.tag-button {
	min-width: 20%;
	padding: 10px 6px;
	background-color: #fff;
	color: #777;
	cursor: pointer;
	border-radius: 4px;
	font-weight: 600;
	margin-right: 4px;
	border: 1px solid #ddd;
	font-size: 13px;
	text-align: left;
	margin: 4px;
}

.tag-btn-gray {
	background-color: #D9D9D9;
	border: none;
}

.tag-button i {
	float: right;
	font-size: 10px;
	color: #DA6A69;
	padding-left: 6px;
}

.plus-btn {
	text-align: left;
	padding: 6px;
	border-radius: 4px;
	position: relative;
}

.plus-btn i {
	color: #2D95FF;
	position: absolute;
	right: -6px;
}

.section {
	width: 100%;
	overflow: hidden;
	clear: both;
	margin-bottom: 20px;
}

.section-title {
	font-size: 14px;
	color: #4d4d4d;
	display: block;
	font-weight: 600;
}

.sub-title {
	font-size: 12px;
	margin-bottom: 2px !important;
}

.search-input, .doctor-gray-box {
	margin-bottom: 6px;
	margin-top: 10px;
	border-radius: 4px;
}

.search-input {
	width: 60%;
}

.right-side-box {
	background-color: #fff;
	padding: 20px;
}

.card {
	margin-bottom: 6px;
	box-shadow: 1px 1px 2px #ddd;
}

.card .card-header {
	padding: 0px;
	margin-bottom: 0;
	background-color: #fff;
	border-bottom: transparent;
}

.card .card-header button {
	color: #4d4d4d;
	font-size: 14px;
	width: 100%;
	text-align: left;
}

.card .card-header button .card-sideicon {
	position: absolute;
	right: 12px;
	top: 8px;
	color: #0196FD;
}

.box-title {
	margin-bottom: 16px;
}

.box-title p {
	font-size: 12px;
}

.flex {
	display: -webkit-flex;
	display: -webkit-box;
	display: -moz-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
}

/*********************************************************************************/

/*
==================================
    SIDE-NAV STYLING HERE
==================================
*/

.side-nav {
	padding: 0px;
	background-color: #11313E;
	color: #fff;
	height: auto;
	min-height: 100vh;
}

.side-nav ul {
	text-align: center;
}

.side-nav ul li {
	border-bottom: 1px solid #777;
	-webkit-transition: all 0.5s ease 0s;
	-moz-transition: all 0.5s ease 0s;
	-ms-transition: all 0.5s ease 0s;
	-o-transition: all 0.5s ease 0s;
	transition: all 0.5s ease 0s;
	height: 100px;
	border-left: 3px solid transparent;
	cursor: pointer;
	display: grid;
	align-items: center;
}

.side-nav ul li:focus, .side-nav ul li:hover {
	border-left: 3px solid #0096FD;
	-webkit-transition: all 0.5s ease 0s;
	-moz-transition: all 0.5s ease 0s;
	-ms-transition: all 0.5s ease 0s;
	-o-transition: all 0.5s ease 0s;
	transition: all 0.5s ease 0s;
}

.side-nav ul li:focus, .side-nav ul li:hover i {
	color: #0096FD;
}

.side-nav ul li:focus, .side-nav ul li:hover a {
	color: #fff;
}

.side-nav ul li a {
	height: auto;
	color: #c1c1c1;
	/* text-transform: uppercase; */
}

.side-nav ul li a img {
	display: block;
	font-size: 2em;
	margin: 0 auto;
}

.side-nav ul li a i {
	display: block;
	font-size: 2.5em;
	margin: 0 auto;
	margin-bottom: 5px;
}

.main-body {
	background-color: #F2F3F7;
	padding: 0px;
}

/*
==================================
    HEADER STYLING HERE
==================================
*/

.main-body header {
	background-color: #fff;
	clear: both;
	-webkit-box-shadow: 0px 0px 4px #E9E9E9;
	box-shadow: 0px 0px 4px #E9E9E9;
	height: 60px;
	z-index: 100;
	position: relative;
	width: 100%;
	/* transition:background-color 3s */
}
.main-body header .header-left {
	width: 50%;
	height: 100%;
	float: left;
	display: flex;
	align-items: center;
}

.main-body header .header-left ul {
	display: flex;
	margin: 0;
}

.main-body header .header-left ul li {
	display: inline-block;
	margin: 0px 30px;
	line-height: 48px;
}
.main-body header .header-left ul .menuIcon {
	display: inline-block;
	margin: 0px 30px;
	line-height: 48px;
}
/* @media (min-width: 1026px) {
	.main-body header .header-left ul .menuIcon {
		display: none;
	}
} */

.main-body header .header-left ul li a {
	/* background-color: #0096FD; */
	color: #3598db;
	padding: 8px 18px;
	border-radius: 4px;
	border: 1px solid #3598db;
}

.main-body header .header-left ul li .header-button {
	/* background-color: #0096FD; */
	color: #3598db;
	padding: 8px 18px;
	border-radius: 4px;
	border: 1px solid #3598db;
}

@media (max-width: 576px) {
	.main-body header .header-left ul li .header-button {
		display: none;
	}
}

@media (max-width: 768px) and (min-width: 576px) {
	.main-body header .header-left ul li .header-button {
		display: none;
	}
}

/* @media (max-width: 1024px) and (min-width: 768px) {
	.main-body header .header-left ul li .header-button {
		display: none;
	}
} */


.main-body header .header-left ul li a:hover {
	background-color: #3598db;
	color: white;
}

.main-body header .header-right {
	width: 50%;
	float: right;
	height: 100%;
	text-align: right;
	display: flex;
	align-content: center;
	justify-content: flex-end;
}

.main-body header .header-right ul {
	display: flex;
	float: right;
	margin: 0;
}

.main-body header .header-right ul li {
	display: grid;
	align-content: center;
	margin: 0px 15px;
}

.main-body header .header-right ul li a {
	color: #97A9B2;
}

.main-body header .header-right ul li .fa-caret-down {
	font-size: 12px;
	color: #97A9B2;
}

.main-body header .header-right .user-notification {
	position: relative;
}

.main-body header .header-right .user-notification .notify {
	position: absolute;
	background: #F43D59;
	color: #fff;
	width: 18px;
	height: 18px;
	border-radius: 50%;
	text-align: center;
	font-size: 12px;
	padding-top: 1px;
	top: -14px;
	right: -4px;
}

.main-body header .header-right .user-profile {
	text-align: left;
}

.main-body header .header-right .user-profile .user-name {
	font-size: 14px;
	color: #7b7b7b;
	/* text-transform: uppercase; */
	;
}

.main-body header .header-right .user-profile .user-designation {
	font-size: 12px;
	color: #7b7b7b;
	align-self: baseline;
	min-width: 85px;
}

.main-body header .header-right .user-picture {
	text-align: left;
}

.main-body header .header-right .user-picture:hover .userlist {
	display: grid;
	transition-duration: 0.5s;
}

.main-body header .header-right .user-picture .userlist {
	display: none;
	width: 200px;
	height: auto;
	position: fixed;
	right: 1%;
	margin-top: 50px;
	z-index: 1;
	text-align: left;
	background-color: #ffffff;
	-webkit-box-shadow: 1px 1px 1px #718899;
	-moz-box-shadow: 1px 1px 1px #718899;
	box-shadow: 0px 6px 24px 0px rgba(113, 136, 153, 0.21);
	padding: 15px 0 20px 28px;
	border-radius: 10px;
}

.main-body header .header-right .user-picture .userlist a {
	width: auto;
	height: auto;
	position: relative;
	text-align: left;
	/* font-family: 'Open Sans', sans-serif; */
	font-size: 14px;
	padding: 8px 0;
	color: #4a4a4a;
}

.main-body header .header-right .user-picture .userlist .listitem {
	width: auto;
	height: auto;
	position: relative;
	text-align: left;
	/* font-family: 'Open Sans', sans-serif; */
	font-size: 14px;
	padding: 8px 0;
	color: #4a4a4a;
	cursor: pointer;
}

.main-body header .header-right .user-picture img {
	max-width: 65px;
	width: 65px;
	object-fit: cover;
}

.main-body header .header-right .user-picture .border1 {
	display: inline-flex;
	width: 50px;
	height: 50px;
	position: relative;
	overflow: hidden;
	border-radius: 50%;
	border: 1px solid #3498db;
	align-items: center;
	justify-content: center;
}

/*
==================================
    Add-Doctor-Page STYLING HERE
==================================
*/

#page-body {
	/* padding: 30px; */
	/* padding-top: 90px; */
	display: -webkit-flex;
	display: -webkit-box;
	display: -moz-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
}

#page-body .left-side {
	background-color: #E8EBF2;
	min-height: 80vh;
	width: 15%;
}

#page-body .left-side .logo {
	padding: 10px;
	text-align: center;
	position: relative;
	height: 175px;
	display: grid;
	justify-content: center;
}

#page-body .left-side .logo .clinicPic {
	display: inline-flex;
	width: 150px;
	height: 150px;
	position: relative;
	overflow: hidden;
	border-radius: 50%;
	border: 7px solid #dadfe4;
	align-items: center;
	justify-content: center;
}

#page-body .left-side .logo .clinicPic img {
	width: 100%;
	margin: 0 auto;
	height: 100%;
	object-fit: cover;
	background-color: #ffffff;
}

#page-body .left-side .logo label {
	position: relative;
	color: #3498db;
	z-index: 99;
	padding-top: 4px;
	font-size: 12px;
	font-weight: 600;
	cursor: pointer;
}

#page-body .left-side .logo input[type="file"] {
	display: none;
}

#page-body .left-side .profile-completition {
	padding: 24px 20px 0;
}

#page-body .left-side .profile-completition .progress {
	height: 0.6rem;
	background-color: #fff;
	margin-top: 10px;
}

#page-body .left-side .profile-completition .progress .progress-bar {
	background-color: #8CCA11;
}

#page-body .left-side .activities-date {
	padding: 15px 20px;
}

#page-body .left-side .activities-date i {
	font-size: 10px;
	display: block;
	line-height: 22px;
	text-align: start;
	color: #777;
}

#page-body .left-side .page-nav ul {
	border-bottom: none;
	width: 101%;
}

#page-body .left-side .page-nav ul li {
	border-left: 2px solid transparent;
	width: 100%;
	display: block;
}

#page-body .left-side .page-nav ul li a {
	color: #000;
	padding: 10px 20px;
	display: block;
	border-radius: 0px!important;
}

#page-body .left-side .page-nav ul li .shomore {
	color: #000;
	padding: 14px 20px;
	display: flex;
	align-items: center;
	border-radius: 0px!important;
	width: 83%;
	border-top: 1px solid #dadfe4;
}

#page-body .left-side .page-nav ul li .shomore .pic {
	position: relative;
	left: 35%;
	width: auto;
	height: 20px;
}

#page-body .left-side .page-nav ul li:focus, #page-body .left-side .page-nav ul li:hover {
	background-color: #fff;
	border-left: 2px solid #0095FC;
	color: #0095FC;
}

#page-body .left-side .page-nav ul li a:hover {
	color: #0095FB;
}

#page-body .tab-content>.active {
	display: -webkit-flex;
	display: -webkit-box;
	display: -moz-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
}

/*
----- Basic CSS of Sub Pages ----------
*/

.sub-pages {
	width: 85%;
	overflow-y: auto;
}

.sub-pages .main-side {
	background: #fff;
	padding: 20px;
	width: 70%;
	margin-right: 2%;
}

.sub-pages .main-side .box {
	margin-top: 16px;
	/* overflow: hidden; */
	clear: both;
}

.sub-pages .main-side .box .selectiondropdown {
	height: 32px;
	width: 60%;
	position: relative;
	margin: 0;
	-ms-transform: rotate(0deg);
	/* IE 9 */
	-webkit-transform: rotate(0deg);
	/* Chrome, Safari, Opera */
	transform: rotate(0deg);
}

.sub-pages .main-side .box .selectiondropdown .default.text {
	background: none;
	height: 15px;
}

.sub-pages .main-side .box .selectiondropdown .visible.menu.transition .item .ui.label {
	background: white;
}

.sub-pages .main-side .box label {
	font-size: 14px;
	color: #4d4d4d;
	display: block;
}

.sub-pages .main-side .box input {
	display: block;
	padding: 10px 10px;
	border: 1px solid #ddd;
	/* width: 60px; */
	/* margin-bottom: 6px; */
	border-radius: 4px;
}

.sub-pages .main-side div .select-input {
	display: -webkit-flex;
	display: -webkit-box;
	display: -moz-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
}

.sub-pages .main-side .box .select-input select {
	border-right: none;
	border-radius: 4px 0px 0px 4px;
}

.sub-pages .main-side .box .select-input input {
	border-radius: 0px 4px 4px 0px;
}

.sub-pages .main-side div .input-half {
	width: 48%;
	margin-top: 16px;
	float: left;
}

.sub-pages .main-side div .marg-right {
	margin-right: 2%;
}

.sub-pages .main-side .arabic-input {
	text-align: right;
}

.sub-pages .main-side div textarea {
	width: 100%;
	padding: 10px 12px;
	min-height: 16vh;
	border: 1px solid #ddd;
}

.sub-pages .main-side .box .tagPic-button {
	height: 60px;
	position: relative;
}

.sub-pages .main-side .box .tagPic-button img {
	float: left;
	width: 20%;
}

.sub-pages .main-side .box .tagPic-button .desc {
	padding-top: 6px;
}

.sub-pages .main-side .box .tagPic-button i {
	position: absolute;
	top: 18px;
	right: 4px;
}

.sub-pages .main-side .address-box div label {
	font-weight: normal;
	font-size: 14px;
	text-transform: capitalize;
}

.sub-pages .main-side .address-box div {
	/* margin-bottom: 3px; */
	z-index: 1;
}

.sub-pages .main-side .box .group-button button {
	min-width: 80px;
	margin-bottom: 6px;
	margin-right: 1%;
}

.sub-pages .right-side {
	width: 30%;
}

.sub-pages .right-side .right-side-box .box {
	background: #fff;
	margin-bottom: 16px;
}

.sub-pages .right-side .right-side-box .box .rightselectiondropdown {
	height: 32px;
	width: 100%;
	position: relative;
	margin: 0;
	left: 0px;
	-ms-transform: rotate(0deg);
	/* IE 9 */
	-webkit-transform: rotate(0deg);
	/* Chrome, Safari, Opera */
	transform: rotate(0deg);
}

.sub-pages .right-side .right-side-box .box .rightselectiondropdown .default.text {
	background: none;
	height: 15px;
}

.sub-pages .right-side .right-side-box .box .rightselectiondropdown .visible.menu.transition .item .ui.label {
	background: white;
}

.sub-pages .right-side .right-side-box .box .attachVerifyParent {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
}

.sub-pages .right-side .right-side-box .box .attachVerifyParent .added {
	position: relative;
	border: 1px solid #3498db;
	border-radius: 4px;
	background: #3498db;
	padding: 5px;
	padding-left: 15px;
	height: auto;
	margin: 2px;
	width: 50%;
	cursor: pointer;
}

.sub-pages .right-side .right-side-box .box .attachVerifyParent .addedlabel {
	font-size: 12px;
	font-weight: 600;
	color: white;
	margin: 0;
	cursor: pointer;
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.sub-pages .right-side .right-side-box .box .attachVerifyParent .attachVerify {
	position: relative;
	border: 1px solid #3498db;
	border-radius: 4px;
	padding: 5px;
	height: auto;
	margin: 2px;
	width: 50%;
	cursor: pointer;
}

.sub-pages .right-side .right-side-box .box .attachVerifyParent .attachVerify:hover {
	background: #3498db;
	color: white;
}

.sub-pages .right-side .right-side-box .box .attachVerifyParent .attachVerifylabel {
	font-size: 12px;
	font-weight: 600;
	color: #3498db;
	margin: 0;
	text-align: center;
	cursor: pointer;
}

.sub-pages .right-side .right-side-box .box .attachVerifyParent .attachVerifylabel:hover {
	color: white;
	background: #3498db;
}

.sub-pages .right-side .right-side-box .box .attachVerifyParent .attachVerify input[type="file"] {
	display: none;
}

.sub-pages .right-side .box label {
	display: block;
	margin-bottom: 8px;
	color: #4d4d4d;
	font-weight: normal;
}

.sub-pages .right-side .box textarea, .sub-pages .right-side .box select, .sub-pages .right-side .box input {
	width: 100%;
	padding: 8px 12px;
	/* background-color: #F9F9F9; */
	border: 1px solid #ddd;
	border-radius: 4px;
	margin-bottom: 6px;
}

.sub-pages .right-side .box textarea {
	min-height: 16vh;
}

.sub-pages .right-side .arabic-input {
	text-align: right;
}

.sub-pages .right-side .side-gray-box {
	position: relative;
	width: auto;
	font-size: 12px;
	min-width: 180px;
	margin: 10px;
	border: 1px solid #dadfe4;
	border-radius: 8px;
	padding: 10px;
	display: inline-block;
}

.sub-pages .right-side .side-gray-box .head {
	/* text-transform: uppercase; */
	font-size: 14px;
	font-family: 'tfBold';
	border-bottom: 1px solid #ddd;
	padding-bottom: 6px;
}

.sub-pages .right-side .side-gray-box .head img {
	float: left;
	width: 40px;
	height: 40px;
	margin-right: 6px;
}

.sub-pages .right-side .side-gray-box .degree {
	padding: 10px 0px;
}

.sub-pages .right-side .side-gray-box button {
	position: absolute;
	right: 10px;
	border: 1px solid #ddd;
	bottom: 10px;
	padding: 4px 12px;
	border: 1px solid #0196FD;
	color: #0196FD;
	background-color: #fff;
	border-radius: 4px;
	text-transform: uppercase;
}

.sub-pages .right-side .side-gray-box button:hover {
	background-color: #0196FD;
	color: #fff;
}

.sub-pages .right-side .side-gray-box i {
	position: absolute;
	top: 4px;
	right: 4px;
	font-size: 10px;
	color: #DA6A69;
}

.save-btn {
	position: relative;
	/* left: 2%; */
	width: 100%;
	margin-top: 15px;
	padding: 15px 12px 40px 12px;
}

.save-btn .saveToCon button {
	background-color: #ff552c;
	color: #ffffff;
	min-width: 100px;
	padding: 15px 15px;
	border: 1px solid #ff552c;
	cursor: pointer;
	border-top-right-radius: 2em;
	border-bottom-right-radius: 2em;
	border-top-left-radius: 2em;
	border-bottom-left-radius: 2em;
	font-weight: 600;
	float: left;
	margin-left: 25%;
	align-self: center;
}

.save-btn .saveToCon button:hover {
	box-shadow: 0 2px 23px 2px rgba(128, 128, 128, 0.5);
}

.save-btn .navigation button {
	min-width: 100px;
	padding: 10px;
	background-color: #0095FC;
	border: 1px solid #0095FC;
	color: #fff;
	cursor: pointer;
	border-radius: 4px;
	font-weight: 600;
	float: left;
	margin-right: 10px;
}

.save-btn .navigation button:hover {
	background-color: #fff;
	color: #0095FC;
	border: 1px solid #0095FC;
}

.save-btn .submit button {
	min-width: 100px;
	padding: 10px;
	background-color: #0095FC;
	border: 1px solid #0095FC;
	color: #fff;
	cursor: pointer;
	border-radius: 4px;
	font-weight: 600;
	float: right;
	/* margin-right: 40px; */
}

.save-btn .submit button:hover {
	background-color: #fff;
	color: #0095FC;
	border: 1px solid #0095FC;
}

/*
----- Timing SubPage ----------
*/

#Timing .main-side {
	width: 100%;
}

#Timing .all-days {
	margin: 10px 0px;
	display: -webkit-flex;
	display: -webkit-box;
	display: -moz-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
}

#Timing .set-button {
	width: 75px;
	flex-basis: 75px;
	height: 35px;
	margin-top: 5px;
	background-color: #0096FD;
	color: #ffffff;
	text-transform: uppercase;
	border-radius: 4px;
	border: 1px solid #0096FD;
	cursor: pointer;
	justify-content: center;
	align-items: center;
	box-shadow: 0 1px 7px 0 rgba(0, 0, 0, 0.34);
}

#Timing .all-days .color-button {
	background-color: #50B5FB;
	color: #fff;
}

#Timing .all-days .border-button {
	background-color: #ffffff;
	border: 1px solid #F49AC0;
}

#Timing .all-days .border-button-notselected {
	background-color: #ffffff;
	border: 1px solid rgb(219, 218, 218);
}

#Timing .all-days button {
	flex: 1;
	margin: 0px 1px;
	padding: 8px;
	font-weight: normal;
	border: none;
	border-radius: 4px;
}

#Timing .hours .hours-limit {
	width: 24%;
	float: left;
	margin-right: 10px;
	display: -webkit-flex;
	display: -webkit-box;
	display: -moz-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	padding-top: 18px;
}

#Timing .hours .hours-limit button {
	padding: 8px 6px;
	border: none;
	font-size: 12px;
	flex: 1;
}

#Timing .hours .hours-limit .left-button-active {
	background: #50B5FB;
	color: #fff;
	border-radius: 4px 0px 0px 4px;
	border-left: 1px solid #50B5FB;
	border-top: 1px solid #50B5FB;
	border-bottom: 1px solid #50B5FB;
}

#Timing .hours .hours-limit .right-button-active {
	background: #50B5FB;
	color: #fff;
	border-radius: 0px 4px 4px 0px;
	border-right: 1px solid #50B5FB;
	border-top: 1px solid #50B5FB;
	border-bottom: 1px solid #50B5FB;
}

#Timing .hours .hours-limit .left-button {
	background: #ffffff;
	border-radius: 4px 0px 0px 4px;
	border: 1px solid rgb(219, 218, 218);
}

#Timing .hours .hours-limit .right-button {
	background: #ffffff;
	border-radius: 0px 4px 4px 0px;
	border: 1px solid rgb(219, 218, 218);
}

#Timing .hours .from-to {
	width: 50%;
	float: left;
	display: -webkit-flex;
	display: -webkit-box;
	display: -moz-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	margin-top: 10px;
}

#Timing .hours .from-to .from label, #Timing .hours .from-to .to label {
	font-size: 11px;
	margin-bottom: 0px;
}

#Timing .hours .from-to .from input, #Timing .hours .from-to .to input {
	padding: 4px;
	border-radius: 4px;
}

#Timing .hours .from-to button {
	padding: 4px;
	border-radius: 4px;
	background-color: #0196FD;
	border: none;
	color: #fff;
	min-height: 28px;
	margin-top: 16px;
	padding: 0px 10px;
}

#Timing .hours .split-shift {
	width: 22%;
	float: right;
	margin-left: 8px;
}

#Timing .hours .split-shift label {
	font-size: 11px;
	color: #999;
	line-height: 12px;
	margin-bottom: 2px;
}

#Timing .hours .split-shift button {
	min-width: 100px;
}

#Timing .opening-hour img {
	width: 100%;
}

#Timing .swich-branches button {
	margin-bottom: 6px;
	box-shadow: 1px 1px 2px #ddd;
	padding: 10px;
	border-radius: 4px;
	width: 100%;
	text-align: left;
	background: #fff;
	border: 1px solid #ddd;
	color: #4d4d4d;
	position: relative;
}

#Timing .swich-branches button i {
	position: absolute;
	right: 12px;
	top: 8px;
	color: #0196FD;
}

/*
================================================================

                   RESPONSIVE STYLING HERE

================================================================
*/

@media (max-width: 575px) {}

@media (min-width: 576px) and (max-width: 767px) {}

@media (min-width: 768px) and (max-width: 991px) {}

@media (min-width: 992px) and (max-width: 1199px) {}

@media (min-width: 1200px) {}