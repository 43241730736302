.desktophd {
  width: 100%;
  min-width: 800px;
  height: 100%;
  min-height: 600px;
  position: relative;
  margin: 0px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgba(255, 255, 255, 1.0);
  display: flex;
  overflow: hidden;
  align-items: center;
}

@media (max-width:1024px) {
  .desktophd {
    min-width: auto;
  }
}

.desktophd .leftside {
  background-color: rgba(255, 255, 255, 1.0);
  width: 30%;
  height: 75%;
  position: relative;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
  display: grid;
}

@media (max-width: 1024px) {
  .desktophd .leftside {
    width: 100%;
  }
}

.desktophd .leftside .loginbutton {
  display: grid;
  width: 90%;
  position: relative;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
  font-family: "Poppins", Helvetica, Arial, serif;
  font-style: normal;
  font-size: 17.0px;
  justify-self: center;
  align-self: center;
}

.desktophd .leftside .colorlogotransparentbackground {
  background-color: rgba(255, 255, 255, 0.0);
  width: 30%;
  height: auto;
  position: relative;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
  justify-self: center;
  align-self: center;
}

.desktophd .leftside .logintextmsg {
  background-color: rgba(255, 255, 255, 0.0);
  height: auto;
  width: 100%;
  position: relative;
  margin: 0;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
  font-family: "SFProDisplay-Regular", Helvetica, Arial, serif;
  font-size: 28.0px;
  color: rgba(74, 73, 74, 1.0);
  text-align: center;
  line-height: 34.0px;
  align-self: center;
}

.desktophd .leftside .edittextsparent {
  background-color: rgba(255, 255, 255, 0.0);
  height: auto;
  width: 100%;
  position: relative;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
  display: grid;
  margin: 30px 0;
}

.desktophd .leftside .edittextsparent .group4 {
  background-color: rgba(255, 255, 255, 0.0);
  height: auto;
  width: 90%;
  position: relative;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
  justify-self: center;
}

.desktophd .leftside .edittextsparent .group4 .yourname {
  background-color: rgba(255, 255, 255, 0.0);
  width: 90%;
  position: relative;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
  font-family: 'tfRegular';
  font-style: normal;
  font-size: 15.0px;
  color: rgba(74, 73, 74, 1.0);
  text-align: left;
  line-height: 23.0px;
}

.desktophd .leftside .edittextsparent .group4 .rectangle2 {
  background-color: rgba(255, 255, 255, 0.0);
  top: 8px;
  height: 52px;
  box-sizing: border-box;
  position: relative;
  border-radius: 6px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.desktophd .leftside .group8 {
  background-color: rgba(255, 255, 255, 0.0);
  width: 90%;
  position: relative;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
  justify-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.desktophd .leftside .group8 .keepmeloggedin {
  background-color: rgba(255, 255, 255, 0.0);
  height: auto;
  width: 50%;
  position: relative;
  margin: 0;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
  font-family: "Poppins", Helvetica, Arial, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 14.0px;
  color: rgba(74, 73, 74, 1.0);
  text-align: center;
  line-height: 21.0px;
}

.desktophd .leftside .group8 .forgetpassword {
  background-color: rgba(255, 255, 255, 0.0);
  height: auto;
  position: relative;
  width: 50%;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
  font-family: "Poppins", Helvetica, Arial, serif;
  font-style: normal;
  font-size: 15.0px;
  color: rgba(74, 73, 74, 1.0);
  text-align: center;
  line-height: 23.0px;
  cursor: pointer;
}

.desktophd .rightside {
  background-color: #F6F6F6;
  width: 70%;
  height: 100%;
  position: relative;
  margin: 0;
  border: none;
  display: grid;
  align-items: center;
  justify-content: flex-start;
}

@media (max-width: 1024px) {
  .desktophd .rightside {
    display: none
  }
}

.desktophd .rightside .backgroundimg {
  background-color: rgba(255, 255, 255, 0.0);
  width: 100%;
  position: absolute;
  margin: 0;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  object-fit: cover;
  transform: rotate(0deg);
  height: 100%;
  bottom: 0;
  border: none;
}

.desktophd .rightside .thriveproparent {
  background-color: rgba(255, 255, 255, 0.0);
  height: auto;
  width: auto;
  margin-left: 45px;
  position: relative;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
  z-index: 1;
}

.desktophd .rightside .thriveproparent .u201cyourappointmentso {
  background-color: rgba(255, 255, 255, 0.0);
  height: auto;
  width: auto;
  position: relative;
  margin: 0;
  margin-top: 6px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
  font-family: "SFProDisplay-LightItalic", Helvetica, Arial, serif;
  font-size: 16.0px;
  font-style: italic;
  color: white;
  text-align: start;
  letter-spacing: -0.33px;
  line-height: 26.0px;
}

.desktophd .rightside .thriveproparent .u201cyourappointmentso .span2 {}

.desktophd .rightside .thriveproparent .tellusmore {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 12px;
  align-items: center;
  position: relative;
}

.desktophd .rightside .thriveproparent .tellusmore .group9 {
  background-color: rgba(255, 255, 255, 0.0);
  -ms-transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-size: 16.0px;
  text-align: center;
  width: 100%;
  border: 2px solid white;
  color: white;
  cursor: pointer;
  padding: 8px 20px;
  border-radius: 4px;
}

.desktophd .rightside .thriveproparent .thrivepro {
  background-color: rgba(255, 255, 255, 0.0);
  height: auto;
  width: auto;
  position: relative;
  margin: 0;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
  font-family: "SFProDisplay-Medium", Helvetica, Arial, serif;
  font-size: 36.0px;
  text-align: start;
  line-height: 38.0px;
  color: white;
}

.desktophd .rightside .thriveproparent .reducenoshow {
  background-color: rgba(255, 255, 255, 0.0);
  height: auto;
  width: auto;
  position: relative;
  margin: 0;
  margin-top: 10px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
  font-family: "SFProDisplay-Light", Helvetica, Arial, serif;
  font-size: 16.0px;
  text-align: start;
  /* line-height: 38.0px; */
  color: white;
}