.fullcalendar {
  width: 100%;
  height: 100%;
  zoom: 115%;
}

.fullcalendar .fc.fc-unthemed.fc-ltr {
  font-size: 1em;
  height: 100%;
}

.fullcalendar .fc.fc-unthemed.fc-ltr .fc-view-container {
  color: #9B9B9B;
  font-size: 9px;
  height: 100%;
}

/* Day view */

.fullcalendar .fc.fc-unthemed.fc-ltr .fc-view-container .fc-view.fc-basicDay-view.fc-basic-view {
  height: 100%;
}

.fullcalendar .fc.fc-unthemed.fc-ltr .fc-view-container .fc-view.fc-basicDay-view.fc-basic-view table {
  height: 100%;
}

.fullcalendar .fc.fc-unthemed.fc-ltr .fc-view-container .fc-view.fc-basicDay-view.fc-basic-view table .fc-body {
  height: 100%;
}

.fullcalendar .fc.fc-unthemed.fc-ltr .fc-view-container .fc-view.fc-basicDay-view.fc-basic-view table .fc-body tr {
  height: 100%;
}

.fullcalendar .fc.fc-unthemed.fc-ltr .fc-view-container .fc-view.fc-basicDay-view.fc-basic-view table .fc-body tr .fc-widget-content {
  height: 100%;
}

.fullcalendar .fc.fc-unthemed.fc-ltr .fc-view-container .fc-view.fc-basicDay-view.fc-basic-view table .fc-body tr .fc-widget-content .fc-scroller.fc-day-grid-container {
  height: 100% !important;
}

.fullcalendar .fc.fc-unthemed.fc-ltr .fc-view-container .fc-view.fc-basicDay-view.fc-basic-view table .fc-body tr .fc-widget-content .fc-scroller.fc-day-grid-container .fc-day-grid.fc-unselectable {
  height: 100% !important;
}

.fullcalendar .fc.fc-unthemed.fc-ltr .fc-view-container .fc-view.fc-basicDay-view.fc-basic-view table .fc-body tr .fc-widget-content .fc-scroller.fc-day-grid-container .fc-day-grid.fc-unselectable .fc-row.fc-week.fc-widget-content.fc-rigid .fc-bg table tbody tr .fc-day.fc-widget-content.fc-sat.fc-today.fc-state-highlight {
  background: rgba(53, 152, 219, 0.14);
}

.fullcalendar .fc.fc-unthemed.fc-ltr .fc-view-container .fc-view.fc-basicDay-view.fc-basic-view table .fc-body tr .fc-widget-content .fc-scroller.fc-day-grid-container .fc-day-grid.fc-unselectable .fc-row.fc-week.fc-widget-content.fc-rigid .fc-bg table tbody tr .fc-day.fc-widget-content.fc-sun.fc-today.fc-state-highlight {
  background: rgba(53, 152, 219, 0.14);
}

.fullcalendar .fc.fc-unthemed.fc-ltr .fc-view-container .fc-view.fc-basicDay-view.fc-basic-view table .fc-body tr .fc-widget-content .fc-scroller.fc-day-grid-container .fc-day-grid.fc-unselectable .fc-row.fc-week.fc-widget-content.fc-rigid .fc-bg table tbody tr .fc-day.fc-widget-content.fc-mon.fc-today.fc-state-highlight {
  background: rgba(53, 152, 219, 0.14);
}

.fullcalendar .fc.fc-unthemed.fc-ltr .fc-view-container .fc-view.fc-basicDay-view.fc-basic-view table .fc-body tr .fc-widget-content .fc-scroller.fc-day-grid-container .fc-day-grid.fc-unselectable .fc-row.fc-week.fc-widget-content.fc-rigid .fc-bg table tbody tr .fc-day.fc-widget-content.fc-tue.fc-today.fc-state-highlight {
  background: rgba(53, 152, 219, 0.14);
}

.fullcalendar .fc.fc-unthemed.fc-ltr .fc-view-container .fc-view.fc-basicDay-view.fc-basic-view table .fc-body tr .fc-widget-content .fc-scroller.fc-day-grid-container .fc-day-grid.fc-unselectable .fc-row.fc-week.fc-widget-content.fc-rigid .fc-bg table tbody tr .fc-day.fc-widget-content.fc-wed.fc-today.fc-state-highlight {
  background: rgba(53, 152, 219, 0.14);
}

.fullcalendar .fc.fc-unthemed.fc-ltr .fc-view-container .fc-view.fc-basicDay-view.fc-basic-view table .fc-body tr .fc-widget-content .fc-scroller.fc-day-grid-container .fc-day-grid.fc-unselectable .fc-row.fc-week.fc-widget-content.fc-rigid .fc-bg table tbody tr .fc-day.fc-widget-content.fc-thu.fc-today.fc-state-highlight {
  background: rgba(53, 152, 219, 0.14);
}

.fullcalendar .fc.fc-unthemed.fc-ltr .fc-view-container .fc-view.fc-basicDay-view.fc-basic-view table .fc-body tr .fc-widget-content .fc-scroller.fc-day-grid-container .fc-day-grid.fc-unselectable .fc-row.fc-week.fc-widget-content.fc-rigid .fc-bg table tbody tr .fc-day.fc-widget-content.fc-fri.fc-today.fc-state-highlight {
  background: rgba(53, 152, 219, 0.14);
}

.fullcalendar .fc.fc-unthemed.fc-ltr .fc-view-container .fc-view.fc-basicDay-view.fc-basic-view table .fc-body tr .fc-widget-content .fc-scroller.fc-day-grid-container .fc-day-grid.fc-unselectable .fc-row.fc-week.fc-widget-content.fc-rigid .fc-content-skeleton table tbody tr .fc-event-container .fc-day-grid-event.fc-h-event.fc-event.fc-start.fc-end.appointmentlabel.fc-draggable {
  margin-top: 0;
}

.fullcalendar .fc.fc-unthemed.fc-ltr .fc-view-container .fc-view.fc-basicDay-view.fc-basic-view table .fc-body tr .fc-widget-content .fc-scroller.fc-day-grid-container .fc-day-grid.fc-unselectable .fc-row.fc-week.fc-widget-content.fc-rigid .fc-content-skeleton table tbody tr .fc-event-container .fc-day-grid-event.fc-h-event.fc-event.fc-start.fc-end.appointmentlabel.fc-draggable:hover {
  z-index: 100 !important;
}

/* Month view */

.fullcalendar .fc.fc-unthemed.fc-ltr .fc-view-container .fc-view.fc-month-view.fc-basic-view table .fc-body tr .fc-widget-content .fc-scroller.fc-day-grid-container .fc-day-grid.fc-unselectable .fc-row.fc-week.fc-widget-content.fc-rigid {
  overflow: inherit;
  z-index: auto;
  height: 16.6666667%;
}

.fullcalendar .fc.fc-unthemed.fc-ltr .fc-view-container .fc-view.fc-month-view.fc-basic-view {
  height: 100%;
}

.fullcalendar .fc.fc-unthemed.fc-ltr .fc-view-container .fc-view.fc-month-view.fc-basic-view table {
  height: 100%;
}

.fullcalendar .fc.fc-unthemed.fc-ltr .fc-view-container .fc-view.fc-month-view.fc-basic-view table .fc-body {
  height: 100%;
}

.fullcalendar .fc.fc-unthemed.fc-ltr .fc-view-container .fc-view.fc-month-view.fc-basic-view table .fc-body tr {
  height: 100%;
}

.fullcalendar .fc.fc-unthemed.fc-ltr .fc-view-container .fc-view.fc-month-view.fc-basic-view table .fc-body tr .fc-widget-content {
  height: 100%;
}

.fullcalendar .fc.fc-unthemed.fc-ltr .fc-view-container .fc-view.fc-month-view.fc-basic-view table .fc-body tr .fc-widget-content .fc-scroller.fc-day-grid-container {
  height: 100% !important;
}

.fullcalendar .fc.fc-unthemed.fc-ltr .fc-view-container .fc-view.fc-month-view.fc-basic-view table .fc-body tr .fc-widget-content .fc-scroller.fc-day-grid-container .fc-day-grid.fc-unselectable {
  height: 100%;
}

.fullcalendar .fc.fc-unthemed.fc-ltr .fc-view-container .fc-view.fc-month-view.fc-basic-view table .fc-body tr .fc-widget-content .fc-scroller.fc-day-grid-container .fc-day-grid.fc-unselectable .fc-row.fc-week.fc-widget-content.fc-rigid .fc-bg table tbody tr .fc-day.fc-widget-content.fc-sat.fc-today.fc-state-highlight {
  background: rgba(53, 152, 219, 0.14);
}

.fullcalendar .fc.fc-unthemed.fc-ltr .fc-view-container .fc-view.fc-month-view.fc-basic-view table .fc-body tr .fc-widget-content .fc-scroller.fc-day-grid-container .fc-day-grid.fc-unselectable .fc-row.fc-week.fc-widget-content.fc-rigid .fc-bg table tbody tr .fc-day.fc-widget-content.fc-sun.fc-today.fc-state-highlight {
  background: rgba(53, 152, 219, 0.14);
}

.fullcalendar .fc.fc-unthemed.fc-ltr .fc-view-container .fc-view.fc-month-view.fc-basic-view table .fc-body tr .fc-widget-content .fc-scroller.fc-day-grid-container .fc-day-grid.fc-unselectable .fc-row.fc-week.fc-widget-content.fc-rigid .fc-bg table tbody tr .fc-day.fc-widget-content.fc-mon.fc-today.fc-state-highlight {
  background: rgba(53, 152, 219, 0.14);
}

.fullcalendar .fc.fc-unthemed.fc-ltr .fc-view-container .fc-view.fc-month-view.fc-basic-view table .fc-body tr .fc-widget-content .fc-scroller.fc-day-grid-container .fc-day-grid.fc-unselectable .fc-row.fc-week.fc-widget-content.fc-rigid .fc-bg table tbody tr .fc-day.fc-widget-content.fc-tue.fc-today.fc-state-highlight {
  background: rgba(53, 152, 219, 0.14);
}

.fullcalendar .fc.fc-unthemed.fc-ltr .fc-view-container .fc-view.fc-month-view.fc-basic-view table .fc-body tr .fc-widget-content .fc-scroller.fc-day-grid-container .fc-day-grid.fc-unselectable .fc-row.fc-week.fc-widget-content.fc-rigid .fc-bg table tbody tr .fc-day.fc-widget-content.fc-wed.fc-today.fc-state-highlight {
  background: rgba(53, 152, 219, 0.14);
}

.fullcalendar .fc.fc-unthemed.fc-ltr .fc-view-container .fc-view.fc-month-view.fc-basic-view table .fc-body tr .fc-widget-content .fc-scroller.fc-day-grid-container .fc-day-grid.fc-unselectable .fc-row.fc-week.fc-widget-content.fc-rigid .fc-bg table tbody tr .fc-day.fc-widget-content.fc-thu.fc-today.fc-state-highlight {
  background: rgba(53, 152, 219, 0.14);
}

.fullcalendar .fc.fc-unthemed.fc-ltr .fc-view-container .fc-view.fc-month-view.fc-basic-view table .fc-body tr .fc-widget-content .fc-scroller.fc-day-grid-container .fc-day-grid.fc-unselectable .fc-row.fc-week.fc-widget-content.fc-rigid .fc-bg table tbody tr .fc-day.fc-widget-content.fc-fri.fc-today.fc-state-highlight {
  background: rgba(53, 152, 219, 0.14);
}

.fullcalendar .fc.fc-unthemed.fc-ltr .fc-view-container .fc-view.fc-month-view.fc-basic-view table .fc-body tr .fc-widget-content .fc-scroller.fc-day-grid-container .fc-day-grid.fc-unselectable .fc-row.fc-week.fc-widget-content.fc-rigid .fc-bg {
  z-index: auto !important;
}

.fullcalendar .fc.fc-unthemed.fc-ltr .fc-view-container .fc-view.fc-month-view.fc-basic-view table .fc-body tr .fc-widget-content .fc-scroller.fc-day-grid-container .fc-day-grid.fc-unselectable .fc-row.fc-week.fc-widget-content.fc-rigid .fc-content-skeleton table tbody tr .fc-event-container {
  z-index: inherit;
}

.fullcalendar .fc.fc-unthemed.fc-ltr .fc-view-container .fc-view.fc-month-view.fc-basic-view table .fc-body tr .fc-widget-content .fc-scroller.fc-day-grid-container .fc-day-grid.fc-unselectable .fc-row.fc-week.fc-widget-content.fc-rigid .fc-content-skeleton:hover {
  z-index: inherit;
}

.fullcalendar .fc.fc-unthemed.fc-ltr .fc-view-container .fc-view.fc-month-view.fc-basic-view table .fc-body tr .fc-widget-content .fc-scroller.fc-day-grid-container .fc-day-grid.fc-unselectable .fc-row.fc-week.fc-widget-content.fc-rigid .fc-content-skeleton table tbody tr .fc-event-container .fc-day-grid-event.fc-h-event.fc-event.fc-start.fc-end.appointmentlabel.fc-draggable:hover {
  z-index: 100 !important;
}

.fullcalendar .fc.fc-unthemed.fc-ltr .fc-view-container .fc-view.fc-month-view.fc-basic-view .fc-popover.fc-more-popover .fc-body.fc-widget-content .fc-event-container .fc-day-grid-event.fc-h-event.fc-event.fc-start.fc-end.appointmentlabel.fc-draggable:hover {
  z-index: 100 !important;
}

/* Agenda view */

.fullcalendar .fc.fc-unthemed.fc-ltr .fc-view-container .fc-view.fc-agenda-view {
  height: 100% !important;
}

.fullcalendar .fc.fc-unthemed.fc-ltr .fc-view-container .fc-view.fc-agenda-view table {
  height: 100% !important;
}

.fullcalendar .fc.fc-unthemed.fc-ltr .fc-view-container .fc-view.fc-agenda-view table .fc-body {
  overflow: auto !important;
  height: 100% !important;
}

.fullcalendar .fc.fc-unthemed.fc-ltr .fc-view-container .fc-view.fc-agenda-view table .fc-body tr .fc-widget-content .fc-scroller.fc-time-grid-container {
  height: 100% !important;
}

.fullcalendar .fc.fc-unthemed.fc-ltr .fc-view-container .fc-view.fc-agenda-view table .fc-body tr .fc-widget-content .fc-scroller.fc-time-grid-container::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

.fullcalendar .fc.fc-unthemed.fc-ltr .fc-view-container .fc-view.fc-agenda-view table .fc-body tr .fc-widget-content .fc-scroller.fc-time-grid-container .fc-time-grid.fc-unselectable .fc-content-skeleton table tbody tr .fc-axis {
  width: 35px !important;
}

.fullcalendar .fc.fc-unthemed.fc-ltr .fc-view-container .fc-view.fc-agenda-view table .fc-body tr .fc-widget-content .fc-scroller.fc-time-grid-container .fc-time-grid.fc-unselectable .fc-content-skeleton table tbody tr td .fc-content-col .fc-business-container {
  z-index: 0 !important;
}

.fullcalendar .fc.fc-unthemed.fc-ltr .fc-view-container .fc-view.fc-agenda-view table .fc-body tr .fc-widget-content .fc-scroller.fc-time-grid-container .fc-time-grid.fc-unselectable .fc-content-skeleton table tbody tr td .fc-content-col .fc-business-container .fc-nonbusiness.fc-bgevent {
  background: #ddd;
  opacity: 0.7;
}

.fullcalendar .fc.fc-unthemed.fc-ltr .fc-view-container .fc-view.fc-agenda-view table .fc-body tr .fc-widget-content .fc-scroller.fc-time-grid-container .fc-time-grid.fc-unselectable .fc-content-skeleton table tbody tr td .fc-content-col .fc-event-container {
  margin: 0;
  z-index: inherit;
}

.fullcalendar .fc.fc-unthemed.fc-ltr .fc-view-container .fc-view.fc-agenda-view table .fc-body tr .fc-widget-content .fc-scroller.fc-time-grid-container .fc-time-grid.fc-unselectable .fc-content-skeleton table tbody tr td .fc-content-col .fc-event-container .fc-time-grid-event.fc-v-event.fc-event.fc-start.fc-end.appointmentlabel.fc-draggable:hover {
  z-index: 100 !important;
}

.fullcalendar .fc.fc-unthemed.fc-ltr .fc-view-container .fc-view.fc-agenda-view table .fc-body tr .fc-widget-content .fc-scroller.fc-time-grid-container .fc-time-grid.fc-unselectable .fc-slats table tbody tr {
  height: 35px;
  border-bottom: 1px solid #D4D2D2;
}

.fullcalendar .fc.fc-unthemed.fc-ltr .fc-view-container .fc-view.fc-agenda-view table .fc-body tr .fc-widget-content .fc-scroller.fc-time-grid-container .fc-time-grid.fc-unselectable .fc-slats table tbody .fc-minor {
  border-bottom: 1px solid #D4D2D2;
}

.fullcalendar .fc.fc-unthemed.fc-ltr .fc-view-container .fc-view.fc-agenda-view table .fc-body tr .fc-widget-content .fc-scroller.fc-time-grid-container .fc-time-grid.fc-unselectable .fc-slats table tbody tr .fc-axis.fc-time.fc-widget-content {
  vertical-align: baseline;
  width: 35px !important;
}
.fc .fc-axis {
  padding: 0 4px !important;
}

.fullcalendar .fc.fc-unthemed.fc-ltr .fc-view-container .fc-view.fc-agenda-view table .fc-body tr .fc-widget-content .fc-scroller.fc-time-grid-container .fc-time-grid.fc-unselectable .fc-bg table tbody tr .fc-axis.fc-widget-content {
  width: 35px !important;
}

/* sat */

.fullcalendar .fc.fc-unthemed.fc-ltr .fc-view-container .fc-view.fc-agenda-view table .fc-body tr .fc-widget-content .fc-scroller.fc-time-grid-container .fc-time-grid.fc-unselectable .fc-bg table tbody tr .fc-day.fc-widget-content.fc-sat.fc-today.fc-state-highlight {
  background: rgba(53, 152, 219, 0.14);
}

.fullcalendar .fc.fc-unthemed.fc-ltr .fc-view-container .fc-view.fc-agenda-view table .fc-body tr .fc-widget-content .fc-day-grid.fc-unselectable .fc-row.fc-week.fc-widget-content .fc-bg table tbody tr .fc-day.fc-widget-content.fc-sat.fc-today.fc-state-highlight {
  background: rgba(53, 152, 219, 0.14);
}

/* sun */

.fullcalendar .fc.fc-unthemed.fc-ltr .fc-view-container .fc-view.fc-agenda-view table .fc-body tr .fc-widget-content .fc-scroller.fc-time-grid-container .fc-time-grid.fc-unselectable .fc-bg table tbody tr .fc-day.fc-widget-content.fc-sun.fc-today.fc-state-highlight {
  background: rgba(53, 152, 219, 0.14);
}

.fullcalendar .fc.fc-unthemed.fc-ltr .fc-view-container .fc-view.fc-agenda-view table .fc-body tr .fc-widget-content .fc-day-grid.fc-unselectable .fc-row.fc-week.fc-widget-content .fc-bg table tbody tr .fc-day.fc-widget-content.fc-sun.fc-today.fc-state-highlight {
  background: rgba(53, 152, 219, 0.14);
}

/* mon */

.fullcalendar .fc.fc-unthemed.fc-ltr .fc-view-container .fc-view.fc-agenda-view table .fc-body tr .fc-widget-content .fc-scroller.fc-time-grid-container .fc-time-grid.fc-unselectable .fc-bg table tbody tr .fc-day.fc-widget-content.fc-mon.fc-today.fc-state-highlight {
  background: rgba(53, 152, 219, 0.14);
}

.fullcalendar .fc.fc-unthemed.fc-ltr .fc-view-container .fc-view.fc-agenda-view table .fc-body tr .fc-widget-content .fc-day-grid.fc-unselectable .fc-row.fc-week.fc-widget-content .fc-bg table tbody tr .fc-day.fc-widget-content.fc-mon.fc-today.fc-state-highlight {
  background: rgba(53, 152, 219, 0.14);
}

/* tue */

.fullcalendar .fc.fc-unthemed.fc-ltr .fc-view-container .fc-view.fc-agenda-view table .fc-body tr .fc-widget-content .fc-scroller.fc-time-grid-container .fc-time-grid.fc-unselectable .fc-bg table tbody tr .fc-day.fc-widget-content.fc-tue.fc-today.fc-state-highlight {
  background: rgba(53, 152, 219, 0.14);
}

.fullcalendar .fc.fc-unthemed.fc-ltr .fc-view-container .fc-view.fc-agenda-view table .fc-body tr .fc-widget-content .fc-day-grid.fc-unselectable .fc-row.fc-week.fc-widget-content .fc-bg table tbody tr .fc-day.fc-widget-content.fc-tue.fc-today.fc-state-highlight {
  background: rgba(53, 152, 219, 0.14);
}

/* wed */

.fullcalendar .fc.fc-unthemed.fc-ltr .fc-view-container .fc-view.fc-agenda-view table .fc-body tr .fc-widget-content .fc-scroller.fc-time-grid-container .fc-time-grid.fc-unselectable .fc-bg table tbody tr .fc-day.fc-widget-content.fc-wed.fc-today.fc-state-highlight {
  background: rgba(53, 152, 219, 0.14);
}

.fullcalendar .fc.fc-unthemed.fc-ltr .fc-view-container .fc-view.fc-agenda-view table .fc-body tr .fc-widget-content .fc-day-grid.fc-unselectable .fc-row.fc-week.fc-widget-content .fc-bg table tbody tr .fc-day.fc-widget-content.fc-wed.fc-today.fc-state-highlight {
  background: rgba(53, 152, 219, 0.14);
}

/* thu */

.fullcalendar .fc.fc-unthemed.fc-ltr .fc-view-container .fc-view.fc-agenda-view table .fc-body tr .fc-widget-content .fc-scroller.fc-time-grid-container .fc-time-grid.fc-unselectable .fc-bg table tbody tr .fc-day.fc-widget-content.fc-thu.fc-today.fc-state-highlight {
  background: rgba(53, 152, 219, 0.14);
}

.fullcalendar .fc.fc-unthemed.fc-ltr .fc-view-container .fc-view.fc-agenda-view table .fc-body tr .fc-widget-content .fc-day-grid.fc-unselectable .fc-row.fc-week.fc-widget-content .fc-bg table tbody tr .fc-day.fc-widget-content.fc-thu.fc-today.fc-state-highlight {
  background: rgba(53, 152, 219, 0.14);
}

/* fri */

.fullcalendar .fc.fc-unthemed.fc-ltr .fc-view-container .fc-view.fc-agenda-view table .fc-body tr .fc-widget-content .fc-scroller.fc-time-grid-container .fc-time-grid.fc-unselectable .fc-bg table tbody tr .fc-day.fc-widget-content.fc-fri.fc-today.fc-state-highlight {
  background: rgba(53, 152, 219, 0.14);
}

.fullcalendar .fc.fc-unthemed.fc-ltr .fc-view-container .fc-view.fc-agenda-view table .fc-body tr .fc-widget-content .fc-day-grid.fc-unselectable .fc-row.fc-week.fc-widget-content .fc-bg table tbody tr .fc-day.fc-widget-content.fc-fri.fc-today.fc-state-highlight {
  background: rgba(53, 152, 219, 0.14);
}

.fullcalendar .fc.fc-unthemed.fc-ltr .fc-view-container .fc-view.fc-agenda-view table .fc-head {
  border-color: #D4D2D2;
  zoom: 85%;
}

.fullcalendar .fc.fc-unthemed.fc-ltr .fc-view-container .fc-view.fc-agenda-view table .fc-head tr {
  height: 40px;
}

.fullcalendar .fc.fc-unthemed.fc-ltr .fc-view-container .fc-view.fc-agenda-view table .fc-head tr .fc-head-container.fc-widget-header {
  border: 1px solid #D4D2D2;
}

.fullcalendar .fc.fc-unthemed.fc-ltr .fc-view-container .fc-view.fc-agenda-view table .fc-head tr .fc-axis.fc-widget-header {
  background: #D4D2D2;
  width: 43px !important;
}

.fullcalendar .fc.fc-unthemed.fc-ltr .fc-view-container .fc-view.fc-agenda-view table .fc-head tr th {
  vertical-align: middle;
  color: #4A4A4A;
  font-size: 12px;
}

.fullcalendar .fc.fc-unthemed.fc-ltr .fc-view-container .fc-view.fc-agenda-view table .fc-head tr th a {
}

.fullcalendar .fc.fc-unthemed.fc-ltr .fc-toolbar.fc-header-toolbar {
  margin-bottom: 0em;
  position: absolute;
  top: 77px;
  right: 18px;
  width: 55%;
  zoom: 85%;
}

.fullcalendar .fc.fc-unthemed.fc-ltr .fc-toolbar.fc-header-toolbar .fc-right {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.fullcalendar .fc.fc-unthemed.fc-ltr .fc-toolbar.fc-header-toolbar .fc-right .fc-Settings-button.fc-button.fc-state-default.fc-corner-left.fc-corner-right div {
  font-size: 12px;
  color: #4A4A4A;
}

.fullcalendar .fc.fc-unthemed.fc-ltr .fc-toolbar.fc-header-toolbar .fc-right h2 {
  font-size: 12px;
  color: #4A4A4A;
}

.fullcalendar .fc.fc-unthemed.fc-ltr .fc-toolbar.fc-header-toolbar .fc-right .fc-prev-button.fc-button.fc-state-default.fc-corner-left.fc-corner-right {
  margin-right: 0.75em;
  background: none;
  border: none;
  box-shadow: none;
  color: #4A4A4A;
}

.fullcalendar .fc.fc-unthemed.fc-ltr .fc-toolbar.fc-header-toolbar .fc-right .fc-next-button.fc-button.fc-state-default.fc-corner-left.fc-corner-right {
  margin-left: 0.75em;
  margin-right: 1.75em;
  background: none;
  border: none;
  box-shadow: none;
  color: #4A4A4A;
}

.fullcalendar .fc.fc-unthemed.fc-ltr .fc-toolbar.fc-header-toolbar .fc-right .fc-today-button.fc-button.fc-state-default.fc-corner-left.fc-corner-right.fc-state-disabled {
  background: white;
  box-shadow: none;
  border: 1px solid #9B9B9B;
  color: #9B9B9B;
}

.fullcalendar .fc.fc-unthemed.fc-ltr .fc-toolbar.fc-header-toolbar .fc-right .fc-today-button.fc-button.fc-state-default.fc-corner-left.fc-corner-right {
  background: white;
  box-shadow: none;
  border: 1px solid #9B9B9B;
  color: #4a4a4a;
}

.fullcalendar .fc.fc-unthemed.fc-ltr .fc-toolbar.fc-header-toolbar .fc-right .fc-month-button.fc-button.fc-state-default.fc-corner-left.fc-corner-right {
  background: white;
  box-shadow: none;
  border: 1px solid #9B9B9B;
  margin-left: 1.3em;
  color: #4a4a4a;
}

.fullcalendar .fc.fc-unthemed.fc-ltr .fc-toolbar.fc-header-toolbar .fc-right .fc-agendaWeek-button.fc-button.fc-state-default.fc-corner-left.fc-corner-right {
  background: white;
  box-shadow: none;
  border: 1px solid #9B9B9B;
  margin-left: 0.3em;
  color: #4a4a4a;
}

.fullcalendar .fc.fc-unthemed.fc-ltr .fc-toolbar.fc-header-toolbar .fc-right .fc-basicDay-button.fc-button.fc-state-default.fc-corner-left.fc-corner-right {
  background: white;
  box-shadow: none;
  border: 1px solid #9B9B9B;
  margin-left: 0.3em;
  color: #4a4a4a;
}

.fullcalendar .fc.fc-unthemed.fc-ltr .fc-toolbar.fc-header-toolbar .fc-right .fc-Settings-button.fc-button.fc-state-default.fc-corner-left.fc-corner-right {
  background: white;
  box-shadow: none;
  border: 1px solid #9B9B9B;
  margin-left: 1em;
  color: #4a4a4a;
}

.appointmentlabel {
  height: auto;
  width: -webkit-fill-available;
  margin: 0;
  margin-bottom: 2px;
  -ms-transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  border: none;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  overflow: inherit;
  box-shadow: 0px 0px 10px #A6B4BC;
}

.appointmentlabel .fc-content {
  display: grid;
  overflow: initial;
  min-height: 35px;
  height: 100%;
  white-space: initial;
  padding-left: 2px;
}

.appointmentlabel .fc-content .fc-time {
  display: none;
}

.appointmentlabel .fc-content .fc-title {
  display: none;
}

.appointmentlabel .fc-content .topside {
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-height: 30px;
  position: absolute;
  width: 100%;
}

.appointmentlabel .fc-content .topside .patient-name {
  top: 5px;
  font-size: 11px;
  position: relative;
  color: white;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-lines: 1;
  left: 2px;
  text-transform: capitalize;
}

.appointmentlabel .fc-content .topside .status {
  font-size: 8px;
  height: auto;
  width: auto;
  position: absolute;
  color: white;
  right: 0;
  top: 0;
  display: flex;
}

.appointmentlabel .fc-content .topside .status .new {
  font-size: 8px;
  height: auto;
  padding: 2px;
  width: auto;
  min-width: 27px;
  text-align: center;
  position: relative;
  color: white;
  background-image: linear-gradient(to right, #fe685c, #ff5b61, #ff4d68, #fe3f70, #fc2e79);
}

.appointmentlabel .fc-content .topside .status .online {
  font-size: 8px;
  height: auto;
  width: auto;
  min-width: 27px;
  padding: 2px;
  text-align: center;
  position: relative;
  color: white;
  background-color: #ca9e67
}
.appointmentlabel .fc-content .topside .status .thrive {
  font-size: 8px;
  height: auto;
  width: auto;
  min-width: 27px;
  padding: 2px;
  text-align: center;
  position: relative;
  color: white;
  background-image: linear-gradient(to right, #3498db, #0086e0);
}
.appointmentlabel .fc-content .bottomside {
  position: relative;
  padding-top: 20px;
  color: white;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.appointmentlabel .fc-content .bottomside .dr-name {
  font-size: 9px;
  position: relative;
  color: white;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-lines: 1;
  left: 2px;
  width: 60px;
  text-transform: capitalize;
}

.appointmentlabel .fc-content .bottomside .time {
  position: relative;
  text-align: end;
  font-size: 9px;
  color: white;
  width: auto;
  margin-right: 4px;
}

.appointmentlabel .fc-content .patientpopupinfo {
  visibility: hidden;
  position: absolute;
  display: flex;
}

.appointmentlabel .fc-content:hover .patientpopupinfo {
  visibility: visible;
  background-color: rgba(255, 255, 255, 1.0);
  min-height: 140px;
  min-width: 280px;
  position: absolute;
  margin: 0;
  border-radius: 6px;
  overflow: inherit;
  -ms-transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  display: grid;
  z-index: 100;
  -webkit-box-shadow: 0 0px 14px #D4D2D2;
  box-shadow: 0 0px 14px #D4D2D2;
  transition-delay: 0.5s;
}

.appointmentlabel .fc-content:hover .patientpopupinfo .rectangle {
  background-color: rgba(255, 255, 255, 0.0);
  height: 14px;
  top: 6px;
  width: 15px;
  position: absolute;
  margin: 0;
  -ms-transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}

.appointmentlabel .fc-content .patientpopupinfo .shownoshow {
  background-color: rgba(255, 255, 255, 0.0);
  bottom: 0;
  height: auto;
  min-height: 30px;
  width: 100%;
  position: relative;
  margin: 0;
  -ms-transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  display: flex;
  justify-content: center;
  border-top: 1px solid rgba(215, 215, 215, 1.0);
}

.appointmentlabel .fc-content .patientpopupinfo .shownoshow .noshow {
  background-color: rgba(255, 255, 255, 0.0);
  height: 20px;
  width: 49%;
  position: relative;
  left: 0px;
  -ms-transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  font-size: 12.0px;
  color: rgba(74, 73, 74, 1.0);
  text-align: center;
  line-height: 14.0px;
  font-family: "SFProDisplay-Thin", Helvetica, Arial, serif;
  align-self: center;
  display: grid;
  align-content: center;
}

.appointmentlabel .fc-content .patientpopupinfo .shownoshow .show {
  background-color: rgba(255, 255, 255, 0.0);
  height: 20px;
  width: 49%;
  position: relative;
  right: 0;
  -ms-transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  font-family: "SFProDisplay-Thin", Helvetica, Arial, serif;
  font-size: 12.0px;
  color: rgba(74, 73, 74, 1.0);
  text-align: center;
  line-height: 14.0px;
  align-self: center;
  display: grid;
  align-content: center;
}

.appointmentlabel .fc-content .patientpopupinfo .shownoshow .rectangle1 {
  background-color: rgba(215, 215, 215, 1.0);
  top: 0px;
  height: 100%;
  width: 1px;
  position: relative;
  margin: 0;
  -ms-transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}

.appointmentlabel .fc-content .patientpopupinfo .noteandresaonoft {
  background-color: rgba(255, 255, 255, 0.0);
  height: auto;
  width: 100%;
  min-height: 20px;
  position: relative;
  margin: 0;
  left: 16px;
  -ms-transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  font-family: "SFProDisplay-Light", Helvetica, Arial, serif;
  font-size: 10.0px;
  color: rgba(74, 73, 74, 1.0);
  text-align: left;
  display: grid;
  align-content: center;
}

.appointmentlabel .fc-content .patientpopupinfo .drsamehsuliman {
  background-color: rgba(255, 255, 255, 0.0);
  height: auto;
  width: 100%;
  min-height: 20px;
  position: relative;
  margin: 0;
  left: 16px;
  -ms-transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  font-family: "SFProDisplay-Light", Helvetica, Arial, serif;
  font-size: 10.0px;
  color: rgba(74, 73, 74, 1.0);
  text-align: left;
  display: grid;
  align-content: center;
}

.appointmentlabel .fc-content .patientpopupinfo .appointment {
  background-color: rgba(255, 255, 255, 0.0);
  height: auto;
  min-height: 30px;
  width: 100%;
  position: relative;
  margin: 0;
  -ms-transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  font-family: "SFProDisplay-Light", Helvetica, Arial, serif;
  font-size: 12.0px;
  color: rgba(74, 73, 74, 1.0);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  border-top: 1px solid #D8D8D8;
}

.appointmentlabel .fc-content .patientpopupinfo .appointment .appointmentdate {
  background-color: rgba(255, 255, 255, 0.0);
  height: auto;
  min-height: 30px;
  width: auto;
  position: relative;
  margin: 0;
  left: 10px;
  -ms-transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  font-family: "SFProDisplay-Regular", Helvetica, Arial, serif;
  font-size: 12.0px;
  color: #3598DB;
  text-align: center;
  display: grid;
  align-content: center;
}

.appointmentlabel .fc-content .patientpopupinfo .tarekjamal {
  background-color: rgba(255, 255, 255, 0.0);
  height: auto;
  min-height: 20px;
  width: 100%;
  position: relative;
  margin: 0;
  left: 16px;
  -ms-transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  font-family: "SFProDisplay-Medium", Helvetica, Arial, serif;
  font-size: 12.0px;
  color: rgba(74, 73, 74, 1.0);
  text-align: left;
  display: grid;
  align-content: center;
  text-transform: capitalize;
}

.appointmentlabel .fc-content .patientpopupinfo .viewbutton {
  background-color: rgba(255, 255, 255, 0.0);
  height: auto;
  width: 30%;
  position: absolute;
  margin: 0;
  left: 80%;
  top: 25px;
  -ms-transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  font-family: "SFProDisplay-Thin", Helvetica, Arial, serif;
  font-size: 14.0px;
  color: #3598DB;
  text-align: left;
  display: grid;
  align-content: center;
  cursor: pointer;
}

/* END */